import CapsuleItem from '~/components/capsule_item/index.tsx';
import HomeGridView from '~/components/home/grid_view.tsx';
import Text from '~/components/text.tsx';
import { Capsule } from '~/types/capsule.ts';
import { Persona } from '~/types/persona.ts';
import { User } from '~/types/user.ts';

import PersonaWelcome from './welcome.tsx';

interface SentCapsulesTabProps {
  currentUser: User;
  primaryPersona?: Persona | null;
  capsules: Capsule[];
}

const getTimeBasedGreeting = () => {
  const hour = new Date().getHours();
  if (hour < 12) return 'Good Morning';
  if (hour < 17) return 'Good Afternoon';
  return 'Good Evening';
};

const SentCapsulesTab = ({
  currentUser,
  primaryPersona,
  capsules,
}: SentCapsulesTabProps) => {
  if (primaryPersona && capsules.length === 0) {
    return <PersonaWelcome primaryPersona={primaryPersona} />;
  }

  return (
    <>
      <Text title>
        {getTimeBasedGreeting()}, {currentUser.firstName}
      </Text>
      <HomeGridView>
        {capsules.map((capsule) => (
          <CapsuleItem key={capsule.id} capsule={capsule} />
        ))}
      </HomeGridView>
    </>
  );
};

export default SentCapsulesTab;
