import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';

import useFileUpload from '~/api/hooks/useFileUpload.ts';
import DrawerContainer from '~/components/containers/drawer_container.tsx';

const Playground = () => {
  const { upload, uploads, resetUploads } = useFileUpload('capsule_assets');
  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const files = Array.from(e.target.files);
        const assets = files.map((file) => ({
          parentId: 'test',
          asset: file,
        }));
        upload(assets);
      }
    },
    [upload],
  );

  return (
    <DrawerContainer>
      <h1>Playground</h1>
      <h3>Uploads Testing</h3>
      <div>
        <button onClick={resetUploads}>Reset uploads</button>
      </div>
      <input type="file" onChange={onFileChange} multiple />
      <div>
        {uploads.map((upload) => (
          <div key={upload.id} style={{ flex: 1, flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ marginRight: 8 }}>
                {upload.metadata.asset?.type?.includes('image') && (
                  <img
                    src={URL.createObjectURL(upload.metadata.asset)}
                    alt="upload"
                    style={{ width: 100 }}
                  />
                )}
                {upload.metadata.asset?.type?.includes('video') && (
                  <video
                    src={URL.createObjectURL(upload.metadata.asset)}
                    controls
                    style={{ width: 100 }}
                  />
                )}
                {upload.metadata.asset?.type?.includes('audio') && (
                  <audio
                    src={URL.createObjectURL(upload.metadata.asset)}
                    controls
                    style={{ width: 100 }}
                  />
                )}
              </div>
              {/* eslint-disable-next-line */}
              {/* @ts-ignore */}
              {upload.metadata.blob?.name || upload.metadata.asset?.name} -{' '}
              {upload.status} - {new Date(upload.createdAt).toLocaleString()} -{' '}
              {upload.progress.toFixed(2)}%
            </div>
          </div>
        ))}
      </div>

      <Box sx={{ width: '100%' }}>
        <h1>Typography Playground</h1>
        <h2>
          These are the names of the different variants of typography and an
          example of what that looks like.
        </h2>
        <Typography variant="h1" gutterBottom>
          h1. Heading
        </Typography>
        <Typography variant="h2" gutterBottom>
          h2. Heading
        </Typography>
        <Typography variant="h3" gutterBottom>
          h3. Heading
        </Typography>
        <Typography variant="h4" gutterBottom>
          h4. Heading
        </Typography>
        <Typography variant="h5" gutterBottom>
          h5. Heading
        </Typography>
        <Typography variant="h6" gutterBottom>
          h6. Heading
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="body1" gutterBottom>
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body2" gutterBottom>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
          button text
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          caption text
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          overline text
        </Typography>
      </Box>
    </DrawerContainer>
  );
};

export default Playground;
