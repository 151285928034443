import { Box } from '@mui/material';

const HomeGridView = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { sm: '1fr', md: '1fr 1fr' },
        gap: '20px',
      }}
    >
      {children}
    </Box>
  );
};

export default HomeGridView;
