import { Attachment } from '~/types/attachment.ts';
import { User } from '~/types/user.ts';

export enum MemoryStatus {
  Draft = 'draft',
  Complete = 'complete',
}

export interface MemoryFields {
  // Fields
  id: string;
  capsuleId: string;
  title: string;
  description?: string;
  userId: string;
  status: MemoryStatus;
  createdAt: string;
  updatedAt: string;
}

export interface Memory extends MemoryFields {
  // Relationships
  user: User;
  content: MemoryContent[];
}

export interface MemoryContent {
  // Fields
  id: string;
  memoryId: string;
  title: string;
  content: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  attachmentId?: string;

  // Relationships
  user: User;
  attachments?: Attachment[];
  audioRecording?: Attachment;
}

export type DraftMemory = Pick<
  Memory,
  'id' | 'capsuleId' | 'title' | 'description' | 'userId' | 'status'
>;

export type DraftMemoryContent = Pick<
  MemoryContent,
  'id' | 'memoryId' | 'title' | 'content' | 'userId'
>;
