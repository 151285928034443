import { useRef } from 'react';
import { css } from '@emotion/react';
import { PlayCircle, StopCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useWavesurfer } from '@wavesurfer/react';

import { Attachment } from '~/types/attachment.ts';

interface AudioMiniPlayerProps {
  audio: Attachment;
  autoPlay?: boolean;
  containerStyle?: any;
}

const AudioMiniPlayer = ({
  audio,
  autoPlay = false,
  containerStyle,
}: AudioMiniPlayerProps) => {
  const containerRef = useRef(null);

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: containerRef,
    waveColor: '#959595',
    height: 28,
    url: audio.url,
    autoplay: autoPlay,
  });

  const togglePlayback = () => {
    wavesurfer && wavesurfer.playPause();
  };

  return (
    <>
      <div css={[styles.input, containerStyle]}>
        {isPlaying ? (
          <IconButton onClick={togglePlayback}>
            <StopCircle />
          </IconButton>
        ) : (
          <IconButton onClick={togglePlayback}>
            <PlayCircle />
          </IconButton>
        )}

        <div ref={containerRef} css={styles.audioVisualizer} />
      </div>
    </>
  );
};

const styles = {
  input: css({
    position: 'relative',
    display: 'flex',
    flex: 1,
    minWidth: '280px',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '20px',
    backgroundColor: '#E6E6E6',
    color: '#000',
    resize: 'none' as const,
    maxHeight: '120px',
    outline: 'none',
    fontFamily: 'inherit',
  }),
  audioVisualizer: css({
    zIndex: 1,
    width: '100%',
    marginRight: '16px',
    marginTop: '6px',
  }),
};

export default AudioMiniPlayer;
