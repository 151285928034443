import { useMemo } from 'react';
import { css } from '@emotion/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Text from '~/components/text.tsx';
import colors from '~/styles/colors.ts';
import { Attachment } from '~/types/attachment.ts';
import { MemoryContributorInvite } from '~/types/contributions.ts';
import { getInitials } from '~/utils/strings.ts';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

interface ContributorRequestItemProps {
  invite: MemoryContributorInvite;
  userId: string;
}

const ContributorRequestItem = ({
  invite,
  userId,
}: ContributorRequestItemProps) => {
  const fontColor = colors.WHITE;
  const capsule = invite?.capsule;
  const memory = invite?.memory;

  const numOfContributions = useMemo(() => {
    if (!memory?.content?.length) return 0;
    return memory.content.filter((content) => content.userId === userId).length;
  }, [memory, invite]);

  const daysUntilExpired = invite.expiresAt
    ? Math.ceil(
        (new Date(invite.expiresAt).getTime() - new Date().getTime()) /
          (1000 * 60 * 60 * 24),
      )
    : null;

  const firstAttachment: Attachment | undefined = useMemo(() => {
    if (!memory?.content?.length) return undefined;

    const attachments = memory.content[0]?.attachments;
    if (!attachments?.length) return undefined;

    return attachments.find((attachment) => !!attachment.thumbnailUrl);
  }, [memory]);
  return (
    <div css={styles.section}>
      {firstAttachment ? (
        <>
          <a
            key={memory?.id}
            css={styles.container}
            href={`/capsule/${capsule?.id}/memory/${memory?.id}`}
          >
            <div css={styles.content}>
              <Text title lineHeight={1.4} color={fontColor}>
                {capsule?.title}
              </Text>

              <div css={styles.flexRow}>
                <Text color={fontColor} size={14}>
                  {`You've added ${numOfContributions} contributions`}
                </Text>
              </div>

              {daysUntilExpired && daysUntilExpired > 0 && (
                <div css={styles.flexRow}>
                  <NotificationsNoneIcon />
                  <Text color={fontColor} size={14}>
                    {`Expires in ${daysUntilExpired} days`}
                  </Text>
                </div>
              )}
            </div>

            {firstAttachment && (
              <>
                <div css={styles.gradient} />
                <div css={styles.imageContainer}>
                  <img
                    css={styles.image}
                    src={firstAttachment?.thumbnailUrl}
                    alt="thumbnail"
                  />
                </div>
              </>
            )}
          </a>
        </>
      ) : (
        <>
          <a
            key={memory?.id}
            css={styles.noImageContainer}
            href={`/capsule/${capsule?.id}/memory/${memory?.id}`}
          >
            <div css={[styles.content, styles.noImageContent]}>
              <Text title size={22} lineHeight={1} color={'#000'}>
                {capsule?.title}
              </Text>

              {numOfContributions > 0 && (
                <div css={styles.flexRow}>
                  <Text color={'#000'} size={14}>
                    {`You've added ${numOfContributions} contributions`}
                  </Text>
                </div>
              )}

              {daysUntilExpired && daysUntilExpired > 0 && (
                <div css={styles.flexRow}>
                  <NotificationsNoneIcon />
                  <Text color={'#000'} size={14}>
                    {`Expires in ${daysUntilExpired} days`}
                  </Text>
                </div>
              )}
            </div>
          </a>
        </>
      )}

      <div css={styles.textBubble}>
        <Text color={'#000'} size={14}>
          {memory?.title}
        </Text>
      </div>

      <div css={styles.bottomContainer}>
        <div css={styles.avatarContainer}>
          {capsule?.sender?.avatarPath ? (
            <Avatar
              css={styles.avatar}
              src={capsule?.sender?.avatarPath || ''}
              alt={capsule?.sender?.firstName || '-'}
            />
          ) : (
            <Avatar css={styles.avatar}>
              {getInitials(capsule?.sender?.firstName || '')}
            </Avatar>
          )}
          {capsule?.sender?.firstName || '-'}
        </div>
        <Button
          href={`/capsule/${capsule?.id}/memory/${memory?.id}`}
          variant="contained"
          css={styles.contributeButton}
        >
          {invite.accepted ? 'Continue Contributing' : 'Help Contribute'}{' '}
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};
const styles = {
  section: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '10px'
  }),
  container: css({
    position: 'relative',
    overflow: 'hidden',
    minHeight: 206,
    height: 'auto',
    borderRadius: '16px',
    backgroundColor: colors.BACKGROUND_1,
  }),
  noImageContainer: css({
    overflow: 'hidden',
    minHeight: 50,
    height: 'auto',
    textDecoration: 'none',
  }),
  imageContainer: css({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    zIndex: 1,
  }),
  gradient: css({
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 77%, rgba(0, 0, 0, .65) 100%)',
    zIndex: 2,
  }),
  image: css({
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  }),
  content: css({
    position: 'absolute',
    padding: '16px',
    zIndex: 3,
    bottom: 0,
    color: '#fff',
  }),
  noImageContent: css({
    color: '#000',
    padding: '0px',
    position: 'relative',
  }),
  flexRow: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px',
  }),
  sendDescription: css({
    marginBottom: 3,
  }),
  mediaDescription: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  }),
  statusBadge: css({
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 3,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 16,
    backgroundColor: colors.STATUS_BADGE,
    textTransform: 'capitalize',
  }),
  textBubble: css({
    position: 'relative',
    top: 10,
    zIndex: 3,
    backgroundColor: '#E6E6E6',
    padding: 16,
    borderRadius: 25,
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  }),
  bottomContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 25,
  }),
  avatarContainer: css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  }),
  avatar: css({
    width: 40,
    height: 40,
    border: `2px solid ${colors.WHITE}`,
  }),
  contributeButton: css({
    backgroundColor: 'rgba(1, 87, 155, 0.1)',
    color: '#01579B',
    border: 'none',
    borderRadius: 20,
    padding: '8px 16px',
    cursor: 'pointer',
    fontSize: '14px',
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
    '&:hover': {
      opacity: 0.9,
      color: '#fff',
    },
  }),
};

export default ContributorRequestItem;
