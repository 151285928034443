import { AttachmentType } from '~/types/types.ts';

export interface Attachment {
  // Fields
  id: string;
  capsuleId: string;
  contentId: string;
  blobId: string;
  thumbnailId?: string;
  order?: number;

  type: AttachmentType;
  createdAt: string;

  // Relationships
  blob: AttachmentBlob;
  url: string;
  thumbnailUrl: string;
}

export interface AttachmentBlob {
  // Fields
  id: string;
  key: string;
  filename: string;
  contentType: string;
  service: 'supabase' | 'amazon';
  metadata?: {
    width: number;
    height: number;
  };
  byteSize: number;
  createdAt: string;
}

export type DraftAttachment = Pick<
  Attachment,
  'id' | 'capsuleId' | 'blobId' | 'contentId' | 'thumbnailId' | 'order' | 'type'
>;

export type DraftAttachmentBlob = Pick<
  AttachmentBlob,
  | 'id'
  | 'key'
  | 'filename'
  | 'contentType'
  | 'service'
  | 'metadata'
  | 'byteSize'
>;

export const isAttachment = (
  attachment: File | Blob | Attachment,
): attachment is Attachment => {
  return attachment.type === AttachmentType.ATTACHMENT;
};

export const isThumbnail = (
  attachment: File | Blob | Attachment,
): attachment is Attachment => {
  return attachment.type === AttachmentType.THUMBNAIL;
};

export const isAudioRecording = (attachment: Attachment) => {
  return attachment.type === AttachmentType.AUDIO_RECORDING;
};

export const isImageBlob = (blob?: AttachmentBlob) => {
  return blob?.contentType.includes('image');
};

export const isVideoBlob = (blob?: AttachmentBlob) => {
  return blob?.contentType.includes('video');
};
