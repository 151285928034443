import { PostgrestError } from '@supabase/supabase-js';
import { SupabaseService } from '~/api/service.ts';
import { Contact } from '~/types/contact.ts';
import { AppResponse } from '~/types/request.ts';
import { DraftUser } from '~/types/user.ts';

export default class ContactsService extends SupabaseService {
  getContacts = async (): AppResponse<Contact[]> => {
    const { data, error } = await this.supabase
      .from('contacts')
      .select('*, user:contactId(email)')
      .returns<Contact[]>();

    if (error) {
      return { error, data: null };
    }

    return {
      data,
      error: null,
    };
  };

  /**
   * Creates a contact if one doesn't exist for this recipient/user. If one does exist, updates the name
   * @param draftUser The user to make into a contact
   * @param userId The current user
   * @returns The recipient's user ID if successful, or an error if one occurred
   */
  createContact = async (userId: string, draftUser: DraftUser) => {
    const { data: recipientId, error } = await this.supabase.rpc(
      'add_contact',
      {
        user_id: userId,
        params: draftUser,
      },
    );

    if (error) {
      return { data: null, error };
    }

    return { data: recipientId, error: null };
  };

  /**
   * Update a contact in supabase
   * @param contact The contact to update
   * @param firstName The new first name
   * @param lastName The new last name
   * @returns an error if one occurred
   */
  updateContact = async (
    contactId: string,
    userId: string,
    firstName: string | null,
    lastName: string | null,
  ): Promise<PostgrestError | null> => {
    const { error } = await this.supabase
      .from('contacts')
      .update({ firstName: firstName, lastName: lastName })
      .eq('userId', userId)
      .eq('contactId', contactId);

    return error;
  };

  /**
   * Gets the contact for a given user
   * @param contactId user ID of contact to retrieve
   * @param userId current user ID
   * @returns The contact if retrieved successfully, otherwise an error
   */
  getContact = async (
    contactId: string,
    userId: string,
  ): AppResponse<Contact> => {
    const { data, error } = await this.supabase
      .from('contacts')
      .select('*, user:contactId(email)')
      .eq('userId', userId)
      .eq('contactId', contactId)
      .returns<Contact>()
      .single();

    if (error) {
      return { error, data: null };
    }

    return {
      data,
      error: null,
    };
  };
}
