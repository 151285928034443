import { css } from '@emotion/react';
import { Close } from '@mui/icons-material';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';

import { User } from '~/types/user.ts';

import AddContactForm from './add_contact_form.tsx';

const styles = {
  paper: css({
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    p: 1,
    mb: 4,
    width: '100%',
    height: '100%',
  }),
  contactBox: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
  }),
};

interface AddNewContactModalProps {
  capsuleId: string;
  userId: string;
  userContacts: User[];
  isOpen: boolean;
  onClose: () => void;
  callback: () => void;
}

const AddNewContactModal: React.FC<AddNewContactModalProps> = ({
  isOpen,
  onClose,
  capsuleId,
  userId,
  userContacts,
  callback,
}) => {
  const handleClose = () => {
    callback();
    onClose();
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="new-contact-modal"
      aria-describedby="new-contact-modal-description"
    >
      <Paper css={[styles.paper]}>
        <Box css={[styles.contactBox]}>
          <Typography variant="h6" ml={2} mt={2}>
            Add New Contact
          </Typography>
          <Button onClick={handleClose}>
            <Close />
          </Button>
        </Box>
        <AddContactForm
          capsuleId={capsuleId}
          userId={userId}
          recipients={userContacts}
          onSave={handleClose}
        />
      </Paper>
    </Modal>
  );
};
export default AddNewContactModal;
