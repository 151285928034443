const THOUSAND = 1000;
const MILLION = THOUSAND * THOUSAND;
const BILLION = MILLION * THOUSAND;

/**
 * Shortens a large number like 1234 into 1.2K
 *
 * Examples:
 *   - 123       -> 123
 *   - 1000      -> 1K
 *   - 1234      -> 1.2K
 *   - 12345     -> 12K
 *   - 123456    -> 123K
 *
 * @param count
 * @returns String with shortened number and magnitude lable
 */
export const shortenNumber = (count: number): string => {
  if (count < THOUSAND) {
    return count.toString();
  }

  let label = '';
  let num: string | number = count;
  if (count >= BILLION) {
    label = 'B';
    num = count / BILLION;
  } else if (count >= MILLION) {
    label = 'M';
    num = count / MILLION;
  } else if (count >= THOUSAND) {
    label = 'K';
    num = count / THOUSAND;
  }

  // Only keep decimal point if right side greater than 0
  // ex: 1.234 -> "1.2" but 1.0 -> "1"
  num = Math.round(num * 10) / 10;

  const numStr = num.toString();
  if (numStr.indexOf('.') > 1) {
    // Only keep decimal point if left side less than 10
    // ex: 1.2 -> "1.2" but 12.3 -> "12"
    num = numStr.slice(0, numStr.indexOf('.'));
  }

  return `${num}${label}`;
};

export const pluralize = (
  count: number,
  single: string,
  plural?: string,
  shorten?: boolean,
) => {
  plural = plural ?? single + 's';
  const label = count === 1 ? single : plural;
  const countLabel = shorten ? shortenNumber(count) : count.toString();

  return `${countLabel} ${label}`;
};

export const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase();
};
