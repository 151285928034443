import { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton } from '@mui/material';

import MediaCarousel from '~/components/media_carousel/media_carousel.tsx';
import MemoryItem from '~/components/memory/memory_item.tsx';
import { MemoryContentBody } from '~/components/memory_content_item/index.tsx';
import { Memory, MemoryContent } from '~/types/memory.ts';
import AppEvents from '~/utils/app_events.ts';

const MemoryMediaCarousel = () => {
  const [open, setOpen] = useState(false);
  const [memory, setMemory] = useState<Memory | undefined>();
  const [memoryContent, setMemoryContent] = useState<
    MemoryContent | undefined
  >();
  const [initialIndex, setInitialIndex] = useState(0);

  const attachments = useMemo(
    () => memoryContent?.attachments || [],
    [memoryContent],
  );
  const thumbnailUrls = useMemo(
    () => memoryContent?.attachments?.map((a) => a.thumbnailUrl) || [],
    [memoryContent],
  );

  const onOpen = useCallback(
    (memory: Memory, content: MemoryContent, selectedIndex: number) => {
      setMemory(memory);
      setMemoryContent(content);
      setInitialIndex(selectedIndex);
      setOpen(true);
    },
    [],
  );

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const onOpenUnbind = AppEvents.on('memory.mediaCarousel.open', onOpen);

    return () => {
      onOpenUnbind();
    };
  }, [onOpen]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      disableEscapeKeyDown={true}
      aria-labelledby="form-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'black',
        },
      }}
    >
      <DialogTitle id="form-dialog-title" component={'div' as const}>
        <IconButton onClick={onClose}>
          <ArrowBack htmlColor={'white'} />
        </IconButton>
      </DialogTitle>

      <MediaCarousel
        muted={!!memoryContent?.audioRecording}
        header={memory && <MemoryItem memory={memory} />}
        footer={
          memoryContent && (
            <MemoryContentBody content={memoryContent} audioAutoPlay={true} />
          )
        }
        attachments={attachments}
        thumbnailUrls={thumbnailUrls}
        initialIndex={initialIndex}
      />
    </Dialog>
  );
};

export default MemoryMediaCarousel;
