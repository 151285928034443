const FILE_UPLOAD_SIZE_LIMIT = 5 * 1024 * 1024 * 1024; // 5gb

export function isFileUploadSizeValid(file: File) {
  return file.size <= FILE_UPLOAD_SIZE_LIMIT;
}

/**
 * Given a mime type, determines the proper extension to use
 * @param mimeType type of media, used to pull extension
 * @returns the extension
 */
export function getExtension(mimeType: string): string {
  let extension = mimeType.split('/')[1];
  if (extension.includes(';')) {
    extension = extension.split(';')[0];
  }
  return extension;
}

// getDefaultFileName creates a default file name for blobs without a name
// For certain mimeTypes, the file extension includes an additional parameter
// for example: "image/jpeg; charset=utf-8" should just be "jpeg"
export const getDefaultFileName = (mimeType: string): string => {
  const ext = getExtension(mimeType);

  const date = new Date();
  return `Virtual Time Capsule ${date.toLocaleDateString()} ${date.toLocaleTimeString()}.${ext}`;
};
