import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';

const CheckEmail = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <img
          src={`${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/public_assets/icons/laptop.svg`}
          style={{ width: '350px', height: '350px' }}
        />
        <Typography
          variant="h4"
          textAlign="center"
          sx={{ marginTop: '20px', marginBottom: '20px' }}
        >
          Please check your email and click the link to verify your account.
        </Typography>
        <Button variant="text" size="small" onClick={() => navigate('/login')}>
          Back to Login
        </Button>
      </Box>
    </>
  );
};

export default CheckEmail;
