import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { parsePhoneNumber } from 'libphonenumber-js';

import { useApi } from '~/api/index.ts';
import EmailField from '~/components/shared/form/email_field';
import FormFieldWrapper from '~/components/shared/form/form_field_wrapper';
import NameFields from '~/components/shared/form/name_fields';
import PhoneNumber from '~/components/shared/form/phone_number_field';
import strings from '~/constants/strings.ts';
import styles from '~/styles/index.ts';
import { SignUpSchema } from '~/utils/validation.tsx';

const signUpStyles = {
  cardContainer: css({
    maxWidth: '526px',
  }),
  card: css({
    padding: '1rem',
  }),
  loginButtonContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  oauthContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem 0',
  }),
  divider: css({
    margin: '1.25rem 6rem',
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  }),
};

interface SignUpParams {
  email: string;
  password: string;
  password2: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const SignUp = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    resolver: zodResolver(SignUpSchema),
    defaultValues: {
      email: email || '',
      password: '',
      password2: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    mode: 'onBlur',
  });
  const api = useApi();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleSuccessfulSignup = () => {
    navigate(`/login/verify-email`);
  };

  const signup = useCallback(
    async ({
      email,
      password,
      password2,
      phoneNumber,
      firstName,
      lastName,
    }: SignUpParams) => {
      const phoneNumberDetails = parsePhoneNumber(phoneNumber);
      const { error } = await api.auth.signup({
        email,
        password,
        password2,
        firstName,
        lastName,
        phoneNumber: phoneNumberDetails.number,
      });

      if (error) {
        toast.error(error?.message || strings.SIGN_UP_FAILED);
      } else {
        reset();
        handleSuccessfulSignup();
      }
    },
    [api, reset],
  );

  return (
    <form onSubmit={handleSubmit(signup)}>
      <div
        className="login-container"
        css={[styles.root, signUpStyles.cardContainer]}
      >
        <div>
          <div>
            <Paper css={signUpStyles.card}>
              <h2>Create Account</h2>
              <EmailField register={register} emailError={errors.email} />
              <FormFieldWrapper>
                <TextField
                  {...register('password')}
                  required={true}
                  error={!!errors.password}
                  helperText={errors.password?.message as string}
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  fullWidth
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          aria-label="Toggle password visibility"
                          size="large"
                          tabIndex={-1}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormFieldWrapper>
              <FormFieldWrapper>
                <TextField
                  {...register('password2')}
                  required={true}
                  error={!!errors.password2}
                  helperText={errors.password2?.message as string}
                  variant="outlined"
                  type={showPassword2 ? 'text' : 'password'}
                  label="Confirm Password"
                  fullWidth
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword2(!showPassword2);
                          }}
                          aria-label="Toggle password visibility"
                          size="large"
                          tabIndex={-1}
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormFieldWrapper>
              <NameFields register={register} errors={errors} />
              <PhoneNumber control={control} />
              <div css={signUpStyles.loginButtonContainer}>
                <Button variant="contained" color="primary" type="submit">
                  Sign Up
                </Button>
              </div>

              <div css={signUpStyles.divider} />

              <div css={signUpStyles.oauthContainer}>
                <div>
                  <Typography variant="caption" css={{ marginRight: '.25rem' }}>
                    {strings.login.ALREADY_HAVE_ACCOUNT}
                  </Typography>
                  <Button color="primary" href="/login">
                    <Typography variant="caption">Log In</Typography>
                  </Button>
                </div>
                <div>
                  <Typography variant="caption" css={{ marginRight: '.25rem' }}>
                    {strings.login.FORGOT_YOUR_PASSWORD}
                  </Typography>
                  <Button color="primary" href="/forgot-password">
                    <Typography variant="caption">Reset Password</Typography>
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SignUp;
