import { useCallback } from 'react';
import { css } from '@emotion/react';

import AudioMiniPlayer from '~/components/memory_content_item/audio_mini_player.tsx';
import { Attachment } from '~/types/attachment.ts';
import { MemoryContent, MemoryFields } from '~/types/memory.ts';
import AppEvents from '~/utils/app_events.ts';

const ContentAttachmentItem = ({
  memory,
  content,
  attachment,
  index,
}: {
  memory: MemoryFields;
  content: MemoryContent;
  attachment: Attachment;
  index: number;
}) => {
  const onClick = useCallback(() => {
    AppEvents.emitter.emit('memory.mediaCarousel.open', memory, content, index);
  }, [memory, content, index]);

  return (
    <img
      css={styles.attachmentImage}
      key={attachment.id}
      src={attachment.thumbnailUrl}
      onClick={onClick}
    />
  );
};

const MemoryAttachments = ({
  memory,
  content,
  attachments,
}: {
  memory: MemoryFields;
  content: MemoryContent;
  attachments: Attachment[];
}) => {
  return (
    <div css={styles.attachmentContainer}>
      {attachments.map((attachment: Attachment, index) => (
        <ContentAttachmentItem
          key={attachment.id}
          memory={memory}
          content={content}
          attachment={attachment}
          index={index}
        />
      ))}
    </div>
  );
};

export const MemoryContentBody = ({
  content,
  audioAutoPlay = false,
}: {
  content: MemoryContent;
  audioAutoPlay?: boolean;
}) => {
  if (!content.content && content.audioRecording) {
    return (
      <AudioMiniPlayer
        containerStyle={content.content && styles.audioRecordingBorder}
        audio={content.audioRecording}
        autoPlay={audioAutoPlay}
      />
    );
  }

  return (
    <div css={styles.contentAudioContainer}>
      <div css={styles.bubble}>{content.content}</div>
      {content.audioRecording && (
        <div css={styles.audioRecordingContainer}>
          <AudioMiniPlayer
            containerStyle={content.content && styles.audioRecordingBorder}
            audio={content.audioRecording}
            autoPlay={audioAutoPlay}
          />
        </div>
      )}
    </div>
  );
};

const MemoryContentItem = ({
  memory,
  content,
}: {
  memory: MemoryFields;
  content: MemoryContent;
}) => {
  return (
    <div css={styles.container}>
      {content.attachments && (
        <MemoryAttachments
          memory={memory}
          content={content}
          attachments={content.attachments}
        />
      )}
      <MemoryContentBody content={content} />
    </div>
  );
};

const styles = {
  contentAudioContainer: css({
    backgroundColor: '#E6E6E6',
    borderRadius: '20px',
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
  }),
  bubble: css({
    padding: '12px',
    backgroundColor: '#E6E6E6',
    borderRadius: '20px',
  }),
  attachmentContainer: css({
    display: 'flex',
    gap: '8px',
  }),
  attachmentImage: css({
    width: '186px',
    height: '110px',
    borderRadius: '8px',
    objectFit: 'cover',
  }),
  audioRecordingContainer: css({
    padding: '8px',
  }),
  audioRecordingBorder: css({
    border: '1px solid #959595',
  }),
};

export default MemoryContentItem;
