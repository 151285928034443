import {
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import DrawerContainer from '~/components/containers/drawer_container.tsx';
import { useGetCurrentUser } from '~/state/users.ts';
import { Persona, UserPersona } from '~/types/persona.ts';

const WhoDoYouIdentify: React.FC<{
  personas: Persona[];
  setSelectedPersonas: any;
  selectedPersonas: UserPersona[];
}> = ({ personas, setSelectedPersonas, selectedPersonas }) => {
  const currentUser = useGetCurrentUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onPersonaSelected = (personaId: string) => {
    setSelectedPersonas((currentSelectedPersonas: UserPersona[]) => {
      // If the persona is already selected, remove it
      if (currentSelectedPersonas.find((up) => up.personaId === personaId)) {
        return currentSelectedPersonas.filter(
          (up) => up.personaId !== personaId,
        );
      }

      // If the user already has 3 personas selected, we can't add another
      if (currentSelectedPersonas.length >= 3) {
        return currentSelectedPersonas;
      }

      // Otherwise, add the persona
      return [
        ...currentSelectedPersonas,
        { userId: currentUser.authId, personaId },
      ];
    });
  };

  return (
    <DrawerContainer noLeftDrawer={true}>
      <Typography
        variant="h5"
        fontWeight={'bold'}
        textAlign={'center'}
        mt={-10}
      >
        What Do You Identify With Most?
      </Typography>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        textAlign={'center'}
        gutterBottom
      >
        (Choose your top 3)
      </Typography>
      <Grid
        container
        spacing={isMobile ? 0 : 2}
        mt={2}
        mb={10}
        maxWidth={800}
        mx="auto"
      >
        {personas.map((identity) => (
          <Grid item xs={6} sm={4} md={3} key={identity.name} mb={1}>
            <Paper
              sx={{
                width: isMobile ? 140 : 150,
                height: 160,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                cursor: 'pointer',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: selectedPersonas.find(
                  (p) => p.personaId === identity.id,
                )
                  ? '#e0e0e0'
                  : 'white',
                border: selectedPersonas.find(
                  (p) => p.personaId === identity.id,
                )
                  ? '2px solid #1976d2'
                  : '2px solid #ffffff',
              }}
              onClick={() => onPersonaSelected(identity.id)}
            >
              <img
                src={identity.image}
                width={'100px'}
                height={'100px'}
                alt={identity.name}
              />
              <Typography
                variant="subtitle1"
                color="text.secondary"
                textAlign={'center'}
                fontSize={'12px'}
                sx={{
                  marginBottom: '10px',
                }}
              >
                {identity.name}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </DrawerContainer>
  );
};

export default WhoDoYouIdentify;
