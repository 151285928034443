import React from 'react';
import { css } from '@emotion/react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from '~/assets/images/vtc-logo.png';
import strings from '~/constants/strings.ts';
import colors from '~/styles/colors.ts';

const drawerWidth = 240;

const styles = {
  flex: css({
    display: 'flex',
  }),
  appBar: css({
    backgroundColor: colors.DARK_BLUE,
  }),
  appBarSimple: css({
    backgroundColor: colors.WHITE,
  }),
  logo: css({
    width: '38px',
    height: '38px',
    marginRight: '16px',
  }),
  appBarHeaderRight: css({
    marginLeft: 'auto',
  }),
};

interface HeaderContainerProps {
  headerLeft?: React.ReactNode;
  headerTitle?: React.ReactNode;
  headerRight?: React.ReactNode;
  noPadding?: Boolean;
  appBarProps?: React.ComponentProps<typeof AppBar>;
  children: React.ReactNode;
}

export default function HeaderContainer({
  children,
  headerLeft,
  headerTitle,
  headerRight,
  noPadding,
  appBarProps,
}: HeaderContainerProps) {
  return (
    <Box css={styles.flex}>
      <CssBaseline />
      <AppBar
        position="fixed"
        css={[styles.appBar]}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        {...appBarProps}
      >
        <Toolbar>
          {headerLeft}

          {headerTitle ?? (
            <>
              <img
                src={Logo}
                alt="Virtual Time Capsule Logo"
                css={styles.logo}
              />
              <Typography variant="h6" noWrap component="div">
                {strings.VIRTUAL_TIME_CAPSULE}
              </Typography>
            </>
          )}

          <div css={styles.appBarHeaderRight}>{headerRight}</div>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: noPadding ? 0 : 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export const SimpleHeaderContainer = ({
  headerLeft,
  headerTitle,
  headerRight,
  noPadding,
  children,
}: HeaderContainerProps) => {
  return (
    <HeaderContainer
      appBarProps={{
        elevation: 1,
        css: styles.appBarSimple,
      }}
      headerLeft={headerLeft}
      headerTitle={headerTitle}
      headerRight={headerRight}
      noPadding={noPadding}
    >
      {children}
    </HeaderContainer>
  );
};