import { SupabaseService } from '~/api/service.ts';
import {
  MemoryContributorInvite,
  MemoryContributors,
} from '~/types/contributions.ts';
import { DraftContributor } from '~/types/user.ts';

export default class ContributionsService extends SupabaseService {
  createMemoryContributorInvite = async (
    invitees: DraftContributor[],
    memoryId: string,
    capsuleId: string,
    userId: string,
  ) => {
    const userEmailList = invitees.map((invite) => invite.user.email);
    const { data: existingInvites, error: existingInvitesError } =
      await this.supabase
        .from('memory_contributor_invites')
        .select('*')
        .in('email', userEmailList)
        .eq('memoryId', memoryId);

    if (existingInvitesError) {
      return { data: null, error: existingInvitesError };
    }

    const existingInviteEmailList = existingInvites.map(
      (invite) => invite.email,
    );

    const newInvitees = invitees.filter(
      (invite) =>
        !existingInvites?.some(
          (existing) => existing.email === invite.user.email,
        ),
    );

    if (newInvitees.length === 0) {
      return { data: existingInvites, error: null };
    }

    const inviteData = newInvitees.map((invite) => ({
      memoryId: invite.memoryId,
      capsuleId: capsuleId,
      firstName: invite.user.firstName,
      lastName: invite.user.lastName,
      email: invite.user.email,
      accepted: false,
      expiresAt: invite.expiresAt,
    }));

    const { data: existingUserData, error: existingUserError } =
      await this.supabase
        .from('users')
        .select('id, firstName, lastName')
        .in('email', existingInviteEmailList);

    if (existingUserError) {
      return { data: null, error: existingUserError };
    }

    //Call upsert contact function to preload a new invited user.
    const contactList = [];
    for (const contact of newInvitees) {
      const { data: contactId, error: contactsError } = await this.supabase.rpc(
        'add_contact',
        {
          user_id: userId,
          params: contact.user,
        },
      );
      if (contactsError) {
        return { data: null, error: contactsError };
      }
      contactList.push({ ...contact, contactId });
    }

    //Create memory_contributors records for newly created contacts
    let contributorData: MemoryContributors[] = [];
    for (const contact of contactList) {
      const newContributorData = {
        contributorId: contact.contactId,
        memoryId: memoryId,
      };
      contributorData.push(newContributorData);
    }

    //If the user already exists, create record in memory_contributors
    if (existingUserData && existingUserData.length > 0) {
      const existingContributorData = existingUserData.map((user) => ({
        contributorId: user.id,
        memoryId: memoryId,
      }));
      contributorData = contributorData.concat(existingContributorData);
    }

    if (contributorData.length > 0) {
      const { error: upsertError } = await this.supabase
        .from('memory_contributors')
        .upsert(contributorData)
        .select();

      if (upsertError) {
        return { data: null, error: upsertError };
      }
    }

    const { data, error } = await this.supabase
      .from('memory_contributor_invites')
      .insert(inviteData)
      .select('*');

    if (error) {
      return { data: null, error };
    }

    return { data, error };
  };

  getContributionRequests = async (email: string) => {
    const { data, error } = await this.supabase
      .from('memory_contributor_invites')
      .select(
        ` 
        *,
        memory:memories(
          *,
          user:users!memories_userId_fkey!inner(*),
          content:memory_content!inner(*)
        ), 
        capsule:capsules!inner(*, sender:users!capsules_senderId_fkey(*))`,
      )
      .eq('email', email);
    if (error) {
      return { error, data: null };
    }

    return { data: data as MemoryContributorInvite[], error: null };
  };

  getContributionRequestsByMemory = async (memoryId: string) => {
    const { data, error } = await this.supabase
      .from('memory_contributor_invites')
      .select('*')
      .eq('memoryId', memoryId);
    if (error) {
      return { error, data: null };
    }
    return { data, error: null };
  };

  updateMemoryContributorInvite = async (
    inviteId: string,
    params: {
      firstName?: string;
      lastName?: string;
      email?: string;
    },
  ) => {
    const { data, error } = await this.supabase
      .from('memory_contributor_invites')
      .update(params)
      .eq('id', inviteId)
      .select('*')
      .single();

    if (error) {
      return { error, data: null };
    }

    return { data, error: null };
  };

  deleteMemoryContributorInvites = async (
    invitesToDelete: DraftContributor[],
  ) => {
    const inviteToDeleteIds = invitesToDelete.map((contributor) =>
      contributor.type === 'existing' ? contributor.invite.id : null,
    );

    const { data, error } = await this.supabase
      .from('memory_contributor_invites')
      .delete()
      .in('id', inviteToDeleteIds)
      .select('*');

    if (error) {
      return { error, data: null };
    }

    //Check for existing users to delete contributions relations if they exist
    const { data: userIdList, error: usersError } = await this.supabase
      .from('users')
      .select('id')
      .in(
        'email',
        invitesToDelete.map((memoryInvite) => memoryInvite.user.email),
      );

    if (usersError) {
      return { error: usersError, data: null };
    }

    const { error: memoryContributorsError } = await this.supabase
      .from('memory_contributors')
      .delete()
      .in(
        'memoryId',
        invitesToDelete.map((memoryInvite) => memoryInvite.memoryId),
      )
      .in(
        'contributorId',
        userIdList.map((user) => user.id),
      );

    if (memoryContributorsError) {
      return { error: memoryContributorsError, data: null };
    }
    return { data, error: null };
  };

  getOrCreateContributorMemoryUserRelations = async (
    email: string,
    userId: string,
  ) => {
    const { data: invites, error: invitesError } = await this.supabase
      .from('memory_contributor_invites')
      .select('*')
      .eq('email', email);

    if (invitesError) {
      return { error: invitesError, data: null };
    }

    const { data: existingContributions, error: contributionsError } =
      await this.supabase
        .from('memory_contributors')
        .select('*')
        .eq('contributorId', userId);

    if (contributionsError) {
      return { error: contributionsError, data: null };
    }

    const existingMemoryIds =
      existingContributions?.map((c) => c.memoryId) || [];

    const newEntries =
      invites?.filter(
        (invite) => !existingMemoryIds.includes(invite.memoryId),
      ) || [];

    if (newEntries.length === 0) {
      return { data: existingContributions, error: null };
    }

    const contributorData = newEntries.map((invite) => ({
      contributorId: userId,
      memoryId: invite.memoryId,
    }));

    const { data: newContributions, error: upsertError } = await this.supabase
      .from('memory_contributors')
      .upsert(contributorData)
      .select('*');

    if (upsertError) {
      return { error: upsertError, data: null };
    }

    const payloadData = [...existingContributions, ...newContributions];

    return {
      data: payloadData,
      error: null,
    };
  };
}
