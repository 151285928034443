export const checkInitialLogin = async (currentUser: any) => {
  const onboardingComplete = currentUser.onboardingComplete;
  const isNewUser =
    onboardingComplete !== undefined && onboardingComplete === false;
  if (isNewUser === true) {
    const redirect = '/onboarding';
    return { newUser: true, redirect };
  } else {
    return { newUser: false, redirect: '/' };
  }
};
