/**
 * Shared types that are not in the database
 */

import { Dayjs } from 'dayjs';

import { Database } from '~/types/database.types';

export interface UserFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

/**
 * @deprecated: please use User object
 * public.users doesn't have membershipId yet, so this is a temporary structure to include that.
 * Once we've implemented membershipId, we should be able to remove this and just use the user object
 */
export type UserData = Database['public']['Tables']['users']['Row'] & {
  membershipId: string;
  onboardingComplete: boolean;
};

export interface ChangedUserFields {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  referralSource?: string;
  ageRange?: string;
  avatarPath?: File | null;
  onboardingComplete?: boolean;
}

/**
 * The attachment type saved in supabase
 */
export enum AttachmentType {
  AUDIO_RECORDING = 'audio_recording',
  ATTACHMENT = 'attachment',
  PREVIEW = 'preview',
  THUMBNAIL = 'thumbnail',
}

export interface CapsuleFormData {
  firstName: string;
  lastName: string;
  email: string;
  sendDate: Dayjs;
  message: string;
}

/**
 * @deprecated: please use User object
 */
export interface Recipient {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  backupEmail?: string;
  phoneNumber?: string;
  backupPhoneNumber?: string;
}

/**
 * @deprecated: please use User object
 */
export interface Contributor {
  firstName: string;
  lastName: string;
  email: string;
}

export interface HasChildren {
  children: React.ReactNode;
}
