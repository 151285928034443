import React from 'react';
import { Box, Button, Container, Typography, Link } from '@mui/material';
import { css } from '@emotion/react';

export const CapsuleRecievedPreviewTemplate: React.FC<{
  recipientName: string;
  senderName: string;
  senderAvatar: string;
  sendDate: string;
  recipientEmail: string;
  message: string;
  thumbnailUrl: string;
  appUrl: string;
}> = ({
  recipientName,
  senderName,
  senderAvatar,
  sendDate,
  recipientEmail,
  message,
  thumbnailUrl,
  appUrl,
}) => {
  return (
    <Container css={previewContainerStyles}>
      <Box css={logoHeaderStyles}>
        <Box
          component="img"
          src="https://ycqwxcwaespzhlmjgkoa.supabase.co/storage/v1/object/public/public_assets/vtc-logo.png"
          width={48}
          height={48}
          alt="Virtual Time Capsule"
        />
        <Typography variant="h5" sx={{ color: '#ffffff', fontWeight: 500 }}>
          Virtual Time Capsule
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: '10px',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          component="img"
          src={
            senderAvatar ||
            'https://ycqwxcwaespzhlmjgkoa.supabase.co/storage/v1/object/public/public_assets/vtc-logo.png'
          }
          width={48}
          height={48}
          sx={{ borderRadius: '50%' }}
          alt="User Avatar"
        />
        <Typography sx={{ color: '#ffffff' }}>
          {senderName} sent you a Virtual Time Capsule on {sendDate}!
        </Typography>
      </Box>

      <Box css={imageContainerStyles}>
        <img css={previewImageStyles} src={thumbnailUrl || 'https://ycqwxcwaespzhlmjgkoa.supabase.co/storage/v1/object/public/public_assets/icons/vtc-watercolor-logo.svg'} alt="Thumbnail" />
      </Box>

      <Typography
        variant="h5"
        sx={{ color: '#ffffff', textAlign: 'center', my: 2 }}
      >
        {message}
      </Typography>

      <Button css={styledButtonStyles} href={appUrl}>
        View your capsule, {recipientName}
      </Button>
      <Typography sx={{ color: '#ffffff', textAlign: 'center' }}>
        A Virtual Time Capsule is a digital treasure trove of memories and
        moments that have been carefully prepared and preserved for you to take
        you on a journey back in time. In order to view the entire Time Capsule,
        sign in or create an account using {recipientEmail} at{' '}
        <Link href={appUrl}>app.tryvtc.com</Link>
        .
        <br />
        <br />
        Need Assistance?{' '}
        <Link href="mailto:hello@tryvtc.com">hello@tryvtc.com</Link>.
      </Typography>

      <Typography sx={{ color: '#ffffff', textAlign: 'center' }}>
        Warm regards,
        <br />
        The Virtual Time Capsule Team
        <br />
        <Link href="https://www.tryvtc.com/">tryvtc.com</Link>
      </Typography>
    </Container>
  );
};

const previewContainerStyles = css({
  margin: '0 auto',
  padding: '20px 25px 48px',
  maxWidth: '600px',
  background: 'linear-gradient(90deg, #2196F3 0%, #112D59 100%)',
});

const logoHeaderStyles = css({
  backgroundColor: '#112C41',
  padding: '12px 24px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  gap: '18px',
});

const imageContainerStyles = css({
  maxWidth: '100%',
  minHeight: '825px',
  height: 'auto',
  borderRadius: '8px',
  marginTop: '24px',
  backgroundImage:
    'url(https://ycqwxcwaespzhlmjgkoa.supabase.co/storage/v1/object/public/public_assets/photoframes/gift.png)',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  padding: '20px',
  position: 'relative',
  '@media (max-width: 1024px)': {
    minHeight: '500px',
  },
  '@media (max-width: 768px)': {
    minHeight: '208px',
  }
});
const previewImageStyles = css({
  position: 'absolute',
  top: '67%',
  left: '65.35%',
  transform: 'translate(-50%, -50%)',
  objectFit: 'contain',
  maxHeight: '40%',
  width: '100%',
  maxWidth: '520px',
  backgroundColor: '#fff',
  boxShadow: 'inset 0px 4px 15px rgba(0, 0, 0, 0.75)',
  '@media (max-width: 1024px)': {
    top: '64%',
    left: '65%',
    maxWidth: '45%',
    width: '250px',
  },
  '@media (max-width: 768px)': {
    maxWidth: '90%',
    width: '155px',
  }
});
const styledButtonStyles = css({
  backgroundColor: '#112C42',
  borderRadius: '8px',
  color: '#fff',
  fontSize: '18px',
  width: '100%',
  marginBottom: '24px',
  padding: '20px',
});
