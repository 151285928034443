import { SupabaseClient } from '@supabase/supabase-js';

import { Database } from '~/types/database.ts';
import { User } from '~/types/user.ts';

export class SupabaseService {
  supabase: SupabaseClient<Database>;

  constructor(supabaseClient: SupabaseClient<Database>) {
    this.supabase = supabaseClient;
  }

  /**
   * Gets the current user from our public users table
   * @returns The logged in user
   */
  getUser = async () => {
    const { data, error } = await this.supabase.auth.getUser();
    if (error) {
      return { error, user: null };
    }

    const { data: user, error: userError } = await this.supabase
      .from('users')
      .select('*')
      .eq('authId', data.user.id)
      .single();

    if (userError) {
      return { error: userError, user: null };
    }

    return {
      user: {
        ...(user as User),
        onboardingComplete: data.user.user_metadata.onboardingComplete,
        membershipId: data.user.user_metadata.membershipId,
      },
      error: null,
    };
  };

  /**
   * Updates the user's avatarPath in the public.users table
   * @param authId auth ID of the user
   * @param avatarPath the path to the user's avatar file
   */
  updateAvatarPath = async (
    authId: string,
    avatarPath: string,
  ): Promise<void> => {
    const { error } = await this.supabase
      .from('users')
      .update({
        avatarPath: avatarPath,
        updatedAt: new Date().toISOString(),
      })
      .eq('authId', authId);

    if (error) {
      throw new Error(error.message);
    }
  };
}
