const EnvelopeIcon = ({ color }: { color: string }) => (
  <div>
    <svg
      width="37"
      height="20"
      viewBox="0 0 37 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5 0.25H14C12.35 0.25 11 1.6 11 3.25V16.75C11 18.415 12.35 19.75 14 19.75H33.5C35.165 19.75 36.5 18.415 36.5 16.75V3.25C36.5 1.6 35.165 0.25 33.5 0.25ZM33.5 5.755L23.75 10.75L14 5.755V3.25L23.75 8.215L33.5 3.25V5.755ZM8 16.75C8 17.005 8.045 17.245 8.075 17.5H2C1.172 17.5 0.5 16.825 0.5 16C0.5 15.175 1.172 14.5 2 14.5H8V16.75ZM5 2.5H8.075C8.045 2.755 8 2.995 8 3.25V5.5H5C4.175 5.5 3.5 4.825 3.5 4C3.5 3.175 4.175 2.5 5 2.5ZM2 10C2 9.175 2.675 8.5 3.5 8.5H8V11.5H3.5C2.675 11.5 2 10.825 2 10Z"
        fill={color}
      />
    </svg>
  </div>
);

export default EnvelopeIcon;
