import {
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { api } from '~/api/index.ts';
import DrawerContainer from '~/components/containers/drawer_container.tsx';

export const onboardingLoader = async () => {
  const { data, error } = await api.capsules.getSentCapsules();
  return { capsules: data, error };
};

const usages = [
  {
    name: 'Documenting Self Improvement',
    icon: 'https://ycqwxcwaespzhlmjgkoa.supabase.co/storage/v1/object/public/public_assets/icons/personas/personal%20development.svg',
  },
  {
    name: 'Tracking Health & Wellness',
    icon: 'https://ycqwxcwaespzhlmjgkoa.supabase.co/storage/v1/object/public/public_assets/icons/personas/health_wellness.svg',
  },
  {
    name: 'Commemorating Special Occasions',
    icon: 'https://ycqwxcwaespzhlmjgkoa.supabase.co/storage/v1/object/public/public_assets/icons/personas/special-occasions.svg',
  },
  {
    name: 'Remembering A Loved One',
    icon: 'https://ycqwxcwaespzhlmjgkoa.supabase.co/storage/v1/object/public/public_assets/icons/personas/posthumous.svg',
  },
];
const UsingVTC: React.FC<{
  selectedUsages: any[];
  handleUsageSelection: (name: string) => void;
}> = ({ selectedUsages, handleUsageSelection }) => {

  return (
    <DrawerContainer noLeftDrawer={true}>
      <Typography variant="h5" textAlign={'center'} gutterBottom>
        <b>How do you see yourself using VTC?</b>
      </Typography>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        textAlign={'center'}
        gutterBottom
      >
        (Choose your top 3)
      </Typography>
      <Grid
        container
        spacing={2}
        mt={2}
        mb={10}
        maxWidth={800}
        mx="auto"
      >
        {usages.map((usage) => (
          <Grid item xs={6} sm={3} key={usage.name}>
            <Paper
              sx={{
                width: 130,
                height: 120,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                cursor: 'pointer',
                paddingBottom: 2,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: selectedUsages.includes(usage.name)
                  ? '#e0e0e0'
                  : 'white',
                border: selectedUsages.includes(usage.name)
                  ? '2px solid #1976d2'
                  : '2px solid #ffffff',
              }}
              onClick={() => handleUsageSelection(usage.name)}
            >
              {/* <Icon>{identity.icon}</Icon> */}
              <img
                src={usage.icon}
                width={'65px'}
                height={'60px'}
                alt={usage.name}
              />
              <Typography fontSize={12} textAlign={'center'}>
                {usage.name}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </DrawerContainer>
  );
};
export default UsingVTC;
