import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

import { MemoryContentInputState } from '~/api/hooks/useMemoryContentInput.ts';
import { CircularProgressWithLabel } from '~/components/circular_progress.tsx';
import { Upload } from '~/types/upload.ts';
import { getUploadThumbnail } from '~/utils/upload.ts';

interface UploadDraftItemProps {
  upload: Upload;
  input: MemoryContentInputState;
}

const RemoveConfirmationModal = ({
  open,
  handleClose,
  onAccept,
}: {
  open: boolean;
  handleClose: () => void;
  onAccept: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to remove this attachment?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onAccept}>Remove</Button>
      </DialogActions>
    </Dialog>
  );
};

const UploadDraftItem = ({ upload, input }: UploadDraftItemProps) => {
  const [openRemoveConfirmationModal, setOpenRemoveConfirmationModal] =
    useState(false);

  const onRemoveConfirmation = useCallback(() => {
    setOpenRemoveConfirmationModal(true);
  }, [setOpenRemoveConfirmationModal]);

  const closeRemoveConfirmationModal = useCallback(() => {
    setOpenRemoveConfirmationModal(false);
  }, [setOpenRemoveConfirmationModal]);

  const onRemove = useCallback(() => {
    input.removeUpload(upload.id);
  }, [input, upload]);

  return (
    <div css={styles.container} key={upload.id}>
      <RemoveConfirmationModal
        open={openRemoveConfirmationModal}
        handleClose={closeRemoveConfirmationModal}
        onAccept={onRemove}
      />
      <img src={getUploadThumbnail(upload)} css={styles.uploadImage} />

      {upload.status === 'uploading' && (
        <div css={styles.progressContainer}>
          <CircularProgressWithLabel value={upload.progress} />
        </div>
      )}
      {upload.status === 'error' && <div css={styles.errorContainer} />}

      <IconButton css={styles.clearButton} onClick={onRemoveConfirmation}>
        <Close htmlColor={'white'} sx={{ fontSize: 15 }} />
      </IconButton>
    </div>
  );
};

const styles = {
  container: css({
    position: 'relative',
    height: '100px',
  }),
  progressContainer: css({
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }),
  uploadImage: css({
    width: '80px',
    height: '100px',
    backgroundColor: '#E6E6E6',
    borderRadius: '8px',
    objectFit: 'cover',
  }),
  errorContainer: css({
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 0, 0, 0.5)',
  }),
  clearButton: css({
    position: 'absolute',
    right: '-2px',
    top: '-2px',
    backgroundColor: 'red',
    width: '18px',
    height: '18px',
  }),
};

export default UploadDraftItem;
