import { apiClient } from '~/api/api_client.ts';
import { SupabaseService } from '~/api/service.ts';
import { usePackageStore } from '~/state/packages.ts';
import { Packages } from '~/types/billing.ts';
import { PackageOverview } from '~/types/package.ts';

type GetPackageOverviewResponse =
  | PackageOverview
  | {
      package: null;
      statistics: null;
      capsuleIds: string[];
    };

interface StripeBillingLinkParams {
  return_url: string;
}

interface CheckoutSessionParams {
  membershipId: string;
  capsuleId: string;
  packageId: string;
  priceId: string;
  checkoutType: string;
  successUrl: string;
  cancelUrl: string;
}

interface GetStripeBillingPortalLinkResponse {
  url: string;
}

interface GetCheckoutSessionURLResponse {
  url: string;
}

export default class BillingService extends SupabaseService {
  getActivePackageOverview = async () => {
    try {
      const response = await apiClient.get<GetPackageOverviewResponse>(
        `/v1/package/overview?active=true`,
      );
      return { data: response, error: null };
    } catch (error) {
      return { error: error as Error, data: null };
    }
  };

  getPackageOverview = async (membershipPackageId?: string) => {
    try {
      // Check if package is already in store
      const state = usePackageStore.getState();
      if (membershipPackageId && state.packages[membershipPackageId]) {
        return {
          data: state.packages[membershipPackageId],
          error: null,
        };
      } else if (!membershipPackageId && state.activeMembershipPackageId) {
        // if no membershipPackageId is associated with the capsule,
        // it's due to the capsule.status == "draft"
        return {
          data: state.packages[state.activeMembershipPackageId],
          error: null,
        };
      }

      if (!membershipPackageId) {
        // if no membershipPackageId is provided, and failed to get active package overview from cache
        // attempt from api
        const { data, error } = await this.getActivePackageOverview();
        if (error) {
          return { data: null, error };
        }

        return { data: data as PackageOverview, error: null };
      }

      // Last resort, fetch from api
      const response = await apiClient.get<GetPackageOverviewResponse>(
        `/v1/package/overview?membership_package_id=${membershipPackageId}`,
      );
      return { data: response as PackageOverview, error: null };
    } catch (error) {
      return { error: error as Error, data: null };
    }
  };

  reloadPackageOverview = async (membershipPackageId: string) => {
    const response = await apiClient.get<GetPackageOverviewResponse>(
      `/v1/package/overview?membership_package_id=${membershipPackageId}`,
    );
    usePackageStore.getState().setPackage(response as PackageOverview);
    return response;
  };

  getPackages = async () => {
    try {
      const { data, error } = await this.supabase
        .from('packages')
        .select('*')
        .returns<Packages[]>();

      if (error) {
        return { error, data: null };
      }
      return { data, error: null };
    } catch (error) {
      return { error, data: null };
    }
  };

  getCheckoutSessionURL = async ({
    membershipId,
    capsuleId,
    packageId,
    priceId,
    checkoutType,
    successUrl,
    cancelUrl,
  }: CheckoutSessionParams) => {
    try {
      const searchParams = new URLSearchParams({
        membership_id: membershipId,
        capsule_id: capsuleId,
        package_id: packageId,
        price_id: priceId,
        checkout_type: checkoutType,
        success_url: successUrl,
        cancel_url: cancelUrl,
      });
      const response = await apiClient.get<GetCheckoutSessionURLResponse>(
        `/v1/stripe/checkout?${searchParams}`,
      );

      if (!response) {
        return {
          error: 'Could not retrieve session. Please try again.',
          data: null,
        };
      }
      return { data: { url: response.url }, error: null };
    } catch (error) {
      return { error, data: null };
    }
  };

  createStripeBillingPortalLink = async (data: StripeBillingLinkParams) => {
    try {
      const searchParams = new URLSearchParams({
        return_url: data.return_url,
      });

      const response = await apiClient.get<GetStripeBillingPortalLinkResponse>(
        `/v1/stripe/billing_portal?${searchParams}`,
      );
      return {
        data: { url: response.url },
        error: null,
      };
    } catch (error) {
      return { error, data: null };
    }
  };
}
