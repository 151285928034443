class FileStorage {
  db!: IDBDatabase;

  constructor() {
    this.init();
  }

  init() {
    const request = indexedDB.open('file_storage_db', 1);
    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBRequest<IDBDatabase>).result;
      db.createObjectStore('files', { keyPath: 'id' });
    };

    request.onsuccess = (event) => {
      this.db = (event.target as IDBRequest<IDBDatabase>).result;
    };

    request.onerror = (event) => {
      const error = (event.target as IDBRequest).error;
      console.error(
        'Error opening database:',
        error ? error.message : 'Unknown error',
      );
    };
  }

  async saveFile(id: string, file: File) {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.db.transaction(['files'], 'readwrite');
      const store = transaction.objectStore('files');
      const request = store.put({ id: id, file: file });

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  async getFile(id: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['files'], 'readonly');
      const store = transaction.objectStore('files');
      const request = store.get(id);

      request.onsuccess = (event) => {
        const result = (event.target as IDBRequest).result;
        if (result) {
          resolve(result.file);
        } else {
          reject('File not found');
        }
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
}

const fileStorage = new FileStorage();
export default fileStorage;
