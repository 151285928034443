import { useState, useCallback } from 'react';
import { Params } from 'react-router-dom';
import { useRouteLoaderData, useLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import BackButton from '~/components/back_button.tsx';
import ErrorView from '~/components/error_view.tsx';
import Toolbar from '~/components/toolbar.tsx';
import strings from '~/constants/strings.ts';
import { CapsuleLoaderData } from '~/routes/capsule/index.tsx';
import draft from '~/state/draft.ts';
import { Capsule } from '~/types/capsule.ts';
import { ContributorContactList } from '~/components/contributions/contributor_contact_list.tsx';
import AddNewMemoryContributorModal from '~/components/contributions/add_new_contributor_modal.tsx';
import { useApi } from '~/api/index.ts';
import { DraftContributor } from '~/types/user';
import toast from 'react-hot-toast';
import { api } from '~/api/index.ts';
import { MemoryContributorInvite } from '~/types/contributions.ts';
import { useGetCurrentUser } from '~/state/users';

export const contributorsLoader = async ({
  params,
}: {
  params: Params<'capsuleId' | 'memoryId'>;
}) => {
  if (!params.memoryId) {
    return {
      memoryId: null,
      memoryContribtutionInvites: null,
      error: strings.CAPSULE_NOT_FOUND,
    };
  }

  const {
    data: memoryContribtutionInvites,
    error: memoryContribtutionInvitesError,
  } = await api.contributions.getContributionRequestsByMemory(params.memoryId);
  if (memoryContribtutionInvitesError) {
    return {
      memoryId: params.memoryId,
      capsuleId: params.capsuleId,
      memoryContribtutionInvites: null,
      error: memoryContribtutionInvitesError,
    };
  }

  const activeDraftContentId = draft.getActiveDraftContentId(params.memoryId);
  if (!activeDraftContentId) {
    draft.resetActiveDraftContent(params.memoryId);
  }

  return {
    memoryId: params.memoryId,
    capsuleId: params.capsuleId,
    memoryContribtutionInvites,
  };
};

const memoryContributorInviteToDraftContributors = (
  invites: MemoryContributorInvite[] | null,
  memoryId: string,
): DraftContributor[] => {
  if (!invites || !invites?.length || !memoryId) {
    return [];
  }

  return invites.map((invite) => {
    return {
      type: 'existing',
      memoryId,
      expiresAt: invite.expiresAt || null,
      user: {
        firstName: invite.firstName,
        lastName: invite.lastName,
        email: invite.email,
      },
      invite,
    };
  });
};

const ContributorsLoaded = ({
  capsule,
  memoryId,
  memoryContribtutionInvites,
}: {
  capsule: Capsule;
  memoryId: string;
  memoryContribtutionInvites: MemoryContributorInvite[] | null;
}) => {
  const currentUser = useGetCurrentUser();
  const api = useApi();
  const [isNewMemoryContributorModalOpen, setIsNewMemoryContributorModalOpen] =
    useState<boolean>(false);
  const toggleNewMemoryContributorModal = () =>
    setIsNewMemoryContributorModalOpen((prev) => !prev);
  const [contributors, setContributors] = useState<DraftContributor[]>(
    memoryContributorInviteToDraftContributors(
      memoryContribtutionInvites,
      memoryId,
    ),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const hasNewContributors = contributors.some(
    (contributor) => contributor.type === 'new',
  );

  const inviteMemoryContributors = useCallback(
    async (contributorsToAdd: DraftContributor[]) => {
      try {
        setLoading(true);
        //Create invite relation objects.
        const { data: contributorData, error: contributorInviteError } =
          await api.contributions.createMemoryContributorInvite(
            contributorsToAdd,
            memoryId,
            capsule.id,
            currentUser.id,
          );

        if (contributorInviteError) {
          return { error: contributorInviteError, data: null };
        }
        //Send Emails
        const { data, error } = await api.supabase.functions.invoke(
          'send-contributor-invite-email',
          {
            body: { capsule: capsule, memoryId, contributorsObj: contributorData },
          },
        );
        if (error) {
          return { error, data: null };
        }
        setLoading(false);
        toast.success(
          'Invitaions sent successfully! Your contributors will receive an email shortly.',
        );
        return { data, error: null };
      } catch (error) {
        setLoading(false);
        toast.error(
          'Error sending invitations. Please verify proper contact data & try again.',
        );
        return { error: error as Error, data: null };
      }
    },
    [contributors],
  );

  return (
    <div css={styles.container}>
      <AddNewMemoryContributorModal
        memoryId={memoryId}
        capsuleId={capsule.id}
        userId={capsule.sender.id}
        contributors={contributors}
        isOpen={isNewMemoryContributorModalOpen}
        onClose={toggleNewMemoryContributorModal}
        callback={(draftContributorData: DraftContributor[]) =>
          setContributors(draftContributorData)
        }
      />
      <Toolbar left={<BackButton />} />
      <div css={styles.header}>
        <Typography variant="h5" fontWeight="bold">
          {strings.contributions.TITLE}
        </Typography>
        <Typography variant="subtitle1" mt={1}>
          {strings.contributions.PAGE_ACTION}
        </Typography>
      </div>
      <div css={styles.content}>
        <Typography variant="h6">
          {strings.recipientsReview.SELECT_FROM_CONTACTS}
        </Typography>
        <ContributorContactList contributorList={contributors} />
      </div>
      <div css={styles.inputContainer}>
        <Button
          variant="outlined"
          color="primary"
          css={styles.button}
          onClick={toggleNewMemoryContributorModal}
        >
          {strings.recipientsReview.ADD_NEW_CONTACTS}
        </Button>
        <Button
          variant="contained"
          color="primary"
          css={styles.button}
          disabled={loading || !hasNewContributors}
          onClick={() => inviteMemoryContributors(contributors)}
        >
          {loading ? 'Sending Invites...' : 'Invite'}
        </Button>
      </div>
    </div>
  );
};

const MemoryContributorsRoute = () => {
  const data = useRouteLoaderData<CapsuleLoaderData>('capsuleRoot');
  const contributorData = useLoaderData<typeof contributorsLoader>();

  if (!data.capsule || (!contributorData && data.error)) {
    return <ErrorView error={data.error} />;
  }

  return (
    <ContributorsLoaded
      capsule={data.capsule}
      memoryId={contributorData.memoryId || ''}
      memoryContribtutionInvites={contributorData.memoryContribtutionInvites}
    />
  );
};
const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column' as const,
  }),
  header: css({
    padding: '16px',
  }),
  button: css({
    marginLeft: '10px',
  }),
  content: css({
    flex: 1,
    display: 'flex',
    gap: '16px',
    padding: '16px',
    overflowY: 'auto' as const,
    flexDirection: 'column',
  }),
  inputContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  }),
};

export default MemoryContributorsRoute;
