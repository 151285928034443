import { v4 as uuid } from 'uuid';

import { DraftAttachment } from '~/types/attachment.ts';
import { AttachmentType } from '~/types/types.ts';

export interface Upload {
  id: string;
  parentId: string;
  objectKey: string;
  status: 'uploading' | 'success' | 'error';
  thumbnailId?: string;
  error?: Error;
  errorLocation?: string;
  imageThumbnail?: Blob;
  imageThumbnailUrl?: string;
  metadata: UploadMetadata;
  progress: number;
  createdAt: string;

  fileLocalStorageCompleted?: boolean;
  generateThumbnailCompleted?: boolean;
  generatePreviewCompleted?: boolean;
  directUploadCompleted?: boolean;
  tusUploadCompleted?: boolean;
  insertRecordCompleted?: boolean;
}

interface UploadMetadata {
  asset: File | Blob;
  dimensions?: Dimensions;
}

export interface Dimensions {
  width: number;
  height: number;
}

export const getDraftAttachmentsFromUpload = ({
  capsuleId,
  contentId,
  upload,
  order,
}: {
  capsuleId: string;
  contentId: string;
  upload: Upload;
  order: number;
}): DraftAttachment => {
  return {
    id: uuid(),
    capsuleId: capsuleId,
    contentId: contentId,
    thumbnailId: upload.thumbnailId,
    blobId: upload.id,
    order,
    type: AttachmentType.ATTACHMENT,
  };
};

export const getDraftAudioFromUpload = ({
  capsuleId,
  contentId,
  upload,
}: {
  capsuleId: string;
  contentId: string;
  upload: Upload;
}): DraftAttachment => {
  return {
    id: uuid(),
    capsuleId: capsuleId,
    contentId: contentId,
    blobId: upload.id,
    type: AttachmentType.AUDIO_RECORDING,
  };
};

export const getDraftThumbnailFromUpload = ({
  capsuleId,
  contentId,
  upload,
}: {
  capsuleId: string;
  contentId: string;
  upload: Upload;
}): DraftAttachment => {
  if (!upload.thumbnailId) {
    throw new Error('upload.thumbnailId is missing');
  }

  return {
    id: upload.thumbnailId,
    capsuleId: capsuleId,
    contentId: contentId,
    blobId: upload.thumbnailId,
    type: AttachmentType.THUMBNAIL,
  };
};

export const isImageUpload = (upload: Upload) => {
  return upload.metadata?.asset?.type?.includes('image');
};

export const isVideoUpload = (upload: Upload) => {
  return upload.metadata?.asset?.type?.includes('video');
};

export const isAudioUpload = (upload: Upload) => {
  return upload.metadata?.asset?.type?.includes('audio');
};

export const isFile = (asset: File | Blob): asset is File => {
  return asset instanceof File;
};

export const isBlob = (asset: File | Blob): asset is Blob => {
  return asset instanceof Blob;
};
