import {
  AuthError,
  Provider,
  SupabaseClient,
  User as SupabaseUser,
} from '@supabase/supabase-js';

import { SupabaseService } from '~/api/service.ts';
import StorageService from '~/api/storage.ts';
import { refetchUser } from '~/state/users';
import { Persona, UserPersona } from '~/types/persona.ts';
import { ChangedUserFields } from '~/types/types.ts';
import { User } from '~/types/user.ts';
import { LoginParams, SignUpParams } from '~/utils/validation.tsx';

export default class AuthService extends SupabaseService {
  storage: StorageService;

  constructor(supabase: SupabaseClient, storage: StorageService) {
    super(supabase);
    this.storage = storage;
  }

  initializeUserWithMembership = async () => {
    const { data, error } = await this.supabase.auth.getUser();
    if (error) {
      return error;
    }

    if (!data.user?.user_metadata?.membershipId) {
      return this.updateUserWithMembership(data.user);
    }
  };

  login = async (data: LoginParams) => {
    const { data: authenticatedUser } =
      await this.supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });

    if (!authenticatedUser?.user) {
      // something went wrong - authenticated user object expected
      return null;
    }

    if (!authenticatedUser.user.user_metadata.membershipId) {
      await this.updateUserWithMembership(authenticatedUser.user);
    }

    return authenticatedUser;
  };

  authenticateWithGoogle = async (): Promise<{
    data: {
      provider: Provider;
      url: string;
    } | null;
    error: AuthError | null;
  }> => {
    const { data, error } = await this.supabase.auth.signInWithOAuth({
      provider: 'google',
    });

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  };

  updateUserWithMembership = async (user: SupabaseUser) => {
    const { data: membership, error: getMembershipError } = await this.supabase
      .from('memberships')
      .select('*')
      .single();

    if (!membership) {
      return getMembershipError;
    }

    const { data, error } = await this.supabase.auth.updateUser({
      data: {
        ...user.user_metadata,
        membershipId: membership?.id,
      },
    });

    if (!data) {
      return error;
    }
  };

  signup = async (data: SignUpParams) => {
    return this.supabase.auth.signUp({
      email: data.email,
      password: data.password,
      options: {
        emailRedirectTo: window.location.origin,
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          onboardingComplete: false,
        },
      },
    });
  };

  getToken = async () => {
    const { data, error } = await this.supabase.auth.getSession();
    if (!data?.session?.access_token) {
      return { token: null, error };
    }

    return { token: data.session?.access_token, error: null };
  };

  sendPasswordResetEmail = async (email: string, redirectTo: string) => {
    return this.supabase.auth.resetPasswordForEmail(email, {
      redirectTo,
    });
  };

  changeUserPassword = async (newPassword: string) => {
    return await this.supabase.auth.updateUser({
      password: newPassword,
    });
  };

  /**
   * Updates the user's info with the passed in values in the auth.users table.
   * The public.users table is updated on the database whenever auth.users is updated
   * @param user public.users object with updated values
   * @param changedFields which fields have been changed and need to be updated
   * @returns an error if something went wrong
   */
  updateUserInfo = async (user: User, changedFields: ChangedUserFields) => {
    const { firstName, lastName, phoneNumber, email } = user;
    //Upload to storage if avatar changed
    let avatarUrl: string | null = '';
    if (changedFields.avatarPath) {
      const url = await this.storage.updateAvatar(changedFields.avatarPath);
      if (!url) {
        return { data: null, error: 'Failed to upload avatar' };
      }
      avatarUrl = url;
    }

    const { data, error } = await this.supabase.auth.updateUser({
      data: {
        firstName: changedFields.firstName ? firstName : undefined,
        lastName: changedFields.lastName ? lastName : undefined,
        phoneNumber: changedFields.phoneNumber ? phoneNumber : undefined,
        avatarPath: avatarUrl && avatarUrl !== '' ? avatarUrl : undefined,
        referralSource: changedFields.referralSource
          ? changedFields.referralSource
          : undefined,
        onboardingComplete: changedFields.onboardingComplete
          ? changedFields.onboardingComplete
          : undefined,
      },
      email: changedFields.email ? email : undefined,
    });

    await refetchUser();

    if (!data) {
      return { data: null, error };
    }

    return { data, error };
  };

  getPersonas = async () => {
    const { data, error } = await this.supabase
      .from('personas')
      .select('*')
      .returns<Persona[]>();

    if (!data) {
      return { data: null, error };
    }
    return { data, error: null };
  };

  getUserPersonas = async (userId: string) => {
    const { data, error } = await this.supabase
      .from('user_personas')
      .select('*')
      .eq('userId', userId)
      .returns<UserPersona[]>();

    if (!data) {
      return { data: null, error };
    }
    return { data, error: null };
  };

  upsertUserPersonas = async (userPersonas: UserPersona[]) => {
    const { data, error } = await this.supabase
      .from('user_personas')
      .upsert(userPersonas, {
        ignoreDuplicates: false,
      })
      .select();

    if (!data) {
      return { data: null, error };
    }
    return { data, error: null };
  };

  replaceUserPersonas = async (userId: string, userPersonas: UserPersona[]) => {
    // First delete existing relationships
    const { error: deleteError } = await this.supabase
      .from('user_personas')
      .delete()
      .eq('userId', userId);

    if (deleteError) {
      return { data: null, error: deleteError };
    }

    // Then insert new relationships
    const { data, error } = await this.supabase
      .from('user_personas')
      .insert(userPersonas)
      .select();

    if (!data) {
      return { data: null, error };
    }
    return { data, error: null };
  };
}
