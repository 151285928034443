import { v4 as uuid } from 'uuid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { useUserStore } from '~/state/users.ts';
import { DraftAttachment } from '~/types/attachment.ts';
import { CapsuleStatus, DraftCapsule } from '~/types/capsule.ts';
import {
  DraftMemory,
  DraftMemoryContent,
  MemoryStatus,
} from '~/types/memory.ts';
import { User } from '~/types/user.ts';

interface DraftState {
  capsules: DraftCapsule[];
  memories: DraftMemory[];
  content: DraftMemoryContent[];
  memoryDraftContentIds: Record<string, string>; // memoryId, contentId
  attachments: DraftAttachment[];
}

interface Actions {
  createNewCapsule: (capsule: DraftCapsule) => void;
  updateCapsule: (capsule: Partial<DraftCapsule>) => void;
  deleteCapsule: (capsuleId: string) => void;

  createNewMemory: (memory: DraftMemory) => void;
  updateMemory: (memory: Partial<DraftMemory>) => void;
  deleteMemory: (memoryId: string) => void;

  createNewMemoryContent: (content: DraftMemoryContent) => void;
  updateMemoryContent: (content: Partial<DraftMemoryContent>) => void;
  deleteMemoryContent: (contentId: string) => void;
}

export const useDraftStore = create<DraftState & Actions>()(
  persist(
    (set) => ({
      capsules: [],
      memories: [],
      content: [],
      memoryDraftContentIds: {},
      attachments: [],

      // capsule actions
      createNewCapsule: (newCapsule) => {
        set((state) => ({
          capsules: [...state.capsules, newCapsule],
        }));
      },
      updateCapsule: (updatedCapsule: Partial<DraftCapsule>) => {
        set((state) => ({
          capsules: state.capsules.map((capsule) =>
            capsule.id === updatedCapsule.id
              ? { ...capsule, ...updatedCapsule }
              : capsule,
          ),
        }));
      },
      deleteCapsule: (capsuleId: string) => {
        set((state) => ({
          capsules: state.capsules.filter(
            (capsule) => capsule.id !== capsuleId,
          ),
        }));
      },

      // memory actions
      createNewMemory: (newMemory: DraftMemory) => {
        set((state) => ({
          memories: [...state.memories, newMemory],
        }));
      },
      updateMemory: (updatedMemory: Partial<DraftMemory>) => {
        set((state) => ({
          memories: state.memories.map((memory) =>
            memory.id === updatedMemory.id
              ? { ...memory, ...updatedMemory }
              : memory,
          ),
        }));
      },
      deleteMemory: (memoryId: string) => {
        set((state) => ({
          memories: state.memories.filter((memory) => memory.id !== memoryId),
        }));
      },

      // memory_content actions
      createNewMemoryContent: (newContent: DraftMemoryContent) => {
        set((state) => ({
          content: [...state.content, newContent],
        }));
      },
      updateMemoryContent: (updatedContent: Partial<DraftMemoryContent>) => {
        set((state) => ({
          content: state.content.map((content) =>
            content.id === updatedContent.id
              ? { ...content, ...updatedContent }
              : content,
          ),
        }));
      },
      deleteMemoryContent: (contentId: string) => {
        set((state) => ({
          content: state.content.filter((content) => content.id !== contentId),
        }));
      },
    }),
    {
      name: 'drafts',
    },
  ),
);

const draft = {
  getActiveDraftContentId: (memoryId: string) => {
    return useDraftStore.getState().memoryDraftContentIds[memoryId];
  },
  resetActiveDraftContentId: (memoryId: string, id?: string) => {
    useDraftStore.getState().memoryDraftContentIds[memoryId] = id || uuid();
  },
  resetActiveDraftContent: (memoryId: string) => {
    const oldActiveDraftContentId = draft.getActiveDraftContentId(memoryId);
    const newActiveDraftContentId = uuid();
    draft.content.create(memoryId, newActiveDraftContentId);
    draft.resetActiveDraftContentId(memoryId, newActiveDraftContentId);
    draft.content.delete(oldActiveDraftContentId);
  },

  capsules: {
    create: () => {
      const newCapsule: DraftCapsule = {
        id: uuid(),
        title: '',
        message: '',
        sendStatus: CapsuleStatus.DRAFT,
      };

      useDraftStore.getState().createNewCapsule(newCapsule);
      return newCapsule;
    },

    update: (updatedCapsule: Partial<DraftCapsule>) => {
      useDraftStore.getState().updateCapsule(updatedCapsule);
    },
    delete: (capsuleId: string) => {
      useDraftStore.getState().deleteCapsule(capsuleId);
    },
    get: (capsuleId: string) => {
      return useDraftStore
        .getState()
        .capsules.find((capsule) => capsule.id === capsuleId);
    },
  },

  memories: {
    create: (capsuleId: string) => {
      const newMemory: DraftMemory = {
        id: uuid(),
        capsuleId,
        title: '',
        description: '',
        userId: '',
        status: MemoryStatus.Draft,
      };

      useDraftStore.getState().createNewMemory(newMemory);
      return newMemory;
    },

    update: (updatedMemory: Partial<DraftMemory>) => {
      useDraftStore.getState().updateMemory(updatedMemory);
    },
    delete: (memoryId: string) => {
      useDraftStore.getState().deleteMemory(memoryId);
    },
    get: (memoryId: string) => {
      return useDraftStore
        .getState()
        .memories.find((memory) => memory.id === memoryId);
    },
    getByCapsuleId: (capsuleId: string) => {
      return useDraftStore
        .getState()
        .memories.filter((memory) => memory.capsuleId === capsuleId);
    },
  },

  content: {
    create: (memoryId: string, contentId?: string) => {
      const currentUser = useUserStore.getState().currentUser as User;
      const newContent: DraftMemoryContent = {
        id: contentId || uuid(),
        userId: currentUser.id,
        memoryId,
        title: '',
        content: '',
      };

      useDraftStore.getState().createNewMemoryContent(newContent);
      return newContent;
    },

    update: (updatedContent: Partial<DraftMemoryContent>) => {
      useDraftStore.getState().updateMemoryContent(updatedContent);
    },
    delete: (contentId: string) => {
      useDraftStore.getState().deleteMemoryContent(contentId);
    },
    get: (contentId: string) => {
      return useDraftStore
        .getState()
        .content.find((content) => content.id === contentId);
    },
    getByMemoryId: (memoryId: string) => {
      return useDraftStore
        .getState()
        .content.filter((content) => content.memoryId === memoryId);
    },
  },
};

export default draft;
