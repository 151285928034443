import toast from 'react-hot-toast';
import { css } from '@emotion/react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';

import { api } from '~/api/index.ts';
import DrawerContainer from '~/components/containers/drawer_container.tsx';
import ProfilePictureUpdate from '~/components/profile/profile_picture_update';
import UserForm from '~/components/profile/user_form';
import strings from '~/constants/strings';

const styles = {
  wrapper: css({
    padding: '0.5rem',
  }),

  pictureWrapper: css({
    margin: '1rem auto 0',
  }),

  changePassword: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
  }),
};

const Profile = () => {
  const handleBillingClick = async () => {
    try {
      const { data, error } = await api.billing.createStripeBillingPortalLink({
        return_url: `${window.location.origin}/profile`,
      });

      if (error || !data) {
        toast.error('Failed to get billing link. Please try again.');
        console.error('Failed to get billing link:', error);
        return;
      }

      window.open(data.url, '_blank');
    } catch (error) {
      console.error('Failed to get billing link:', error);
    }
  };

  return (
    <DrawerContainer>
      <Typography component="h1" variant="h4">
        {strings.profile.TITLE}
      </Typography>
      <Paper css={styles.wrapper} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs="auto" css={styles.pictureWrapper}>
            <ProfilePictureUpdate />
          </Grid>
          <Grid item xs>
            <UserForm />
          </Grid>
        </Grid>
      </Paper>
      <Box css={styles.changePassword}>
        <Typography color="GrayText">
          {strings.profile.CHANGE_PASSWORD}
        </Typography>
        <Button color="primary" href="/forgot-password">
          {strings.RESET_PASSWORD}
        </Button>
      </Box>
      <Typography component="h4" variant="h4" mt={2} mb={2}>
        {strings.profile.MANAGE_BILLING_TITLE}
      </Typography>
      <Paper css={styles.wrapper} elevation={2}>
        <Grid container spacing={2} p={2}>
          <Grid item>
            <Typography component="body" variant="body1" mt={2} mb={2}>
              {strings.profile.MANAGE_BILLING_DESC}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={() => handleBillingClick()}
              startIcon={<CreditCardIcon />}
            >
              {strings.profile.GO_TO_BILLING}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </DrawerContainer>
  );
};

export default Profile;
