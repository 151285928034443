import * as Sentry from '@sentry/react';

export const enum Events {
  FILE_UPLOAD_CACHE_BLOB = 'file_upload_cache_blob',
  FILE_UPLOAD_CACHE_BLOB_FAILED = 'file_upload_cache_blob_failed',
  FILE_UPLOAD_HYDRATING = 'file_upload_hydrating',
  FILE_UPLOAD_HYDRATING_FAILED = 'file_upload_hydrating_failed',
  FILE_GENERATE_THUMBNAIL_STARTED = 'file_generate_thumbnail_started',
  FILE_GENERATE_THUMBNAIL_COMPLETED = 'file_generate_thumbnail_completed',
  FILE_GENERATE_THUMBNAIL_FAILED = 'file_generate_thumbnail_failed',
  FILE_UPLOAD_SELECTED = 'file_upload_selected',
  FILE_UPLOAD_STARTED = 'file_upload_started',
  FILE_UPLOAD_COMPLETED = 'file_upload_completed',
  FILE_UPLOAD_FAILED = 'file_upload_failed',
  FILE_UPLOAD_INSERT_RECORD_STARTED = 'file_upload_insert_record_started',
  FILE_UPLOAD_INSERT_RECORD_COMPLETED = 'file_upload_insert_record_completed',
  FILE_UPLOAD_INSERT_RECORD_FAILED = 'file_upload_insert_record_failed',
  CREATE_PURCHASE_CHECKOUT_SESSION_COMPLETED = 'create_purchase_checkout_session_completed',
  CREATE_PURCHASE_CHECKOUT_SESSION_ERROR = 'create_purchase_checkout_session_failed',
}

export const logEvent = (eventName: Events, props: object = {}) => {
  if (
    import.meta.env.VITE_ENABLE_CONSOLE_LOGGER === 'true' ||
    process.env.NODE_ENV === 'development'
  ) {
    if (props) {
      console.info(`logEvent '${eventName}' with`, {
        ...props,
        timestamp: new Date().toISOString(),
      });
    } else {
      console.info(`logEvent '${eventName}'`);
    }
    return;
  }

  // TODO: send event to an event tracking service
};

export const captureException = (exception: unknown, location?: string) => {
  if (process.env.NODE_ENV === 'development') {
    if (location) {
      console.error(`logException [${location}]`, exception);
    } else {
      console.error('logException', exception);
    }
    return;
  }

  Sentry.addBreadcrumb({ message: `location: ${location}` });
  Sentry.captureException(exception);
};
