import React from 'react';
import { Typography } from '@mui/material';

interface TextProps {
  size?: number;
  title?: boolean;
  bold?: boolean;
  semibold?: boolean;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';
  workSansBold?: boolean;
  lineHeight?: number | string;
  color?: string;
  white?: boolean;
  children: React.ReactNode;
}

const getFontSize = (size?: number) => {
  switch (size) {
    case 12:
      return { fontSize: 12, lineHeight: '16px' };
  }

  return {};
};

const Text = ({
  size,
  title,
  variant: _variant,
  bold: _bold,
  workSansBold: _workSansBold,
  lineHeight: _lineHeight,
  color: _color,
  white,
  semibold,
  children,
}: TextProps) => {
  let color = _color;
  let bold = _bold;
  let workSansBold = _workSansBold;
  let variant = _variant;
  let lineHeight = _lineHeight;

  if (title) {
    bold = true;
    workSansBold = true;
    lineHeight = lineHeight || 2;
    variant = variant || 'h5';
  } else {
    variant = 'body1';
  }

  if (white) {
    color = 'white';
  }

  return (
    <Typography
      variant={variant}
      sx={{
        color,
        fontWeight: bold ? '800' : semibold ? '600' : 'normal',
        fontFamily: workSansBold ? 'Work Sans ' : undefined,
        fontStyle: 'normal',
        lineHeight,
        ...getFontSize(size),
      }}
    >
      {children}
    </Typography>
  );
};

export default Text;
