import React from 'react';
import { css } from '@emotion/react';
import AppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';

import ToolbarLogo from '~/components/icon/toolbar_logo.tsx';
import Text from '~/components/text.tsx';
import strings from '~/constants/strings.ts';
import colors from '~/styles/colors.ts';

interface ToolbarProps {
  title?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  setToolbarSpacing?: boolean;
}

const Toolbar = ({ left, right, setToolbarSpacing = true }: ToolbarProps) => {
  return (
    <>
      <AppBar
        position="fixed"
        css={styles.appBar}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          borderBottom: { xs: 'none', sm: '1px solid #E0E0E0' },
          boxShadow: 'none',
        }}
      >
        <MuiToolbar css={styles.appBar}>
          {left || <div style={{ width: '40px' }} />}
          <div css={styles.titleContainer}>
            <ToolbarLogo />
            <Text
              variant={'h6'}
              title
              workSansBold
              bold
              color={colors.DARK_BLUE}
            >
              {strings.VIRTUAL_TIME_CAPSULE}
            </Text>
          </div>

          {right || <div style={{ width: '40px' }} />}
        </MuiToolbar>
      </AppBar>
      {setToolbarSpacing && <MuiToolbar />}
    </>
  );
};

const styles = {
  appBar: css({
    backgroundColor: colors.WHITE,
    justifyContent: 'space-between',
  }),
  titleContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }),
};

export default Toolbar;
