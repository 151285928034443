import { useCallback, useState, useMemo } from 'react';
import { Params, useNavigate } from 'react-router-dom';
import { useRouteLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import {
  Box,
  Button,
  createTheme,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import { useDebouncedCallback } from 'use-debounce';
import { Attachment } from '~/types/attachment.ts';
import { api, useApi } from '~/api/index.ts';
import AppContainer from '~/components/app_container.tsx';
import BackButton from '~/components/back_button.tsx';
import ErrorView from '~/components/error_view.tsx';
import Toolbar from '~/components/toolbar.tsx';
import strings from '~/constants/strings.ts';
import draft from '~/state/draft.ts';
import colors from '~/styles/colors.ts';
import { Capsule } from '~/types/capsule.ts';
import { Memory } from '~/types/memory.ts';

export const draftCapsuleLoader = async ({
  params,
}: {
  params: Params<'capsuleId'>;
}) => {
  if (!params.capsuleId) {
    return { capsule: null, error: strings.CAPSULE_NOT_FOUND };
  }

  const capsule = draft.capsules.get(params.capsuleId);

  if (!capsule) {
    return { capsule: null, error: strings.CAPSULE_NOT_FOUND };
  }

  return { capsule };
};

export const capsuleLoader = async ({
  params,
}: {
  params: Params<'capsuleId'>;
}) => {
  if (!params.capsuleId) {
    return { capsule: null, error: strings.CAPSULE_NOT_FOUND };
  }

  const { data: capsule, error } = await api.capsules.getCapsuleById(
    params.capsuleId,
  );
  if (error) {
    return { capsule: null, error: strings.CAPSULE_NOT_FOUND };
  }

  const { data: packageOverview, error: packageOverviewError } =
    await api.billing.getPackageOverview(capsule?.membershipPackageId);
  if (packageOverviewError) {
    return { capsule: null, error: packageOverviewError };
  }

  return { capsule, packageOverview, error };
};
const TitleInput = styled(TextField)({
  fontWeight: 'bold',
  fontSize: '1.5rem',
});

const MemoryCard = ({
  memory,
  capsuleId,
  readOnly = false,
}: {
  memory: Memory;
  capsuleId: string;
  readOnly: boolean;
}) => (
  <Paper elevation={1} sx={{ padding: 2, marginBottom: 2, width: '85%' }}>
    <a href={`/capsule/${capsuleId}/memory/${memory.id}`}>
      <Typography
        variant="h6"
        component="div"
        color="textPrimary"
        fontWeight="bold"
      >
        {memory.title}
      </Typography>
    </a>
    {readOnly! && (
      <Button
        variant="contained"
        color="primary"
        href={`/capsule/${capsuleId}/memory/${memory.id}`}
        sx={{ float: 'right' }}
      >
        ADD TO MEMORY
      </Button>
    )}
  </Paper>
);

const CapsuleHeader = ({
  title,
  message,
  onTitleChange,
  onMessageChange,
  readOnly = false,
}: {
  title: string;
  message: string;
  onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onMessageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly: boolean;
}) => (
  <div css={styles.headerContent}>
    <TitleInput
      variant="standard"
      fullWidth
      placeholder={strings.ADD_YOUR_CAPSULE_TITLE}
      InputProps={{ disableUnderline: true }}
      css={styles.titleInput}
      value={title}
      onChange={onTitleChange}
      disabled={readOnly}
    />
    <TextField
      variant="standard"
      fullWidth
      multiline
      placeholder={strings.ADD_YOUR_CAPSULE_DESCRIPTION}
      InputProps={{ disableUnderline: true }}
      css={styles.descriptionInput}
      value={message}
      onChange={onMessageChange}
      disabled={readOnly}
    />
  </div>
);

const CapsuleActions = ({
  onAddMemory,
  onReviewAndSend,
}: {
  //If capsule is scheduled - hide send button

  onAddMemory: () => void;
  onReviewAndSend: () => void;
}) => (
  <div css={styles.submitButtons}>
    <Button variant="outlined" color="primary" onClick={onAddMemory}>
      Add a memory
    </Button>
    <Button variant="contained" color="primary" onClick={onReviewAndSend}>
      Send
    </Button>
  </div>
);

const CapsuleLoaded = ({
  capsule,
  readOnly = false,
}: {
  capsule: Capsule;
  readOnly: boolean;
}) => {
  const navigate = useNavigate();
  const api = useApi();
  const [title, setTitle] = useState(capsule.title);
  const [message, setMessage] = useState(capsule.message);
  const [viewMode] = useState(
    new URLSearchParams(window.location.search).get('viewMode') ||
      (readOnly ? 'public' : ''),
  );

  const updateCapsule = useDebouncedCallback(async () => {
    await api.capsules.update(capsule.id, { title, message });
  }, 1000);

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
      updateCapsule();
    },
    [updateCapsule],
  );

  const handleMessageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMessage(e.target.value);
      updateCapsule();
    },
    [updateCapsule],
  );

  const navigateToAddMemory = useCallback(() => {
    navigate(`/capsule/${capsule.id}/select-prompts`);
  }, [capsule.id, navigate]);

  const navigateToReview = useCallback(() => {
    navigate(`/capsule/${capsule.id}/review/recipients`);
  }, [capsule.id, navigate]);


  const firstAttachment: Attachment | undefined = useMemo(() => {
    let _firstAttachment: Attachment | undefined;
    capsule.memories?.some((memory) =>
      memory.content.find((content) => {
        if (content?.attachments && content.attachments.length > 0) {
          _firstAttachment = content.attachments?.find((a) => !!a.thumbnailUrl);
        }

        if (_firstAttachment) {
          return true;
        }
      }),
    );

    return _firstAttachment;
  }, [capsule.memories]);

  return (
    <>
      <Toolbar left={<BackButton />} />
      <AppContainer>
        <Paper
          elevation={3}
          sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', mb: 4 }}
        >
          <div css={styles.coverImage}>
            <img
              src={
                firstAttachment?.thumbnailUrl ||
                'https://ycqwxcwaespzhlmjgkoa.supabase.co/storage/v1/object/public/public_assets/icons/backgrounds/watercolor-banner.svg'
              }
              alt="cover"
              css={styles.coverImage}
            />
            <div css={styles.coverImagePlaceholderContainer}>
              <div css={styles.coverImageStatusBadge}>
                {capsule.sendStatus.toUpperCase()}
              </div>
            </div>
          </div>
          <CapsuleHeader
            title={title}
            message={message}
            onTitleChange={handleTitleChange}
            onMessageChange={handleMessageChange}
            readOnly={readOnly}
          />
          {!readOnly && (
            <CapsuleActions
              onAddMemory={navigateToAddMemory}
              onReviewAndSend={navigateToReview}
            />
          )}
        </Paper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {capsule.memories.map((memory: Memory) => (
            <MemoryCard
              key={memory.id}
              memory={memory}
              capsuleId={capsule.id}
              readOnly={false}
            />
          ))}
        </Box>

        {(viewMode === 'review' || viewMode === 'public') && (
          <Paper
            elevation={3}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'white',
              boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', mb: 1 }}
              fontWeight="bold"
            >
              Have a story to tell?
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
              Create a virtual time capsule to start sharing your own memories.
            </Typography>
            <Button variant="contained" color="primary">
              Get Started
            </Button>
          </Paper>
        )}
      </AppContainer>
    </>
  );
};

export const CapsulePublicRoute = () => {
  const data = useRouteLoaderData<CapsuleLoaderData>('publicCapsuleView');
  if (!data.capsule || data.error) {
    return <ErrorView error={data.error || strings.CAPSULE_NOT_FOUND} />;
  }
  return <CapsuleLoaded capsule={data.capsule} readOnly={true} />;
};

export const CapsuleIndexRoute = () => {
  const data = useRouteLoaderData<CapsuleLoaderData>('capsuleRoot');
  if (!data.capsule || data.error) {
    return <ErrorView error={data.error || strings.CAPSULE_NOT_FOUND} />;
  }
  return <CapsuleLoaded capsule={data.capsule} readOnly={false} />;
};

const theme = createTheme();
export const styles = {
  headerContent: css({
    padding: theme.spacing(2),
  }),
  breadcrumbs: css({
    position: 'absolute',
    top: theme.spacing(2),
    padding: '20px',
  }),
  coverImage: css({
    position: 'relative',
    height: '300px',
    width: '100%',
    backgroundColor: colors.GRAY,
    backgroundSize: 'cover',
    objectFit: 'cover',
  }),
  coverImageStatusBadge: css({
    position: 'absolute',
    top: '60px',
    right: '10px',
    backgroundColor: '#B388FF',
    color: 'white',
    padding: '4px 8px',
    borderRadius: '12px',
    fontSize: '12px',
  }),
  coverImagePlaceholderContainer: css({
    position: 'absolute',
    width: '100%',
    top: 'calc(50% - 8px)',
    display: 'flex',
    justifyContent: 'center',
  }),
  coverImagePlaceholder: css({
    backgroundColor: grey['400'],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: '20px',
    color: grey['700'],
    fontWeight: 'bold',
  }),
  titleInput: css({
    fontWeight: 'bold',
    fontSize: '1.2rem',
  }),
  descriptionInput: css({
    minHeight: '120px',
  }),
  submitButtons: css({
    display: 'flex',
    justifyContent: 'end',
    gap: theme.spacing(1),
    padding: '20px',
  }),
};export type DraftCapsuleLoaderData = typeof draftCapsuleLoader;
export type CapsuleLoaderData = typeof capsuleLoader;
