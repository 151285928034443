import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { css } from '@emotion/react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Typography, Link, Button, Paper, TextField } from '@mui/material';
import { useApi } from '~/api/index.ts';
import FormFieldWrapper from '~/components/shared/form/form_field_wrapper';
import strings from '~/constants/strings.ts';
import styles from '~/styles/index.ts';

const forgotPWStyles = {
  cardContainer: css({
    maxWidth: '526px',
  }),
  card: css({
    padding: '1rem',
  }),
  buttonContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
};

const ForgotPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');

  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: email || '',
    },
  });

  const api = useApi();

  const submitPasswordResetEmailRequest = useCallback(
    async (data: { email: string }) => {
      setLoading(true);
      const { error } = await api.auth.sendPasswordResetEmail(
        data.email,
        `${import.meta.env.VITE_APP_URL}/reset-password`,
      );

      if (error) {
        toast.error(strings.LOGIN_FAILED);
        setLoading(false);
        return;
      }
      setEmailSent(true);
      setLoading(true);
    },
    [api],
  );

  if (emailSent) {
    return (
      <div css={[styles.root, forgotPWStyles.cardContainer]}>
        <Paper css={forgotPWStyles.card}>
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            <CheckCircle
              color="success"
              sx={{ fontSize: 60, marginBottom: 2 }}
            />
            <Typography variant="h5" gutterBottom>
              {
                strings.forgotPasswordAndReset
                  .FORGOT_YOUR_PASSWORD_SUCCESS_MSG_TITLE
              }
            </Typography>
            <Typography variant="body1">
              {
                strings.forgotPasswordAndReset
                  .FORGOT_YOUR_PASSWORD_SUCCESS_MSG_SUBTITLE
              }
            </Typography>
            <Link href="/login" style={{ marginTop: '1rem', display: 'block' }}>
              {strings.forgotPasswordAndReset.FORGOT_YOUR_PASSWORD_SUCCESS_BTN}
            </Link>
          </div>
        </Paper>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(submitPasswordResetEmailRequest)}>
      <div
        id="login-container"
        css={[styles.root, forgotPWStyles.cardContainer]}
      >
        <div>
          <div>
            <Paper css={forgotPWStyles.card}>
              <Typography variant="h4" fontWeight="bold" mb={1}>
                {strings.forgotPasswordAndReset.FORGOT_YOUR_PASSWORD_TITLE}
              </Typography>
              <Typography variant="subtitle1" mb={1}>
                {strings.forgotPasswordAndReset.FORGOT_YOUR_PASSWORD_SUBTITLE}
              </Typography>
              <FormFieldWrapper>
                <TextField
                  {...register('email')}
                  id="login-email"
                  autoComplete="email"
                  variant="outlined"
                  label="Email"
                  fullWidth
                />
              </FormFieldWrapper>
              <div css={forgotPWStyles.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {strings.forgotPasswordAndReset.FORGOT_YOUR_PASSWORD_BTN}
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ForgotPassword;
