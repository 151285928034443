import { replace } from 'react-router-dom';
import draft from '~/state/draft.ts';
import { usePackageStore } from '~/state/packages.ts';

export const createCapsuleLoader = () => {
  // auto-associate membershipPackageId if an existing active package exists
  // this ensures we can properly track the storage usage
  const packageState = usePackageStore.getState();
  let membershipPackageId: string | undefined;
  if (
    packageState.activeMembershipPackageId &&
    packageState.packages[packageState.activeMembershipPackageId].statistics
      .remainingCapsules > 0
  ) {
    membershipPackageId = packageState.activeMembershipPackageId;
  }

  //If user has no active package or remaining capsules, redirect to select plan page
  if (!membershipPackageId) {
    const newCapsule = draft.capsules.create();
    return replace(`/capsule/${newCapsule.id}/select-plan`);
  }

  const newCapsule = draft.capsules.create();
  return replace(`/capsule/${newCapsule.id}/select-template`);
};
