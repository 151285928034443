import React, { useCallback, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import { Mic, Upload, Videocam } from '@mui/icons-material';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Image from '@mui/icons-material/Image';
import { Box, IconButton, SpeedDial, SpeedDialAction } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { MemoryContentInputState } from '~/api/hooks/useMemoryContentInput.ts';
import AudioMiniPlayer from '~/components/memory/audio_mini_player.tsx';
import AudioRecordModal from '~/components/memory/audio_record_modal.tsx';
import UploadDraftItem from '~/components/memory/upload_draft_item.tsx';
import VideoRecordMemoryModal from '~/components/memory/video_record_memory_modal.tsx';
import { Memory } from '~/types/memory.ts';
import { getUploadThumbnail } from '~/utils/upload.ts';

const actions = [
  {
    id: 'record-video',
    icon: <Videocam />,
    name: <span style={{ whiteSpace: 'nowrap' }}>Record video</span>,
  },
  // NOTE: we'll enable this feature when we have a way to take photos
  // {
  //   id: 'take-photo',
  //   icon: <PhotoCamera />,
  //   name: <span style={{ whiteSpace: 'nowrap' }}>Take photo</span>,
  // },
  {
    id: 'upload',
    icon: <Upload />,
    name: 'Upload',
  },
];

function getInputAcceptTypes(supportedTypes: string[]) {
  return (
    supportedTypes
      // Remove audio from the list of supported types
      .filter((type) => type !== 'audio')
      .map((type) => {
        switch (type) {
          case 'image':
            return 'image/*';
          case 'video':
            return 'video/*';
          default:
            return '';
        }
      })
      .join(',')
  );
}

function AttachmentSpeedDial({
  enableVideoRecord,
  onUploadPress,
  onRecordPress,
}: {
  enableVideoRecord?: boolean;
  onUploadPress: () => void;
  onRecordPress: () => void;
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const enabledActions = useMemo(() => {
    return actions.filter((action) => {
      if (action.id === 'record-video') {
        return enableVideoRecord;
      }

      return true;
    });
  }, [enableVideoRecord]);

  const onClick = useCallback(
    (actionId: string) => {
      switch (actionId) {
        case 'record-video': {
          onRecordPress();
          break;
        }
        case 'take-photo': {
          onRecordPress();
          break;
        }
        case 'upload': {
          onUploadPress();
          break;
        }
      }
    },
    [onRecordPress, onUploadPress],
  );

  return (
    <Box sx={{ transform: 'translateZ(0px)', width: 64, zIndex: 2 }}>
      <SpeedDial
        ariaLabel="Add attachment"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 6,
          right: 12,
        }}
        icon={<Image />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {enabledActions.map((action) => (
          <SpeedDialAction
            key={action.id}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement={'right'}
            tooltipOpen={true}
            onClick={() => {
              onClick(action.id);
              handleClose();
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}

const SubmitButton = ({
  active,
  onSubmit,
}: {
  active: boolean;
  onSubmit: () => void;
}) => {
  return (
    <IconButton
      type={'button'}
      disabled={!active}
      css={styles.button}
      onClick={onSubmit}
      sx={{
        color: active ? 'white' : 'gray',
      }}
    >
      <ArrowUpward />
    </IconButton>
  );
};

const MemoryContentInput = ({
  memory,
  input,
}: {
  memory: Memory;
  input: MemoryContentInputState;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [openVideoRecordModal, setOpenVideoRecordModal] = React.useState(false);
  const [openAudioRecordModal, setOpenAudioRecordModal] = React.useState(false);
  const attachments = useMemo(
    () => input.uploads.map((upload) => upload.metadata.asset),
    [input.uploads],
  );
  const thumbnailUrls = useMemo(
    () =>
      input.uploads
        .map((upload) => getUploadThumbnail(upload))
        .filter(Boolean) as string[],
    [input.uploads],
  );
  const supportedUploadTypes = useMemo(() => {
    if (input.audioRecording) {
      return ['image'];
    } else if (
      input.uploads.some((upload) =>
        upload.metadata.asset.type.includes('video'),
      )
    ) {
      return ['image', 'video'];
    }

    return ['image', 'video', 'audio'];
  }, [input]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      input.setText(event.target.value);
    },
    [input],
  );

  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && input.content) {
        const files = Array.from(e.target.files);
        input.upload(files);
      }
    },
    [input],
  );

  const closeVideoRecordModal = () => {
    setOpenVideoRecordModal(false);
  };

  const closeAudioRecordModal = () => {
    setOpenAudioRecordModal(false);
  };

  const selectFile = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  const recordVideo = useCallback(() => {
    setOpenVideoRecordModal(true);
  }, [setOpenVideoRecordModal]);

  const recordAudio = useCallback(() => {
    setOpenAudioRecordModal(true);
  }, []);

  const onRecordComplete = useCallback(
    (videoBlob: Blob) => {
      input.upload([videoBlob]);
    },
    [input],
  );

  const onAudioRecordComplete = useCallback(
    (audioBlob: Blob) => {
      input.upload([audioBlob]);
    },
    [input],
  );

  const onSubmit = useCallback(() => {
    input.submit();
  }, [input]);

  return (
    <div css={styles.container}>
      <VideoRecordMemoryModal
        memory={memory}
        open={openVideoRecordModal}
        handleClose={closeVideoRecordModal}
        onComplete={onRecordComplete}
      />
      <AudioRecordModal
        memory={memory}
        attachments={attachments}
        thumbnailUrls={thumbnailUrls}
        open={openAudioRecordModal}
        handleClose={closeAudioRecordModal}
        onComplete={onAudioRecordComplete}
      />
      <div css={styles.uploadContainer}>
        <canvas id="thumbnailCanvas" style={{ display: 'none' }} />
        {input.uploads.map((upload) => (
          <UploadDraftItem key={upload.id} upload={upload} input={input} />
        ))}
      </div>
      <input
        ref={inputRef}
        accept={getInputAcceptTypes(supportedUploadTypes)}
        type="file"
        hidden={true}
        onChange={onFileChange}
        multiple
      />
      <AttachmentSpeedDial
        enableVideoRecord={supportedUploadTypes.includes('video')}
        onUploadPress={selectFile}
        onRecordPress={recordVideo}
      />

      <div css={styles.inputAudioContainer}>
        <div css={styles.inputContainer}>
          <TextareaAutosize
            css={[
              styles.input,
              { paddingRight: !input.audioRecording ? 48 : 16 },
            ]}
            value={input.content?.content}
            onChange={onChange}
            placeholder="Share your thoughts"
            maxRows={5}
          />

          {!input.audioRecording && supportedUploadTypes.includes('audio') && (
            <IconButton
              type={'button'}
              css={styles.audioRecordButton}
              onClick={recordAudio}
            >
              <Mic />
            </IconButton>
          )}
        </div>

        {input.audioRecording && (
          <div css={styles.audioRecordingContainer}>
            <AudioMiniPlayer
              audio={input.audioRecording}
              removeUpload={input.removeUpload}
            />
          </div>
        )}
      </div>

      <SubmitButton active={input.canSubmit} onSubmit={onSubmit} />
    </div>
  );
};

const styles = {
  container: css({
    position: 'fixed',
    zIndex: 10,
    bottom: -1,
    left: 0,
    right: 0,
    display: 'flex',
    padding: '16px',
    paddingRight: '12px',
    backgroundColor: 'white',
  }),
  uploadContainer: css({
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    position: 'absolute',
    top: '-100px',
    right: '16px',
  }),
  inputAudioContainer: css({
    backgroundColor: '#E6E6E6',
    borderRadius: '20px',
    marginLeft: '8px',
    marginRight: '8px',
    flex: 1,
  }),
  inputContainer: css({
    flex: 1,
    display: 'flex',
    position: 'relative',
  }),
  input: css({
    flex: 1,
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '20px',
    backgroundColor: '#E6E6E6',
    color: '#000',
    resize: 'none' as const,
    overflow: 'hidden',
    maxHeight: '120px',
    outline: 'none',
    border: 'none',
    fontFamily: 'inherit',
  }),
  audioRecordingContainer: css({
    padding: '8px',
  }),
  button: css({
    width: '36px',
    height: '36px',
    borderRadius: '18px',
    alignSelf: 'flex-end',
    marginBottom: '6px',
    backgroundColor: '#112D59',
  }),
  audioRecordButton: css({
    position: 'absolute',
    right: 12,
    top: 2,
  }),
};

export default MemoryContentInput;
