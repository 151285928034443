import { Memory } from '~/types/memory.ts';
import { User } from '~/types/user.ts';

export enum CapsuleStatus {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  SENT = 'sent',
  RECEIVED = 'received',
  OPENED = 'opened',
}

export interface CapsuleFields {
  // Fields
  id: string;
  title: string;
  message: string;
  senderId: string; // The user who created the capsule
  recipientId?: string; // The user who will receive the capsule
  membershipId: string;
  membershipPackageId?: string;
  sendDate?: string;
  sendStatus: CapsuleStatus;
  createdAt: string;
  updatedAt: string;
}

export interface Capsule extends CapsuleFields {
  // Relationships
  sender: User;
  recipients: User[];
  memories: Memory[];
  videoCount: number;
  photoCount: number;
}

export type DraftCapsule = Pick<
  Capsule,
  'id' | 'sendStatus' | 'title' | 'message'
>;
