import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, Typography } from '@mui/material';
import { PostgrestError } from '@supabase/supabase-js';

const ErrorView = ({ error }: { error: Error | PostgrestError | string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: '2rem',
        backgroundColor: '#f5f5f5',
      }}
    >
      <ErrorOutlineIcon
        sx={{
          fontSize: '4rem',
          marginBottom: '1rem',
        }}
      />
      <Typography variant="h4" component="h1" gutterBottom textAlign="center">
        Oops! <br />
        Something went wrong
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        {error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : error.message}
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/"
        startIcon={<HomeIcon />}
        sx={{ marginTop: '1rem' }}
      >
        Return to Home
      </Button>
    </Box>
  );
};
export default ErrorView;
