import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Avatar } from '@mui/material';

import { api } from '~/api';

const styles = css({
  width: '10rem',
  height: '10rem',
  margin: 'auto',
});

interface ProfilePictureProps {
  /**
   * The path to the user's avatar, from the public.users table
   */
  avatarPath?: string | null;
}

/**
 * Component for displaying the user's profile picture
 */
const ProfilePicture: React.FC<ProfilePictureProps> = (props) => {
  const { avatarPath } = props;
  const [imageUrl, setPictureUrl] = useState('');

  const fetchPictureUrl = useCallback(async () => {
    if (avatarPath) {
      const avatarUrl = await api.storage.getAvatarUrl(avatarPath);
      setPictureUrl(avatarUrl);
    }
  }, [avatarPath]);

  useEffect(() => {
    fetchPictureUrl();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarPath]);

  return <Avatar src={imageUrl} css={styles} />;
};

export default ProfilePicture;
