import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="back"
      onClick={() => navigate(-1)}
      sx={{ color: 'black' }}
    >
      <ArrowBack />
    </IconButton>
  );
};

export default BackButton;
