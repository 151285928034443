import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Session } from '@supabase/supabase-js';

import { useApi } from '~/api/index.ts';

interface AuthContextProps {
  session: Session | null;
  logout: () => void;
}

interface AuthProviderProps {
  initialSession: Session | null;
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextProps>({
  session: null,
  logout: () => {},
});

export const AuthProvider = ({
  initialSession,
  children,
}: AuthProviderProps) => {
  const [session, setSession] = useState<Session | null>(initialSession);
  const api = useApi();

  const initializeSession = useCallback(async () => {
    const { data } = await api.getClient().auth.getSession();
    setSession(data.session);
  }, [api, setSession]);

  const logout = useCallback(async () => {
    await api.getClient().auth.signOut();
  }, [api]);

  useEffect(() => {
    initializeSession();
    const { data: authListener } = api
      .getClient()
      .auth.onAuthStateChange(async (_event, _session) => {
        setSession(_session);
      });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [api, initializeSession]);

  return (
    <AuthContext.Provider value={{ session, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
