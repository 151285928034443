import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';

import { User } from '~/types/user.ts';

export const ContactList = ({ userContacts }: { userContacts: User[] }) => (
  <Box display="flex" flexDirection="column" alignItems="center" width="100%">
    {userContacts.length === 0 ? (
      <Typography variant="body1" color="grey" mt={5}>
        No contacts available
      </Typography>
    ) : (
      <List sx={{ width: '100%' }}>
        {userContacts.map((contact, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar>{contact.firstName?.[0]}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${contact.firstName} ${contact.lastName || ''}`}
              secondary={contact.email}
            />
          </ListItem>
        ))}
      </List>
    )}
  </Box>
);
