import { useCallback, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Params, useNavigate } from 'react-router-dom';
import { useLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import { api } from '~/api/index.ts';
import { PageHeader } from '~/components/capsules';
import ErrorView from '~/components/error_view.tsx';
import strings from '~/constants/strings.ts';
import { Capsule } from '~/types/capsule.ts';
import { Packages } from '~/types/billing.ts';
import { usePackageStore } from '~/state/packages.ts';
import { captureException, Events, logEvent } from '~/utils/logger.ts';

import {
  PackageCard,
  PackageSelectView,
  getCheckoutType,
} from '~/components/packages/index.tsx';

export const packagesLoader = async ({
  params,
}: {
  params: Params<'capsuleId'>;
}) => {
  try {
    if (!params.capsuleId) {
      return {
        error: strings.CAPSULE_NOT_FOUND,
        packages: null,
        capsule: null,
        activePackage: null,
      };
    }

    const { data: capsule, error: capsuleError } =
      await api.capsules.getCapsuleById(params.capsuleId);
    if (capsuleError) {
      return {
        error: strings.CAPSULE_NOT_FOUND,
        packages: null,
        capsule: null,
        activePackage: null,
      };
    }

    const activePackage = usePackageStore.getState().selectedPackage || null;

    const { data, error } = await api.billing.getPackages();

    if (error) {
      return {
        error: 'Error fetching packages',
        packages: null,
        capsule: null,
        activePackage: null,
      };
    }

    return {
      activePackage,
      packages: data,
      capsule: capsule,
    };
  } catch (error) {
    console.error(error);
    return {
      error: 'An error occurred',
      packages: null,
      capsule: null,
      activePackage: null,
    };
  }
};

const ConfirmCapsulePlan = ({
  packages,
  capsule,
  activePackage,
}: {
  packages: any;
  capsule: Capsule | null;
  activePackage: Packages | null;
}) => {
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [changeSelectionView, setChangeSelectionView] =
    useState<boolean>(false);

  useEffect(() => {
    if (activePackage) {
      setSelectedPackage(activePackage);
    }
  }, []);

  const createPurchaseCheckoutSession = useCallback(
    async (pkg: any) => {
      try {
        setLoading(true);
        if (!pkg || !capsule) {
          toast.error(`${strings.payments.SELECT_PACKAGE_ERROR}`);
          return;
        }

        const { data, error } = await api.billing.getCheckoutSessionURL({
          membershipId: capsule.membershipId,
          capsuleId: capsule.id,
          packageId: pkg.id,
          priceId: pkg.stripePriceId,
          checkoutType: getCheckoutType(pkg),
          successUrl: `${window.location.origin}/capsule/${capsule.id}/review/order-complete?success=true`,
          cancelUrl: `${window.location.origin}/capsule/${capsule.id}/review/confirm-plan?success=false`,
        });

        if (error || !data) {
          logEvent(Events.CREATE_PURCHASE_CHECKOUT_SESSION_ERROR, {
            membershipId: capsule.membershipId,
            capsuleId: capsule.id,
            packageId: pkg.id,
            priceId: pkg.stripePriceId,
            error,
          });
          captureException(error, 'billing.createPurchaseCheckoutSession');
          setLoading(false);
          return;
        }

        setLoading(false);
        if (data.url) {
          logEvent(Events.CREATE_PURCHASE_CHECKOUT_SESSION_COMPLETED, {
            membershipId: capsule.membershipId,
            capsuleId: capsule.id,
            packageId: pkg.id,
            priceId: pkg.stripePriceId,
            error,
          });

          window.open(data.url, '_self');
        }
      } catch (error) {
        captureException(error, 'billing.createPurchaseCheckoutSession');
        setLoading(false);
        return;
      }
    },
    [packages],
  );

  const toggleChangeSelectionView = useCallback(async () => {
    try {
      setChangeSelectionView(!changeSelectionView);
    } catch (error) {
      console.log(`${strings.payments.UNEXPECTED_ERROR}`, error);
      setLoading(false);
      return;
    }
  }, [packages, changeSelectionView]);

  const savePackageSelection = useCallback(
    async (pkg: Packages) => {
      try {
        usePackageStore.getState().setSelectedPackage(pkg as Packages);
        setSelectedPackage(pkg);
      } catch (error) {
        setLoading(false);
        return;
      }
    },
    [packages],
  );

  return (
    <div css={styles.container}>
      <PageHeader title={strings.payments.TITLE} onBack={() => navigate(-1)} />
      <div css={styles.content}>
        <Typography variant="subtitle1" css={styles.headerTitle}>
          {strings.payments.PAGE_ACTION}
        </Typography>

        {changeSelectionView ? (
          <PackageSelectView
            packages={packages}
            selectedPackage={selectedPackage}
            setSelectedPackage={savePackageSelection}
          />
        ) : (
          <div
            css={css({
              display: 'grid',
              gap: '20px',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            })}
          >
            {selectedPackage ? (
              <PackageCard
                pkg={selectedPackage}
                isSelected={true}
                onSelect={() => null}
              />
            ) : (
              <Typography variant="body1">
                No package selected - click change selection to select a
                package...
              </Typography>
            )}
          </div>
        )}
      </div>
      <div css={styles.inputContainer}>
        {changeSelectionView ? (
          <Button
            variant="outlined"
            color="primary"
            css={styles.button}
            disabled={!selectedPackage || loading}
            onClick={() => toggleChangeSelectionView()}
          >
            {strings.payments.SELECT_PKG_BTN}
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              color="primary"
              css={styles.button}
              onClick={() => toggleChangeSelectionView()}
            >
              {strings.payments.CHANGESELECTION_BTN}
            </Button>

            <Button
              variant="contained"
              color="primary"
              css={styles.button}
              disabled={!selectedPackage || loading}
              onClick={() => createPurchaseCheckoutSession(selectedPackage)}
            >
              {loading
                ? 'Please wait...'
                : !selectedPackage
                  ? strings.payments.SUBMIT_BTN_DISABLED
                  : strings.payments.SUBMIT_BTN}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const ConfirmCapsulePlanIndex = () => {
  const data = useLoaderData<typeof packagesLoader>();

  if (data.error && !data.packages) {
    return <ErrorView error={data.error} />;
  }

  return (
    <ConfirmCapsulePlan
      packages={data.packages}
      capsule={data.capsule}
      activePackage={data.activePackage}
    />
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100vh',
  }),
  header: css({
    padding: '16px',
  }),
  button: css({
    marginLeft: '10px',
  }),
  backButton: css({
    marginLeft: '10px',
  }),
  headerTitle: css({
    fontWeight: '500',
  }),
  subHeaderTitle: css({
    color: '#666666',
  }),
  headerContent: css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  }),
  content: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '16px',
    padding: '16px',
    overflowY: 'auto' as const,
  }),
  inputContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
  }),
};

export default ConfirmCapsulePlanIndex;
