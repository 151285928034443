import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import strings from '~/constants/strings.ts';
import { Packages } from '~/types/billing.ts';

export const getCheckoutType = (pkg: any) => {
  if (pkg.monthlyCost && pkg.monthlyCost !== null) {
    return `subscription`;
  } else if (pkg.yearlyCost && pkg.yearlyCost !== null) {
    return `subscription`;
  } else if (pkg.oneTimeCost && pkg.oneTimeCost !== null) {
    return `payment`;
  }
  return `payment`;
};

export const PackageCard = ({
  pkg,
  isSelected,
  onSelect,
}: {
  pkg: Packages;
  isSelected: boolean;
  onSelect: () => void;
}) => {
  const getPriceDisplay = () => {
    if (pkg.monthlyCost && pkg.monthlyCost !== null) {
      return `$${pkg.monthlyCost} / Month`;
    } else if (pkg.yearlyCost && pkg.yearlyCost !== null) {
      return `$${pkg.yearlyCost} / Year`;
    } else if (pkg.oneTimeCost && pkg.oneTimeCost !== null) {
      return `$${pkg.oneTimeCost} / One Time`;
    }
    return 'Price not available';
  };

  return (
    <div onClick={onSelect} css={styles.packageCard(isSelected)}>
      {isSelected && (
        <div css={styles.checkmark}>
          <CheckCircle
            color="primary"
            fontSize={'large'}
            sx={{ backgroundColor: '#fff' }}
          />
        </div>
      )}
      <Typography
        variant="h6"
        fontWeight="bold"
        css={styles.packageTitle(isSelected)}
      >
        {pkg.name}
      </Typography>
      <div css={styles.packageContent}>
        <Typography variant="body2" css={styles.packageDescription}>
          {pkg.description}
        </Typography>
        <Typography
          variant="body1"
          css={styles.packageQuantity}
          fontWeight="bold"
        >
          {getPriceDisplay()} for{' '}
          {pkg.maxCapsules > 1000
            ? 'Unlimited Capsules'
            : `${pkg.maxCapsules} Capsule(s)`}
        </Typography>
      </div>
    </div>
  );
};

export const PackageSelectView = ({
  packages,
  selectedPackage,
  setSelectedPackage,
}: {
  packages: Packages[];
  selectedPackage: Packages;
  setSelectedPackage: (pkg: Packages) => void;
}) => {
  return (
    <>
      <Typography variant="subtitle1" fontWeight="bold">
        {strings.selectCapsulePlan.ONE_TIME_PAYMENTS}
      </Typography>
      <div css={styles.packageGrid}>
        {packages
          ?.filter((pkg) => getCheckoutType(pkg) === 'payment')
          .map((pkg: any, index: number) => (
            <PackageCard
              key={index}
              pkg={pkg}
              isSelected={selectedPackage?.friendlyId === pkg.friendlyId}
              onSelect={() => setSelectedPackage(pkg)}
            />
          ))}
      </div>

      <Typography variant="subtitle1" fontWeight="bold">
        {strings.selectCapsulePlan.SUBSCRIPTION_PLAN}
      </Typography>
      <div css={styles.packageGrid}>
        {packages
          ?.filter((pkg) => getCheckoutType(pkg) === 'subscription')
          .map((pkg: any, index: number) => (
            <PackageCard
              key={index}
              pkg={pkg}
              isSelected={selectedPackage?.friendlyId === pkg.friendlyId}
              onSelect={() => setSelectedPackage(pkg)}
            />
          ))}
      </div>
    </>
  );
};

const styles = {
  subHeaderTitle: css({
    color: '#666666',
  }),
  packageGrid: css({
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    border: '2px solid #aaaaaa',
    borderRadius: '8px',
    padding: '20px',
  }),
  packageCard: (isSelected: boolean) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      border: isSelected ? '2px solid #1976d2' : '2px solid #aaaaaa',
      borderRadius: '8px',
      padding: '20px',
      margin: '24px 0',
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      cursor: 'pointer',
      position: 'relative',
      marginTop: '12px',
      '&:hover': {
        borderColor: '#1976d2',
      },
    }),
  checkmark: css({
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    zIndex: 1,
  }),
  packageTitle: (isSelected: boolean) =>
    css({
      marginBottom: '16px',
      color: '#fff',
      position: 'absolute',
      top: '-12px',
      border: isSelected ? '2px solid #1976d2' : '2px solid #aaaaaa',
      borderRadius: '25px',
      backgroundColor: isSelected ? '#1976d2' : '#aaaaaa',
      padding: '0 15px',
    }),
  packageContent: css({
    marginTop: '20px',
  }),
  packageDescription: css({
    color: '#666666',
    marginBottom: '16px',
  }),
  packageQuantity: css({
    marginBottom: '8px',
  }),
};
