import { ChangeEvent, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { css } from '@emotion/react';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { api } from '~/api';
import ProfilePicture from '~/components/shared/profile_picture';
import strings from '~/constants/strings';
import { useGetCurrentUser, refetchUser } from '~/state/users';

const styles = {
  pictureUpdate: css({
    display: 'block',
    padding: '0.25rem',
  }),

  upload: css({
    font: 'inherit',
  }),

  preview: css({
    margin: '0.5rem auto 0',
    width: '5rem',
    height: '5rem',
  }),
};

/**
 * Component for updating the user's profile picture
 */
const ProfilePictureUpdate: React.FC = () => {
  const currentUser = useGetCurrentUser();
  const { authId, avatarPath } = currentUser;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState<string>();
  const [newImage, setNewImage] = useState<File>();
  const [currentAvatarPath, setCurrentAvatarPath] = useState<string | null>(
    null,
  );

  useEffect(() => {
    setCurrentAvatarPath(currentUser.avatarPath);
  }, [currentUser]);

  const onOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setImagePreview(undefined);
    setNewImage(undefined);
  };

  const onCancel = () => {
    closeDialog();
  };

  const updateImage = async () => {
    if (newImage && authId) {
      const newAvatarPath = await api.storage.updateAvatar(
        newImage,
        authId,
        avatarPath,
      );

      if (!newAvatarPath) {
        toast.error(strings.profile.UPLOAD_FAILED);
        return;
      }

      // Trigger a fetch to update the current user
      const updatedUser = await refetchUser();
      if (updatedUser) {
        setCurrentAvatarPath(updatedUser.avatarPath);
      }
      toast.success(strings.profile.UPLOAD_SUCCESSFUL);
    }
  };

  const onChangeImage = () => {
    closeDialog();
    updateImage();
  };

  const onFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const picture = e.target.files![0];
      setNewImage(picture);
      setImagePreview(URL.createObjectURL(picture));
    }
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={onCancel}
        aria-labelledby="pictureUpdateTitle"
        scroll="paper"
      >
        <DialogTitle id="pictureUpdateTitle">
          {strings.profile.UPLOAD_PICTURE_DIALOG_TITLE}
        </DialogTitle>
        <DialogContent>
          <input
            accept="image/*"
            type="file"
            onChange={onFileInput}
            css={styles.upload}
            autoFocus
          />
          {imagePreview && (
            <Avatar css={styles.preview} src={imagePreview}></Avatar>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} variant="contained" color="secondary">
            {strings.CANCEL}
          </Button>
          <Button onClick={onChangeImage} variant="contained" color="primary">
            {strings.profile.CHANGE_IMAGE}
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="outlined"
        color="secondary"
        css={styles.pictureUpdate}
        onClick={onOpenDialog}
      >
        <ProfilePicture avatarPath={currentAvatarPath} />
        {strings.profile.UPDATE_PICTURE}
      </Button>
    </>
  );
};

export default ProfilePictureUpdate;
