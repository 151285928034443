import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Carousel from 'react-multi-carousel';
import ReactPlayer from 'react-player';
import { css } from '@emotion/react';

import {
  Attachment,
  isAttachment,
  isImageBlob,
  isVideoBlob,
} from '~/types/attachment.ts';

import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

interface ImageCarouselProps {
  muted?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  attachments: (Blob | File | Attachment)[];
  thumbnailUrls?: string[];
  initialIndex?: number;
}

const ImageItem = ({
  attachment,
}: {
  attachment: Blob | File | Attachment;
}) => {
  const imageUrl = useMemo(
    () =>
      isAttachment(attachment)
        ? attachment.url
        : URL.createObjectURL(attachment),
    [attachment],
  );

  return (
    <div css={styles.imageContainer}>
      <img src={imageUrl} alt="attachment" css={styles.image} />
    </div>
  );
};

const VideoItem = ({
  attachment,
  playing,
  muted,
}: {
  attachment: Blob | File | Attachment;
  playing: boolean;
  muted: boolean;
}) => {
  const videoUrl = useMemo(
    () =>
      isAttachment(attachment)
        ? attachment.url
        : URL.createObjectURL(attachment),
    [attachment],
  );

  return (
    <div css={styles.videoContainer}>
      <ReactPlayer
        className="react-player"
        url={videoUrl}
        controls
        muted={muted}
        playing={playing}
        playsinline
        loop
        width="100%"
        height="100%"
      />
    </div>
  );
};

const AttachmentItem = ({
  attachment,
  active,
  muted,
}: {
  attachment: Blob | File | Attachment;
  active: boolean;
  muted: boolean;
}) => {
  const isFileOrBlob = attachment instanceof File || attachment instanceof Blob;
  if (isFileOrBlob && attachment.type.includes('image')) {
    return <ImageItem attachment={attachment} />;
  } else if (isFileOrBlob && attachment.type.includes('video')) {
    return <VideoItem attachment={attachment} playing={active} muted={muted} />;
  } else if (isAttachment(attachment) && isImageBlob(attachment.blob)) {
    return <ImageItem attachment={attachment} />;
  } else if (isAttachment(attachment) && isVideoBlob(attachment.blob)) {
    return <VideoItem attachment={attachment} playing={active} muted={muted} />;
  }
};

const ThumbnailItem = ({
  url,
  index,
  active,
  gotoSlide,
}: {
  url: string;
  index: number;
  active: boolean;
  gotoSlide: (index: number) => void;
}) => {
  const onClick = useCallback(() => {
    gotoSlide(index);
  }, [index, gotoSlide]);

  return (
    <div onClick={onClick}>
      <img
        src={url}
        css={[styles.thumbnailImage, active && styles.thumbnailImageActive]}
      />
    </div>
  );
};

const MediaCarousel = ({
  muted = false,
  header,
  footer,
  attachments,
  thumbnailUrls,
  initialIndex = 0,
}: ImageCarouselProps) => {
  const [showCarousel, setShowCarousel] = useState(false);
  const [slideIndex, setSlideIndex] = React.useState(initialIndex);
  const carouselRef = React.useRef<Carousel>(null);

  const gotoSlide = useCallback(
    (index: number) => {
      setSlideIndex(index);
      carouselRef.current?.goToSlide(index);
    },
    [carouselRef],
  );

  const beforeChange = useCallback((nextSlide: number) => {
    setSlideIndex(nextSlide);
  }, []);

  useEffect(() => {
    let showCarouselTimeout = 0;
    if (initialIndex !== 0) {
      gotoSlide(initialIndex);
      showCarouselTimeout = 400;
    }

    // NOTE: carousel library does not have support for initial slide index
    // in order to avoid a jank transition, we delay the carousel visibility
    // https://github.com/YIZHUANG/react-multi-carousel/pull/95
    setTimeout(() => {
      setShowCarousel(true);
    }, showCarouselTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={[styles.container, !showCarousel && { opacity: 0 }]}>
      {header}
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        containerClass="carousel-container"
        beforeChange={beforeChange}
      >
        {attachments.map((attachment, index) => (
          <div key={index} css={styles.carouselItem}>
            <AttachmentItem
              attachment={attachment}
              active={index === slideIndex}
              muted={muted}
            />
          </div>
        ))}
      </Carousel>
      <div>
        {thumbnailUrls && (
          <div css={styles.thumbnailContainer}>
            {thumbnailUrls.map((url, index) => (
              <ThumbnailItem
                key={index}
                url={url}
                index={index}
                active={index === slideIndex}
                gotoSlide={gotoSlide}
              />
            ))}
          </div>
        )}
        {footer}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'auto',
    height: '100%',
    padding: '20px',
  }),
  carouselItem: css({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  thumbnailContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    marginTop: '16px',
  }),
  thumbnailImage: css({
    width: '50px',
    height: '50px',
    borderRadius: '8px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    objectFit: 'cover',
  }),
  thumbnailImageActive: css({
    borderColor: 'white',
  }),
  imageContainer: css({
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  image: css({
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }),
  videoContainer: css({
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  video: css({
    objectFit: 'contain',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
  }),
};

const globalStyles = `
  .carousel-container {
    margin-top: 20px;
    max-height: 100%;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

// Inject global styles
document.head.insertAdjacentHTML('beforeend', `<style>${globalStyles}</style>`);

export default MediaCarousel;
