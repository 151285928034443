import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { parsePhoneNumber } from 'libphonenumber-js';

import { api } from '~/api';
import ButtonWrapper from '~/components/shared/button_wrapper';
import EmailField from '~/components/shared/form/email_field';
import NameFields from '~/components/shared/form/name_fields';
import PhoneNumber from '~/components/shared/form/phone_number_field';
import strings from '~/constants/strings';
import { useGetCurrentUser } from '~/state/users';
import { useUserStore } from '~/state/users';
import { ChangedUserFields } from '~/types/types';
import { User } from '~/types/user.ts';
import { UserSchema } from '~/utils/validation';

const styles = css({
  paddingTop: '0.5rem',
  minWidth: '15rem',
});

/**
 * Creates a form to display and capture basic user data (name, email, phone)
 */
const UserForm: React.FC = () => {
  const userStore = useUserStore();
  const currentUser = useGetCurrentUser();

  const [isEditMode, setIsEditMode] = useState(false);
  const defaultFormValues = useMemo(() => {
    return {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      phoneNumber: currentUser.phoneNumber ? currentUser.phoneNumber : '',
    };
  }, [currentUser]);

  const {
    register,
    formState: { errors, dirtyFields, isDirty, isValid },
    getValues,
    handleSubmit,
    reset,
    control,
    trigger,
  } = useForm({
    resolver: zodResolver(UserSchema),
    defaultValues: defaultFormValues,
    reValidateMode: 'onChange',
    mode: 'all',
  });

  useEffect(() => {
    // Reset form whenever default values change to keep isDirty calculations up to date
    reset(defaultFormValues);
    // Trigger validation after reset
    trigger();
  }, [defaultFormValues, reset, trigger]);

  const onEditProfile = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    reset(defaultFormValues);
    setIsEditMode(false);
  };

  const updateUser = async () => {
    const newValues = getValues();
    const { firstName, lastName, phoneNumber, email } = newValues;
    let needToUpdate = true;

    /**
     * phone from getValues is formatted differently than phone from user,
     * so check that it's truly changed if it's the only dirty field
     */
    const parsedNumber = parsePhoneNumber(phoneNumber);
    if (dirtyFields.phoneNumber && Object.keys(dirtyFields).length === 1) {
      needToUpdate = parsedNumber.number !== defaultFormValues.phoneNumber;
    }

    if (needToUpdate && currentUser) {
      const updatedUser: User = {
        ...currentUser,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: parsedNumber.number,
      };
      const changedFields: ChangedUserFields = {
        firstName: dirtyFields.firstName ? firstName : undefined,
        lastName: dirtyFields.lastName ? lastName : undefined,
        email: dirtyFields.email ? email : undefined,
        phoneNumber: dirtyFields.phoneNumber ? parsedNumber.number : undefined,
      };
      const { error } = await api.auth.updateUserInfo(
        updatedUser,
        changedFields,
      );
      if (error) {
        toast.error(strings.profile.SAVE_FAILED);
        return;
      }

      // Trigger a fetch to update the current user
      await userStore.fetch();
      toast.success(strings.profile.SAVE_SUCCESSFUL);
    }
  };
  const onSubmit = () => {
    if (isDirty) {
      updateUser();
    }

    // Always go back to read only
    setIsEditMode(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} css={styles}>
      <NameFields
        register={register}
        isReadOnly={!isEditMode}
        errors={errors}
        hasDefaultValue
        useFlatUserModel={true}
      />
      <EmailField
        register={register}
        isReadOnly={!isEditMode}
        emailError={errors.email}
        hasDefaultValue
      />
      <PhoneNumber isReadOnly={!isEditMode} control={control} />
      <ButtonWrapper>
        {isEditMode ? (
          <>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              {strings.CANCEL}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid}
            >
              {strings.profile.SAVE_PROFILE}
            </Button>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={onEditProfile}>
            {strings.profile.EDIT_PROFILE}
          </Button>
        )}
      </ButtonWrapper>
    </form>
  );
};

export default UserForm;
