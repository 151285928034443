import { isImageUpload, Upload } from '~/types/upload.ts';

export const getUploadThumbnail = (upload: Upload) =>
  upload.imageThumbnailUrl
    ? upload.imageThumbnailUrl
    : upload.imageThumbnail instanceof Blob
      ? URL.createObjectURL(upload.imageThumbnail)
      : upload.metadata?.asset && isImageUpload(upload)
        ? URL.createObjectURL(upload.metadata.asset)
        : undefined;
