import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { Params, useNavigate } from 'react-router-dom';
import { useLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import { api } from '~/api/index.ts';
import { PageHeader } from '~/components/capsules';
import ErrorView from '~/components/error_view.tsx';
import strings from '~/constants/strings.ts';
import { DraftCapsule } from '~/types/capsule.ts';
import draft from '~/state/draft.ts';
import { usePackageStore } from '~/state/packages.ts';
import { PackageSelectView } from '~/components/packages/index.tsx';
import { Packages } from '~/types/billing.ts';

export const capsulePlanLoader = async ({
  params,
}: {
  params: Params<'capsuleId'>;
}) => {
  try {
    if (!params.capsuleId) {
      return {
        error: strings.CAPSULE_NOT_FOUND,
        packages: null,
        capsule: null,
      };
    }

    const capsule = draft.capsules.get(params.capsuleId);

    if (!capsule) {
      return {
        capsule: null,
        packages: null,
        error: strings.CAPSULE_NOT_FOUND,
      };
    }

    const { data, error } = await api.billing.getPackages();

    if (error) {
      return {
        error: 'Error fetching packages',
        packages: null,
        capsule: null,
      };
    }

    return {
      packages: data,
      capsule: capsule,
    };
  } catch (error) {
    console.error(error);
    return { error: 'An error occurred', packages: null, capsule: null };
  }
};

const SelectCapsulePlan = ({
  packages,
  capsule,
}: {
  packages: Packages[] | null;
  capsule: DraftCapsule | null;
}) => {
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const savePackageAndContinue = useCallback(
    async (pkg: Packages) => {
      try {
        setLoading(true);
        if (!pkg || !capsule) {
          toast.error(`${strings.selectCapsulePlan.SAVE_PACKAGE_ERROR}`);
          return;
        }
        usePackageStore.getState().setSelectedPackage(pkg as Packages);
        setLoading(false);
        navigate(`/capsule/${capsule.id}/select-template`);
      } catch (error) {
        console.log(`${strings.selectCapsulePlan.SAVE_PACKAGE_ERROR}`, error);
        setLoading(false);
        return;
      }
    },
    [packages],
  );

  return (
    <div css={styles.container}>
      <PageHeader
        title={strings.selectCapsulePlan.TITLE}
        onBack={() => navigate(-1)}
      />
      <div css={styles.content}>
        <Typography variant="subtitle1" css={styles.subHeaderTitle}>
          {strings.selectCapsulePlan.PAGE_ACTION}
        </Typography>
        {packages && packages.length > 0 && (
          <PackageSelectView
            packages={packages}
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
          />
        )}
      </div>
      <div css={styles.inputContainer}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          css={styles.button}
          disabled={!selectedPackage || loading}
          onClick={() => savePackageAndContinue(selectedPackage)}
        >
          {loading
            ? 'Please wait...'
            : !selectedPackage
              ? strings.selectCapsulePlan.SUBMIT_BTN_DISABLED
              : strings.selectCapsulePlan.SUBMIT_BTN}
        </Button>
      </div>
    </div>
  );
};

const SelectCapsulePlanIndex = () => {
  const data = useLoaderData<typeof capsulePlanLoader>();

  if (data.error && !data.packages) {
    return <ErrorView error={data.error} />;
  }

  return <SelectCapsulePlan packages={data.packages} capsule={data.capsule} />;
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column' as const,
  }),
  button: css({
    marginLeft: '10px',
    width: '100%',
  }),
  subHeaderTitle: css({
    color: '#666666',
  }),
  content: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '16px',
    padding: '16px',
    overflowY: 'auto' as const,
  }),
  inputContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
  }),
};
export default SelectCapsulePlanIndex;
