import { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useApi } from '~/api/index.ts';
import FormFieldWrapper from '~/components/shared/form/form_field_wrapper';
import strings from '~/constants/strings.ts';
import styles from '~/styles/index.ts';

const resetPWStyles = {
  cardContainer: css({
    maxWidth: '526px',
  }),
  card: css({
    padding: '1rem',
  }),
  loginButtonContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  oauthContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem 0',
  }),
  divider: css({
    margin: '1.25rem 6rem',
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  }),
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(false);

  const api = useApi();

  const changePassword = useCallback(
    async (data: { password: string; confirm_password: string }) => {
      setLoading(true);
      if (data.password !== data.confirm_password) {
        toast.error(
          strings.forgotPasswordAndReset.RESET_PASSWORD_ERROR_MSG_TITLE,
        );
        setLoading(false);
        return;
      }
      const { error } = await api.auth.changeUserPassword(data.password);

      if (error) {
        toast.error(error.message);
        setLoading(false);
        return;
      }

      toast.success(
        strings.forgotPasswordAndReset.RESET_PASSWORD_SUCCESS_MSG_TITLE,
      );

      setTimeout(() => {
        navigate('/login');
      }, 2000);
    },
    [api, navigate],
  );

  useEffect(() => {
    const getToken = async () => {
      const { token, error } = await api.auth.getToken();
      if (!token || error) {
        navigate('/login');
      }
    };
    getToken();
  }, [api.auth]);
  return (
    <form onSubmit={handleSubmit(changePassword)}>
      <div
        id="login-container"
        css={[styles.root, resetPWStyles.cardContainer]}
      >
        <div>
          <div>
            <Paper css={resetPWStyles.card}>
              <Typography variant="h4" fontWeight="bold" mb={1}>
                {strings.forgotPasswordAndReset.RESET_PASSWORD_TITLE}
              </Typography>
              <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>
                {strings.forgotPasswordAndReset.RESET_PASSWORD_SUBTITLE}
              </Typography>
              <FormFieldWrapper>
                <TextField
                  {...register('password')}
                  id="login-password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  autoComplete="current-password"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          aria-label="Toggle password visibility"
                          size="large"
                          tabIndex={-1}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormFieldWrapper>
              <FormFieldWrapper>
                <TextField
                  {...register('confirm_password')}
                  id="login-password"
                  variant="outlined"
                  type={showPassword2 ? 'text' : 'password'}
                  label="Confirm Password"
                  autoComplete="confirm-password"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword2(!showPassword2);
                          }}
                          aria-label="Toggle password visibility"
                          size="large"
                          tabIndex={-1}
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormFieldWrapper>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {strings.forgotPasswordAndReset.RESET_PASSWORD_BTN}
              </Button>
            </Paper>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
