import Resizer from 'react-image-file-resizer';

/**
 * createVideoThumbnail: Creates a thumbnail image from the first frame of a video
 * @param file The video file to create a thumbnail from
 * @param debug Whether to log debug information
 * @returns The Blob of the thumbnail image
 */
export const createVideoThumbnail = async ({
  file,
  debug = false,
}: {
  file: Blob;
  debug?: boolean;
}): Promise<Blob> => {
  const video = document.createElement('video');
  video.muted = true;
  const canvas = document.getElementById(
    'thumbnailCanvas',
  ) as HTMLCanvasElement | null;
  if (!canvas) {
    throw new Error('Unable to find canvas element');
  }

  const ctx = canvas.getContext('2d', {
    willReadFrequently: true,
  }) as CanvasRenderingContext2D | null;
  if (!ctx) {
    throw new Error('Unable to get canvas context');
  }

  const videoObjectUrl = URL.createObjectURL(file);
  debug &&
    console.log(
      `VideoThumbnail: ${Date.now()} - Video object url: ${videoObjectUrl}`,
    );

  video.currentTime = 0;
  video.controls = false;
  video.style.display = 'none';
  video.autoplay = false;
  video.setAttribute('webkit-playsinline', 'webkit-playsinline');
  video.setAttribute('playsinline', 'playsinline');

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    debug &&
      console.log(`VideoThumbnail: ${Date.now()} - Play button image loaded`);

    try {
      video.addEventListener('loadedmetadata', () => {
        debug &&
          console.log(`VideoThumbnail: ${Date.now()} - Video metadata loaded`, {
            videoWidth: video.videoWidth,
            videoHeight: video.videoHeight,
          });
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        debug &&
          console.log(
            `VideoThumbnail: ${Date.now()} - Canvas dimensions: ${canvas.width}x${
              canvas.height
            }`,
          );

        debug &&
          console.log(
            `VideoThumbnail: ${Date.now()} - Start capturing frames...`,
          );
        const captureInterval = setInterval(() => {
          clearInterval(captureInterval);
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Failed to create thumbnail'));
            }
          });
        }, 100);
      });

      debug &&
        console.log(`VideoThumbnail: ${Date.now()} - Start playing video`);

      video.src = videoObjectUrl;
      await video.play();
    } catch (error) {
      debug && console.log(`VideoThumbnail: ${Date.now()} - Error: ${error}`);
      reject(error);
    }
  });
};

export const resizeImage = ({
  file,
  maxWidth = 300,
  maxHeight = 300,
}: {
  file: File | Blob;
  maxWidth?: number;
  maxHeight?: number;
}): Promise<Blob> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as Blob);
      },
      'blob',
    );
  });
