import { css } from '@emotion/react';

import { Memory } from '~/types/memory.ts';
import { User } from '~/types/user.ts';

interface MemoryItemProps {
  memory: Memory;
  user?: User;
}

const MemoryItem = ({ memory }: MemoryItemProps) => {
  return <div css={styles.container}>{memory.title}</div>;
};

const styles = {
  container: css({
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#E6E6E6',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: '20px',
  }),
};

export default MemoryItem;
