import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import { Add as AddIcon } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import Button from '@mui/material/Button';

import { api } from '~/api/index.ts';
import DrawerContainer, {
  drawerWidth,
} from '~/components/containers/drawer_container.tsx';
import ErrorView from '~/components/error_view.tsx';
import ContributorsTab from '~/components/home/contributors_tab.tsx';
import ReceivedCapsulesTab from '~/components/home/received_capsules_tab.tsx';
import SentCapsulesTab from '~/components/home/sent_capsules_tab.tsx';
import ContributorIcon from '~/components/icon/contributor.tsx';
import EnvelopeIcon from '~/components/icon/envelope.tsx';
import PresentIcon from '~/components/icon/present.tsx';
import strings from '~/constants/strings.ts';
import { usePackageStore } from '~/state/packages.ts';
import { getCurrentUser, useGetCurrentUser } from '~/state/users';
import { PackageOverview } from '~/types/package.ts';
import { checkInitialLogin } from '~/utils/auth.ts';

enum HomeTabs {
  SentCapsules = 0,
  ReceivedCapsules = 1,
  Contributors = 2,
}

const CreateCapsuleButton = () => (
  <Button
    variant="contained"
    color="primary"
    style={{ marginRight: '4px', borderRadius: '24px' }}
    startIcon={<AddIcon />}
    sx={{ backgroundColor: '#01579B', textTransform: 'none' }}
    href={'/capsule/new'}
  >
    Create capsule
  </Button>
);

const getTabColor = (isActive: boolean) => (isActive ? '#01579B' : '#9E9E9E');

export const homeLoader = async () => {
  const currentUser = getCurrentUser();

  // Load the active package overview into the store
  const { data: packageOverview, error: packageError } =
    await api.billing.getActivePackageOverview();
  if (packageError) {
    return { error: packageError };
  }

  if (packageOverview?.package) {
    usePackageStore
      .getState()
      .setActiveMembershipPackageId(
        packageOverview.package.membershipPackageId,
      );
    usePackageStore.getState().setPackage(packageOverview as PackageOverview);
  } else {
    usePackageStore.getState().setActiveMembershipPackageId(undefined);
  }

  const { data: personas, error: personaError } = await api.auth.getPersonas();

  if (personaError) {
    return { error: personaError };
  }

  const { data: userPersonas, error: userPersonaError } =
    await api.auth.getUserPersonas(currentUser.authId);

  if (userPersonaError) {
    return { error: userPersonaError };
  }

  const firstUserPersona = userPersonas[0] || null;

  const primaryPersona = firstUserPersona
    ? personas.find((persona) => persona.id === firstUserPersona.personaId)
    : null;

  const { data: sentCapsules, error: sentCapsuleError } =
    await api.capsules.getSentCapsules();
  if (sentCapsuleError) {
    return { error: sentCapsuleError };
  }
  const { data: receivedCapsules, error: receivedCapsuleError } =
    await api.capsules.getReceivedCapsules();
  if (receivedCapsuleError) {
    return { error: receivedCapsuleError };
  }

  const { data: contributionRequests, error: contributionRequestsError } =
    await api.contributions.getContributionRequests(currentUser.email);
  if (contributionRequestsError) {
    return { error: contributionRequestsError };
  }

  return {
    sentCapsules,
    receivedCapsules,
    contributionRequests,
    primaryPersona,
    error: null,
  };
};

const Home = () => {
  const onboardingChecked = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');
  const [tab, setTab] = React.useState(() => {
    switch (tabParam) {
      case 'received':
        return HomeTabs.ReceivedCapsules;
      case 'contributions':
        return HomeTabs.Contributors;
      case 'sent':
      default:
        return HomeTabs.SentCapsules;
    }
  });
  const data = useLoaderData<typeof homeLoader>();
  const currentUser = useGetCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (onboardingChecked.current) {
      return;
    }

    const onboardingCheck = async () => {
      onboardingChecked.current = true;
      const loginCheck = await checkInitialLogin(currentUser);
      if (loginCheck) {
        navigate(loginCheck.redirect);
      }
    };
    onboardingCheck();
  }, [currentUser, navigate]);

  const handleTabChange = (_event: any, newValue: number) => {
    setTab(newValue);
    switch (newValue) {
      case HomeTabs.ReceivedCapsules:
        setSearchParams({ tab: 'received' });
        break;
      case HomeTabs.Contributors:
        setSearchParams({ tab: 'contributions' });
        break;
      case HomeTabs.SentCapsules:
        setSearchParams({ tab: 'sent' });
        break;
    }
  };

  if (!data.sentCapsules || !data.receivedCapsules || data.error) {
    return <ErrorView error={data.error || strings.CAPSULES_NOT_FOUND} />;
  }

  return (
    <DrawerContainer>
      <div css={styles.tabsContainer}>
        {tab === HomeTabs.ReceivedCapsules && (
          <ReceivedCapsulesTab capsules={data.receivedCapsules} />
        )}
        {tab === HomeTabs.SentCapsules && (
          <SentCapsulesTab
            currentUser={currentUser}
            primaryPersona={data.primaryPersona}
            capsules={data.sentCapsules}
          />
        )}
        {tab === HomeTabs.Contributors && (
          <ContributorsTab invites={data.contributionRequests} />
        )}
      </div>

      <Box
        css={styles.createCapsuleButtonContainer}
        sx={{ left: { xs: 0, sm: drawerWidth } }}
      >
        <CreateCapsuleButton />
      </Box>

      <Box
        css={styles.bottomNavigation}
        sx={{ left: { xs: 0, sm: drawerWidth } }}
      >
        <BottomNavigation
          sx={{ height: '100%' }}
          value={tab}
          onChange={handleTabChange}
        >
          <BottomNavigationAction
            sx={{ paddingTop: 1 }}
            icon={
              <EnvelopeIcon
                color={getTabColor(tab === HomeTabs.SentCapsules)}
              />
            }
          />
          <BottomNavigationAction
            sx={{ paddingTop: 1 }}
            icon={
              <PresentIcon
                color={getTabColor(tab === HomeTabs.ReceivedCapsules)}
              />
            }
          />
          <BottomNavigationAction
            sx={{ paddingTop: 1 }}
            icon={
              <ContributorIcon
                color={getTabColor(tab === HomeTabs.Contributors)}
              />
            }
          />
        </BottomNavigation>
      </Box>
    </DrawerContainer>
  );
};

const styles = {
  bottomNavigation: css({
    zIndex: 10,
    position: 'fixed',
    bottom: -1,
    right: 0,
    height: 72,
    backgroundColor: 'white',
    borderTop: '1px solid #EAEAEA',
  }),
  createCapsuleButtonContainer: css({
    zIndex: 20,
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    bottom: 56,
    right: 0,
  }),
  tabsContainer: css({
    paddingBottom: 100,
  }),
};

export default Home;
