import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import { useApi } from '~/api/index.ts';
import GoogleSignInButton from '~/components/auth/google_sign_in_button.tsx';
import FormFieldWrapper from '~/components/shared/form/form_field_wrapper';
import strings from '~/constants/strings.ts';
import styles from '~/styles/index.ts';

const loginStyles = {
  cardContainer: css({
    maxWidth: '526px',
  }),
  card: css({
    padding: '1rem',
  }),
  loginButtonContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  oauthContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem 0',
  }),
  divider: css({
    margin: '1.25rem 6rem',
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  }),
};

const Login = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: email || '',
      password: '',
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const api = useApi();

  const login = useCallback(
    async (data: { email: string; password: string }) => {
      const authenticatedUser = await api.auth.login(data);
      if (!authenticatedUser) {
        toast.error(strings.LOGIN_FAILED);
        return;
      }
      navigate('/');
    },
    [api, navigate],
  );

  const authenticateWithGoogle = useCallback(async () => {
    const { error } = await api.auth.authenticateWithGoogle();
    if (error) {
      toast.error(strings.LOGIN_FAILED);
      return;
    }
    navigate('/');
  }, [api, navigate]);

  return (
    <form onSubmit={handleSubmit(login)}>
      <div id="login-container" css={[styles.root, loginStyles.cardContainer]}>
        <div>
          <div>
            <Paper css={loginStyles.card}>
              <h2>Login</h2>
              <FormFieldWrapper>
                <TextField
                  {...register('email')}
                  id="login-username"
                  autoComplete="email"
                  variant="outlined"
                  label="Email"
                  fullWidth
                />
              </FormFieldWrapper>
              <FormFieldWrapper>
                <TextField
                  {...register('password')}
                  id="login-password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  autoComplete="current-password"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          aria-label="Toggle password visibility"
                          size="large"
                          tabIndex={-1}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormFieldWrapper>
              <div css={loginStyles.loginButtonContainer}>
                <Button variant="contained" color="primary" type="submit">
                  Login
                </Button>
              </div>

              <div css={loginStyles.divider} />

              <div css={loginStyles.oauthContainer}>
                <GoogleSignInButton
                  authenticateWithGoogle={authenticateWithGoogle}
                />
                <div>
                  <Typography variant="caption" css={{ marginRight: '.25rem' }}>
                    {strings.login.DONT_HAVE_ACCOUNT}
                  </Typography>
                  <Button color="primary" href="/sign-up">
                    <Typography variant="caption">Sign Up</Typography>
                  </Button>
                </div>
                <div>
                  <Typography variant="caption" css={{ marginRight: '.25rem' }}>
                    {strings.login.FORGOT_YOUR_PASSWORD}
                  </Typography>
                  <Button color="primary" href="/forgot-password">
                    <Typography variant="caption">Reset Password</Typography>
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
