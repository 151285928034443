import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { css } from '@emotion/react';
import { TextField } from '@mui/material';

import FormFieldWrapper from '~/components/shared/form/form_field_wrapper';
import strings from '~/constants/strings.ts';

const styles = {
  firstName: css({
    marginRight: '0.5rem',
  }),
};

interface NameFieldProps {
  /**
   * Register function from useForm, used to link the name inputs to form validation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;

  /**
   * errors from useForm, used to display any name field errors
   */
  errors?: any;

  /**
   * Whether the inputs should be read-only or editable
   */
  isReadOnly?: boolean;

  /**
   * Whether the inputs will render with a value
   */
  hasDefaultValue?: boolean;

  /**
   * If the names are in a complex form object, include the relevent text to prepend
   */
  registerPrepend?: string;

  /**
   * If the user model is flat, exclude text to prepend
   */
  useFlatUserModel?: boolean;
}

const NameFields: React.FC<NameFieldProps> = (props) => {
  const {
    register,
    isReadOnly,
    errors,
    hasDefaultValue,
    registerPrepend,
    useFlatUserModel,
  } = props;

  let firstNameErrorMessage = '';
  let lastNameErrorMessage = '';
  let firstNameErrors = false;
  let lastNameErrors = false;
  if (errors) {
    firstNameErrorMessage = errors.firstName
      ? (errors.firstName.message as string)
      : '';
    lastNameErrorMessage = errors.lastName
      ? (errors.lastName.message as string)
      : '';
    firstNameErrors = !!errors.firstName;
    lastNameErrors = !!errors.lastName;
  }

  let firstNameRegister = useFlatUserModel ? 'firstName' : 'user.firstName';
  let lastNameRegister = useFlatUserModel ? 'lastName' : 'user.lastName';

  if (registerPrepend) {
    firstNameRegister = registerPrepend + firstNameRegister;
    lastNameRegister = registerPrepend + lastNameRegister;
  }

  return (
    <FormFieldWrapper>
      <TextField
        {...register(firstNameRegister)}
        variant="outlined"
        required
        disabled={isReadOnly}
        label={strings.FIRST_NAME}
        fullWidth
        autoComplete="given-name"
        error={firstNameErrors}
        helperText={firstNameErrorMessage}
        InputLabelProps={{ shrink: hasDefaultValue }}
        css={styles.firstName}
      />
      <TextField
        {...register(lastNameRegister)}
        variant="outlined"
        required
        disabled={isReadOnly}
        label={strings.LAST_NAME}
        fullWidth
        autoComplete="family-name"
        error={lastNameErrors}
        helperText={lastNameErrorMessage}
        InputLabelProps={{ shrink: hasDefaultValue }}
      />
    </FormFieldWrapper>
  );
};
export default NameFields;
