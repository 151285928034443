import React from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { TextField } from '@mui/material';

import FormFieldWrapper from '~/components/shared/form/form_field_wrapper';
import strings from '~/constants/strings.ts';

interface EmailFieldProps {
  /**
   * Register function from useForm, used to link the email input to form validation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;

  /**
   * errors from useForm, used to display any email field errors
   */
  emailError?: FieldError;

  /**
   * Whether the input should be read-only or editable
   */
  isReadOnly?: boolean;

  /**
   * Whether the input will render with a value
   */
  hasDefaultValue?: boolean;

  /**
   * If the email is in a complex form object, include the relevent text to prepend
   */
  registerPrepend?: string;
  /**
   * Validate email patterns
   */
  pattern?: {
    value: RegExp;
    message: string;
  };
}

const EmailField: React.FC<EmailFieldProps> = (props) => {
  const { register, isReadOnly, emailError, hasDefaultValue, registerPrepend } =
    props;

  const emailErrorMessage = emailError ? (emailError.message as string) : '';

  const registerName = registerPrepend ? registerPrepend + 'email' : 'email';

  return (
    <FormFieldWrapper>
      <TextField
        {...register(registerName)}
        variant="outlined"
        required
        disabled={isReadOnly}
        label={strings.EMAIL}
        fullWidth
        autoComplete="email"
        error={!!emailError}
        helperText={emailErrorMessage}
        InputLabelProps={{ shrink: hasDefaultValue }}
      />
    </FormFieldWrapper>
  );
};

export const ContactEmailField: React.FC<EmailFieldProps> = (props) => {
  const { register, isReadOnly, emailError, hasDefaultValue, registerPrepend } =
    props;

  const emailErrorMessage = emailError ? (emailError.message as string) : '';

  const registerName = registerPrepend
    ? registerPrepend + 'user.email'
    : 'user.email';

  return (
    <FormFieldWrapper>
      <TextField
        {...register(registerName)}
        variant="outlined"
        required
        disabled={isReadOnly}
        label={strings.EMAIL}
        fullWidth
        autoComplete="email"
        error={!!emailError}
        helperText={emailErrorMessage}
        InputLabelProps={{ shrink: hasDefaultValue }}
      />
    </FormFieldWrapper>
  );
};

export default EmailField;
