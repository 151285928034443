import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { useRouteLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import { Button, Typography } from '@mui/material';

import { PageHeader } from '~/components/capsules';
import AddNewContactModal from '~/components/capsules/add_new_contact_modal.tsx';
import { ContactList } from '~/components/capsules/contact_list.tsx';
import ErrorView from '~/components/error_view.tsx';
import strings from '~/constants/strings.ts';
import { CapsuleLoaderData } from '~/routes/capsule/index.tsx';
import { Capsule } from '~/types/capsule.ts';

export interface CapsuleReviewPageProps {
  capsule: Capsule;
  handlePageChange: (index: number) => void;
  refreshRecipients: () => void;
}
const RecipientReview = ({
  capsule,
  handlePageChange,
  refreshRecipients,
}: CapsuleReviewPageProps) => {
  const navigate = useNavigate();
  const [isNewContactModalOpen, setIsNewContactModalOpen] = useState(false);

  const toggleNewContactModal = () => setIsNewContactModalOpen((prev) => !prev);

  return (
    <div css={styles.container}>
      <AddNewContactModal
        isOpen={isNewContactModalOpen}
        capsuleId={capsule.id}
        userId={capsule.sender.id}
        userContacts={capsule.recipients}
        onClose={toggleNewContactModal}
        callback={refreshRecipients}
      />
      <PageHeader
        title={strings.recipientsReview.TITLE}
        onBack={() => navigate(-1)}
      />
      <div css={styles.content}>
        <Typography variant="subtitle1" css={styles.headerTitle}>
          {strings.recipientsReview.PAGE_ACTION}
        </Typography>
        <Typography variant="h5" css={styles.headerTitle}>
          {strings.recipientsReview.SELECT_FROM_CONTACTS}
        </Typography>
        <ContactList userContacts={capsule.recipients} />
      </div>
      <div css={styles.inputContainer}>
        <Button
          variant="contained"
          color="primary"
          css={styles.button}
          onClick={toggleNewContactModal}
        >
          {strings.recipientsReview.ADD_NEW_CONTACTS}
        </Button>
        <Button
          variant="contained"
          color="primary"
          css={styles.button}
          onClick={() =>
            capsule.recipients.length > 0
              ? handlePageChange(1)
              : toast.error(`${strings.recipientsReview.NO_CONTACTS_ERROR}`)
          }
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const CapsuleReviewRecipients = () => {
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const data = useRouteLoaderData<CapsuleLoaderData>('capsuleRoot');

  const handlePageChange = useCallback(
    (index: number) => {
      if (index === -1) {
        navigate(-1);
      } else {
        navigate(`/capsule/${data?.capsule?.id}/review/schedule`);
      }
    },
    [navigate, data?.capsule?.id],
  );

  const refreshRecipients = useCallback(() => {
    revalidator.revalidate();
  }, [revalidator]);

  if (!data.capsule || data.error) {
    return <ErrorView error={data.error} />;
  }

  return (
    <RecipientReview
      capsule={data.capsule}
      handlePageChange={handlePageChange}
      refreshRecipients={refreshRecipients}
    />
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100vh',
  }),
  header: css({
    padding: '16px',
  }),
  button: css({
    marginLeft: '10px',
  }),
  backButton: css({
    marginLeft: '10px',
  }),
  headerTitle: css({
    fontWeight: '500',
  }),
  headerContent: css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  }),
  content: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '16px',
    padding: '16px',
    overflowY: 'auto' as const,
  }),
  inputContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  }),
};

export default CapsuleReviewRecipients;
