import { useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { css } from '@emotion/react';
import { Close, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, List, ListItem } from '@mui/material';
import { PostgrestError } from '@supabase/supabase-js';
import { api } from '~/api';
import strings from '~/constants/strings';
import { DraftContributor } from '~/types/user';
import { isEmail } from '~/utils/email';
import ButtonWrapper from '../shared/button_wrapper';
import { ContactEmailField as EmailField } from '../shared/form/email_field';
import NameFields from '../shared/form/name_fields';
import { ContributorsSchema } from '~/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';

const styles = css({
  width: '100%',
});

interface AddMemoryContributorFormProps {
  memoryId: string;
  capsuleId: string;
  userId: string;
  contributors: DraftContributor[] | null;
  onSave: (draftContributorData: DraftContributor[]) => void;
  onClose: () => void;
}

const expiresAtDefault = new Date(
  Date.now() + 7 * 24 * 60 * 60 * 1000,
).toISOString(); // 7 days
const defaultDraftContributorEntry: DraftContributor = {
  type: 'new',
  memoryId: '',
  expiresAt: expiresAtDefault,
  user: {
    firstName: '',
    lastName: '',
    email: '',
  },
};

const AddMemoryContributorForm: React.FC<AddMemoryContributorFormProps> = (
  props,
) => {
  const { memoryId, contributors, onSave, onClose } = props;
  const [contributorsToRemove, setContributorsToRemove] = useState<
    DraftContributor[]
  >([]);

  useEffect(() => {
    setContributorsToRemove([]);
  }, []);

  // load existing users into form
  const defaultFormValues = getDefaultValues(contributors, memoryId);
  const {
    register,
    formState: { isValid, errors, dirtyFields },
    getValues,
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: zodResolver(ContributorsSchema),
    defaultValues: { contributors: defaultFormValues },
    mode: 'onBlur',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contributors',
  });

  const onSubmit = async () => {
    if (!dirtyFields.contributors) {
      return;
    }

    const values = getValues();
    const { contributors } = values;
    // Validate all email addresses
    const invalidEmails = contributors.filter(
      (contributor) => !isEmail.test(contributor?.user?.email),
    );

    if (invalidEmails.length > 0) {
      toast.error('Please enter valid email addresses');
      return;
    }

    const contributorsToAdd: DraftContributor[] = [];
    const contributorsToEdit: DraftContributor[] = [];

    // Go through dirty fields to determine whether we need to add, remove, or edit contributors
    dirtyFields.contributors.forEach((contributorDirty, index) => {
      if (index < contributors.length) {
        const contributor = contributors[index];
        if (contributorDirty?.user?.email) {
          if (contributor.type === 'existing') {
            // existing contributor moved places or email changed
            contributorsToRemove.push(contributor);
          }
          // add contributor no matter what
          contributorsToAdd.push(contributor);
        } else if (
          contributorDirty?.user?.firstName ||
          contributorDirty?.user?.lastName ||
          contributorDirty?.user?.email
        ) {
          contributorsToEdit.push(contributor);
        }
      }
    });

    if (contributorsToEdit.length > 0) {
      // delete contributors on save
      const editErrors =
        await editMemoryContributorsInvites(contributorsToEdit);
      if (editErrors.length > 0) {
        toast.error(`Error deleting contributors. Please try again.`);
        return;
      } else {
        toast.success(`Changes saved.`);
      }
    }

    if (contributorsToRemove.length > 0) {
      // delete contributors on save
      const deleteErrors =
        await deleteMemoryContributorInvites(contributorsToRemove);
      if (deleteErrors.length > 0) {
        toast.error(`Error deleting contributors. Please try again.`);
        return;
      } else {
        toast.success(
          `Contributor(s) removed. They will no longer be able to contribute this memory. You may always add them back later.`,
        );
      }
    }
    reset(values);
    onSave(values.contributors);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <List>
        {fields.map((item, index) => {
          const contributorErrors = errors.contributors
            ? errors.contributors[index]
            : undefined;
          return (
            <ListItem key={item.id}>
              <Box css={styles}>
                <NameFields
                  register={register}
                  errors={contributorErrors}
                  registerPrepend={`contributors.${index}.`}
                />
                <EmailField
                  register={register}
                  registerPrepend={`contributors.${index}.`}
                  isReadOnly={item.type === 'existing'}
                  pattern={{
                    value: isEmail,
                    message: 'Please enter a valid email address',
                  }}
                ></EmailField>
              </Box>
              {!item.id ? (
                <IconButton
                  aria-label="remove contributor draft"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <Close />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="delete contributor"
                  onClick={() => {
                    const values = getValues();
                    const contributor = values.contributors[index];
                    if (contributor.type === 'existing') {
                      contributorsToRemove.push(contributor);
                    }
                    remove(index);
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </ListItem>
          );
        })}
      </List>
      <Box display="flex" justifyContent="center" mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            append({ ...defaultDraftContributorEntry, memoryId });
          }}
        >
          Add More
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" mb={3}>
        <ButtonWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || !dirtyFields.contributors}
          >
            {strings.SAVE_CHANGES}
          </Button>
        </ButtonWrapper>
      </Box>
    </form>
  );
};

const editMemoryContributorsInvites = async (
  contributorsToEdit: DraftContributor[],
): Promise<PostgrestError[]> => {
  const errors: any = [];

  if (contributorsToEdit.length === 0) {
    return errors;
  }

  for (const contributor of contributorsToEdit) {
    if (contributor.type === 'existing') {
      const { error } = await api.contributions.updateMemoryContributorInvite(
        contributor.invite.id,
        {
          firstName: contributor.user.firstName,
          lastName: contributor.user.lastName,
          email: contributor.user.email,
        },
      );

      if (error) {
        errors.push(error);
      }
    }
  }

  return errors;
};

const deleteMemoryContributorInvites = async (
  invitesToDelete: DraftContributor[],
): Promise<PostgrestError[]> => {
  const deleteErrors: PostgrestError[] = [];
  if (invitesToDelete.length < 1) {
    return deleteErrors;
  }

  const { error: deleteError } =
    await api.contributions.deleteMemoryContributorInvites(invitesToDelete);

  if (deleteError) {
    deleteErrors.push(deleteError);
  }

  return deleteErrors;
};

const getDefaultValues = (
  contributors: DraftContributor[] | null,
  memoryId: string,
): DraftContributor[] => {
  if (!contributors?.length) {
    return [{ ...defaultDraftContributorEntry, memoryId }];
  }

  return contributors;
};

export default AddMemoryContributorForm;
