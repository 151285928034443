import { Box, css } from '@mui/material';

import { HasChildren } from '~/types/types';

const styles = css({
  display: 'flex',
  ['button']: {
    marginLeft: '0.5rem',
  },
  [`button:first-of-type`]: {
    marginLeft: 'auto',
  },
});

export const ButtonWrapper: React.FC<HasChildren> = (props) => {
  const { children } = props;
  return <Box css={styles}>{children}</Box>;
};

export default ButtonWrapper;
