import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoaderData } from 'react-router-typesafe';
import {
  AccountCircle,
  ChevronLeft,
  ChevronRight,
  Close,
  CloudUpload,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  StepLabelProps,
  Stepper,
  Typography,
} from '@mui/material';
import { api } from '~/api/index.ts';
import { useApi } from '~/api/index.ts';
import strings from '~/constants/strings.ts';
import WhoDoYouIdentify from '~/routes/onboarding/identify';
import UsingVTC from '~/routes/onboarding/using-vtc';
import { getCurrentUser, useGetCurrentUser } from '~/state/users';
import { UserPersona } from '~/types/persona.ts';
import DrawerContainer from '~/components/containers/drawer_container.tsx';

export const onboardingLoader = async () => {
  const currentUser = getCurrentUser();
  const { data: personas, error: personaError } = await api.auth.getPersonas();
  if (personaError) {
    return {
      personas: [],
      userPersonas: [],
      contributions: [],
      error: personaError,
    };
  }

  const { data: userPersonas, error: userPersonaError } =
    await api.auth.getUserPersonas(currentUser.authId);
  if (userPersonaError) {
    return {
      personas: [],
      userPersonas: [],
      contributions: [],
      error: userPersonaError,
    };
  }

  await api.contributions.getOrCreateContributorMemoryUserRelations(
    currentUser.email,
    currentUser.id,
  );

  const { data: contributions, error: contributionsErrors } =
    await api.contributions.getContributionRequests(currentUser.email);
  if (contributionsErrors) {
    return {
      personas: [],
      userPersonas: [],
      contributions: [],
      error: contributionsErrors,
    };
  }

  return {
    personas,
    selectedPersonas: userPersonas,
    contributions,
    error: null,
  };
};

const StepperBox: React.FC<StepLabelProps & { active?: boolean }> = (props) => {
  return (
    <>
      <Box
        width={30}
        height={10}
        borderRadius={'5px'}
        bgcolor={props.active ? '#112D59' : '#D9D9D9'}
      ></Box>
    </>
  );
};

const Onboarding = () => {
  const data = useLoaderData<typeof onboardingLoader>();
  const api = useApi();
  const navigate = useNavigate();
  const currentUser = useGetCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialStep = searchParams.get('step');
  const getInitialStep = () => {
    switch (initialStep) {
      case 'profile':
        return 0;
      case 'identify':
        return 1;
      case 'using-vtc':
        return 2;
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (!initialStep) {
      setSearchParams({ step: 'profile' });
    }
  }, []);

  const [step, setStep] = useState(getInitialStep());
  const [ageRange, setAgeRange] = useState('young-adult');
  const [howDidYouHear, setHowDidYouHear] = useState('');
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [selectedPersonas, setSelectedPersonas] = useState<UserPersona[]>(
    data.selectedPersonas || [],
  );
  const [selectedUsages, setSelectedUsages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateUserProfile = () => {
    try {
      const response = api.auth.updateUserInfo(currentUser, {
        ageRange: ageRange,
        referralSource: howDidYouHear,
        avatarPath: profilePicture,
        onboardingComplete: true,
      });

      return response;
    } catch (error) {
      toast.error(strings.profile.EDIT_PROFILE_FAILED);
      return;
    }
  };

  const updateUserPersonas = async () => {
    const { error } = await api.auth.replaceUserPersonas(currentUser.authId, selectedPersonas);
    if (error) {
      toast.error(strings.USER_PERSONAS_UPDATE_FAILED);
    }
  };

  const handleAgeRangeChange = (e: SelectChangeEvent<string>) => {
    setAgeRange(e.target.value);
  };

  const handleHowDidYouHearChange = (e: SelectChangeEvent<string>) => {
    setHowDidYouHear(e.target.value);
  };

  const handleProfilePictureChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleOnboardingSubmit = async () => {
    setIsLoading(true);
    await updateUserProfile();
    await updateUserPersonas();
    setTimeout(() => {
      setIsLoading(false);
      if (data.contributions && data.contributions.length > 0) {
        navigate('/?tab=contributions');
      } else {
        navigate('/');
      }
    }, 1000);
  };

  const handleNext = () => {
    const nextStep = step + 1;
    if (nextStep > 2) {
      handleOnboardingSubmit();
      return;
    }
    setStep(nextStep);
    const stepParam =
      nextStep === 0 ? 'profile' : nextStep === 1 ? 'identify' : 'using-vtc';
    setSearchParams({ step: stepParam });
  };

  const handlePrevious = () => {
    const prevStep = step - 1;
    setStep(prevStep);
    const stepParam =
      prevStep === 0 ? 'profile' : prevStep === 1 ? 'identify' : 'using-vtc';
    setSearchParams({ step: stepParam });
  };

  const handleUsageSelection = (usage: string) => {
    setSelectedUsages((prev) => {
      if (prev.includes(usage)) {
        return prev.filter((item) => item !== usage);
      } else if (prev.length < 3) {
        return [...prev, usage];
      }
      return prev;
    });
  };

  const skipOnboardingStep = () => {
    handleNext();
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography variant="h5">
              <b>Complete Profile</b>
            </Typography>
            <Grid container spacing={2} mb={8} mt={2}>
              <Grid
                item
                xs={6}
                sm={6}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
              >
                <Box position="relative" display="inline-flex">
                  <Avatar
                    src={
                      profilePicture
                        ? URL.createObjectURL(profilePicture)
                        : undefined
                    }
                    alt="Profile Picture Preview"
                    sx={{ width: 45, height: 45 }}
                  >
                    {!profilePicture &&
                      `${currentUser.firstName?.[0] || ''}${currentUser.lastName?.[0] || ''}`}
                  </Avatar>
                  {profilePicture && (
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -15,
                        backgroundColor: 'background.paper',
                        '&:hover': { backgroundColor: 'action.hover' },
                      }}
                      onClick={() => setProfilePicture(null)}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <Typography variant="body2" style={{ marginTop: '8px' }}>
                  <b>Profile Picture</b>
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  style={{ display: 'block', marginTop: '4px' }}
                >
                  PNG, JPEG Under 15MB
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="profile-picture-upload"
                  type="file"
                  onChange={handleProfilePictureChange}
                />
                <label htmlFor="profile-picture-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUpload />}
                  >
                    Upload
                  </Button>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    style={{ display: 'block', marginTop: '4px' }}
                  >
                    Skip and Add Later
                  </Typography>
                </label>
              </Grid>
            </Grid>

            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Icon>
                <AccountCircle />
              </Icon>
              <Typography variant="h5" ml={1}>
                <b>Personal Information</b>
              </Typography>
            </Box>
            <FormControl fullWidth margin="normal">
              <FormLabel>Age Range (Optional)</FormLabel>
              <Select
                value={ageRange}
                onChange={handleAgeRangeChange}
                placeholder="Select from the drop-down"
                name="ageRange"
              >
                <MenuItem value="minor">Minor (0-17)</MenuItem>
                <MenuItem value="young-adult">Young Adult (18-25)</MenuItem>
                <MenuItem value="adult">Adult (26-64)</MenuItem>
                <MenuItem value="elderly">Elderly (65+)</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel>
                How did you hear about virtual time capsule?
              </FormLabel>
              <Select
                value={howDidYouHear}
                onChange={handleHowDidYouHearChange}
                placeholder="Select from the drop-down"
                name="referralSource"
              >
                <MenuItem value="">Select referral source</MenuItem>
                <MenuItem value="google">Google</MenuItem>
                <MenuItem value="linkedin">LinkedIn</MenuItem>
                <MenuItem value="facebook">Facebook</MenuItem>
                <MenuItem value="twitter">Twitter</MenuItem>
                <MenuItem value="friend">Friend or colleague</MenuItem>
                <MenuItem value="advertisement">Advertisement</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </>
        );
      case 1:
        return (
          <WhoDoYouIdentify
            personas={data.personas}
            selectedPersonas={selectedPersonas}
            setSelectedPersonas={setSelectedPersonas}
          />
        );
      case 2:
        return (
          <UsingVTC
            selectedUsages={selectedUsages}
            handleUsageSelection={handleUsageSelection}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DrawerContainer noLeftDrawer={true}>
      {step < 3 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
          mt={1}
          mb={4}
        >
          {step > 0 && step !== 3 && (
            <Button
              variant="outlined"
              startIcon={<ChevronLeft />}
              onClick={handlePrevious}
              sx={{ position: 'absolute', left: 0 }}
            ></Button>
          )}
          <Box
            sx={{ width: '100%' }}
            display={'flex'}
            justifyContent={'center'}
          >
            <Box>
              <Stepper activeStep={step} alternativeLabel connector={null}>
                <Step key="step1">
                  <StepLabel StepIconComponent={StepperBox}></StepLabel>
                </Step>
                <Step key="step2">
                  <StepLabel StepIconComponent={StepperBox}></StepLabel>
                </Step>
                <Step key="step3">
                  <StepLabel StepIconComponent={StepperBox}></StepLabel>
                </Step>
              </Stepper>
            </Box>
          </Box>
        </Box>
      )}
      {renderStep()}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: 'background.paper',
          display: 'flex',
          justifyContent: step === 0 ? 'flex-end' : 'space-between',
        }}
      >
        {step === 0 && (
          <Button
            variant="outlined"
            onClick={skipOnboardingStep}
            sx={{
              marginRight: 2,
            }}
          >
            Skip for Now
          </Button>
        )}

        <Button
          variant="contained"
          endIcon={!isLoading && <ChevronRight />}
          fullWidth={true}
          onClick={step === 2 ? handleOnboardingSubmit : handleNext}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : step === 2 ? (
            'Go To Home Experience'
          ) : (
            'Continue'
          )}
        </Button>
      </Box>
    </DrawerContainer>
  );
};

export default Onboarding;
