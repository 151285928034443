import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRouteLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useApi } from '~/api/index.ts';
import { PageHeader } from '~/components/capsules';
import { ContactList } from '~/components/capsules/contact_list.tsx';
import ErrorView from '~/components/error_view.tsx';
import strings from '~/constants/strings.ts';
import { CapsuleLoaderData } from '~/routes/capsule/index.tsx';
import { Capsule } from '~/types/capsule.ts';

const ScheduleSend = ({ capsule }: { capsule: Capsule }) => {
  const api = useApi();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  useEffect(() => {
    if (capsule) {
      if (capsule.sendDate) {
        setSelectedDate(dayjs(capsule.sendDate));
      }
    }
  }, [capsule]);

  const updateSendDateAndRedirect = useCallback(
    async (capsuleObj: Capsule) => {
      try {
        if (!capsuleObj) {
          toast.error(`${strings.scheduleReview.UPDATE_CAPSULE_ERROR}`);
          return;
        }
        if (!selectedDate) {
          toast.error(`${strings.scheduleReview.SELECT_DATE_ERROR}`);
          return;
        }
        const error = await api.capsules.update(capsuleObj.id, {
          sendDate: selectedDate.toISOString(),
        });
        if (error) {
          console.error(
            `${strings.scheduleReview.UPDATE_CAPSULE_ERROR}`,
            error,
          );
          return;
        }
        navigate(`/capsule/${capsuleObj.id}/review/preview`);
      } catch (error) {
        console.log(`${strings.scheduleReview.UPDATE_CAPSULE_ERROR}`, error);
        return;
      }
    },
    [navigate, selectedDate, api.capsules],
  );
  return (
    <div css={styles.container}>
      <PageHeader
        title={strings.scheduleReview.TITLE}
        onBack={() => navigate(-1)}
      />
      <div css={styles.content}>
        <Typography variant="subtitle1" css={styles.headerTitle}>
          {strings.scheduleReview.PAGE_ACTION}
        </Typography>
        <Typography variant="h5" css={styles.headerTitle}>
          {strings.scheduleReview.RECIPIENTS_LABEL}
        </Typography>
        <ContactList userContacts={capsule.recipients} />
        <Typography variant="h5" css={styles.headerTitle}>
          {strings.scheduleReview.SCHEDULE_DATE}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={strings.scheduleReview.SELECT_DATE}
            value={selectedDate}
            onChange={setSelectedDate}
            css={styles.datepicker}
            disablePast
          />
        </LocalizationProvider>
      </div>
      <div css={styles.inputContainer}>
        <Button
          variant="contained"
          color="primary"
          css={styles.button}
          onClick={() => navigate(-1)}
        >
          {strings.scheduleReview.BACK_BTN}
        </Button>
        <Button
          variant="contained"
          color="primary"
          css={styles.button}
          onClick={() => updateSendDateAndRedirect(capsule)}
        >
          {strings.scheduleReview.SUBMIT_BTN}
        </Button>
      </div>
    </div>
  );
};

const CapsuleReviewScheduleSend = () => {
  const data = useRouteLoaderData<CapsuleLoaderData>('capsuleRoot');

  if (!data.capsule || data.error) {
    return <ErrorView error={data.error} />;
  }

  return <ScheduleSend capsule={data.capsule} />;
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100vh',
  }),
  header: css({
    padding: '16px',
  }),
  button: css({
    marginLeft: '10px',
  }),
  backButton: css({
    marginLeft: '10px',
  }),
  headerTitle: css({
    fontWeight: '500',
  }),
  headerContent: css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  }),
  content: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '16px',
    padding: '30px',
    overflowY: 'auto' as const,
  }),
  datepicker: css({
    '& .MuiInputAdornment-root': {
      paddingRight: '15px',
    },
  }),
  inputContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  }),
};

export default CapsuleReviewScheduleSend;
