import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRouteLoaderData } from 'react-router-typesafe';
import { Close } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { useApi } from '~/api/index.ts';
import PlusIcon from '~/assets/images/plus.png';
import TileIcon from '~/assets/images/tile.png';
import Hourglass from '~/assets/images/time-hourglass.png';
import { SimpleHeaderContainer } from '~/components/containers/header_container.tsx';
import ErrorView from '~/components/error_view.tsx';
import strings from '~/constants/strings.ts';
import { DraftCapsuleLoaderData } from '~/routes/capsule/index.tsx';
import { DraftCapsule } from '~/types/capsule.ts';

const Banner = styled('img')({
  width: '100%',
  maxWidth: '250px',
  height: 'auto',
  objectFit: 'cover',
});

const SelectionBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface SelectionOptionProps {
  icon: string;
  title: string;
  description: string;
  onClick: () => void;
}

const SelectionOption = ({
  icon,
  title,
  description,
  onClick,
}: SelectionOptionProps) => (
  <Grid item xs={12} md={6}>
    <SelectionBox onClick={onClick}>
      <Grid container spacing={2}>
        <Grid item mt={1}>
          <img src={icon} alt={`${title} Icon`} />
        </Grid>
        <Grid item xs>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </Grid>
      </Grid>
    </SelectionBox>
  </Grid>
);

const LoadedCapsuleTemplateView = ({ capsule }: { capsule: DraftCapsule }) => {
  const api = useApi();
  const navigate = useNavigate();

  const onNavigateBack = useCallback(() => navigate(-1), [navigate]);
  const onSelectTemplate = useCallback(
    () => navigate(`../select-prompts`, { relative: 'route' }),
    [navigate],
  );

  const createBlankCapsule = useCallback(async () => {
    try {
      const { data: newCapsule, error } = await api.capsules.create(capsule);
      if (error || !newCapsule) {
        toast.error(`${strings.selectTemplate.CAPSULE_CREATE_ERROR}`);
        return;
      }
      toast.success(`${strings.selectTemplate.CAPSULE_CREATE_SUCCESS}`);
      navigate(`../`, { relative: 'route' });
    } catch (error) {
      console.error(`${strings.selectTemplate.CAPSULE_CREATE_ERROR}`, error);
      toast.error(`${strings.selectTemplate.CAPSULE_CREATE_ERROR}`);
    }
  }, [api.capsules, capsule, navigate]);

  return (
    <SimpleHeaderContainer
      headerTitle={
        <Typography variant="h6" noWrap component="div" color="textPrimary">
          {strings.CREATE_CAPSULE}
        </Typography>
      }
      headerRight={
        <IconButton aria-label="navigate back" onClick={onNavigateBack}>
          <Close />
        </IconButton>
      }
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent={'center'}
      >
        <Banner src={Hourglass} alt="Capsule Creation Banner" />
      </Box>
      <Box sx={{ mt: 2, mb: 4 }}>
        <Typography variant="h5" fontWeight="900" gutterBottom>
          {strings.selectTemplate.TITLE}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {strings.selectTemplate.PAGE_ACTION}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <SelectionOption
          icon={TileIcon}
          title={strings.selectTemplate.START_TEMPLATE_CAPSULE_LABEL}
          description={
            strings.selectTemplate.START_TEMPLATE_CAPSULE_DESCRIPTION
          }
          onClick={onSelectTemplate}
        />
        <SelectionOption
          icon={PlusIcon}
          title={strings.selectTemplate.START_BLANK_CAPSULE_LABEL}
          description={strings.selectTemplate.START_BLANK_CAPSULE_DESCRIPTION}
          onClick={createBlankCapsule}
        />
      </Grid>
    </SimpleHeaderContainer>
  );
};

const NewCapsuleSelectTemplate = () => {
  const data = useRouteLoaderData<DraftCapsuleLoaderData>(
    'capsuleSelectTemplate',
  );

  if (!data.capsule || data.error) {
    return <ErrorView error={data.error} />;
  }

  return <LoadedCapsuleTemplateView capsule={data.capsule} />;
};

export default NewCapsuleSelectTemplate;
