import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Close, PlayCircle, StopCircle } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useWavesurfer } from '@wavesurfer/react';

import { Upload } from '~/types/upload.ts';

interface AudioMiniPlayerProps {
  audio: Upload;
  removeUpload: (id: string) => void;
}

const RemoveConfirmationModal = ({
  open,
  handleClose,
  onAccept,
}: {
  open: boolean;
  handleClose: () => void;
  onAccept: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to remove this audio recording?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onAccept}>Remove</Button>
      </DialogActions>
    </Dialog>
  );
};

const AudioMiniPlayer = ({ audio, removeUpload }: AudioMiniPlayerProps) => {
  const containerRef = useRef(null);
  const [url, setUrl] = useState('');
  const [openRemoveConfirmationModal, setOpenRemoveConfirmationModal] =
    useState(false);

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: containerRef,
    waveColor: '#959595',
    height: 28,
    url,
  });

  const onRemoveConfirmation = useCallback(() => {
    setOpenRemoveConfirmationModal(true);
  }, [setOpenRemoveConfirmationModal]);

  const closeRemoveConfirmationModal = useCallback(() => {
    setOpenRemoveConfirmationModal(false);
  }, [setOpenRemoveConfirmationModal]);

  const onRemove = useCallback(() => {
    removeUpload(audio.id);
  }, [audio, removeUpload]);

  useEffect(() => {
    if (audio.metadata.asset) {
      const audioUrl = URL.createObjectURL(audio.metadata.asset);
      setUrl(audioUrl);

      return () => {
        URL.revokeObjectURL(audioUrl);
      };
    }
  }, [audio]);

  const togglePlayback = () => {
    wavesurfer && wavesurfer.playPause();
  };

  return (
    <>
      <RemoveConfirmationModal
        open={openRemoveConfirmationModal}
        handleClose={closeRemoveConfirmationModal}
        onAccept={onRemove}
      />
      <div css={styles.input}>
        {isPlaying ? (
          <IconButton onClick={togglePlayback}>
            <StopCircle />
          </IconButton>
        ) : (
          <IconButton onClick={togglePlayback}>
            <PlayCircle />
          </IconButton>
        )}

        <div ref={containerRef} css={styles.audioVisualizer} />

        <IconButton css={styles.clearButton} onClick={onRemoveConfirmation}>
          <Close htmlColor={'white'} sx={{ fontSize: 15 }} />
        </IconButton>
      </div>
    </>
  );
};

const styles = {
  input: css({
    position: 'relative',
    display: 'flex',
    flex: 1,
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '20px',
    marginRight: '10px',
    backgroundColor: '#E6E6E6',
    color: '#000',
    resize: 'none' as const,
    maxHeight: '120px',
    outline: 'none',
    border: '1px solid #959595',
    fontFamily: 'inherit',
  }),
  audioVisualizer: css({
    width: '100%',
    marginRight: '30px',
    marginTop: '6px',
  }),
  clearButton: css({
    position: 'absolute',
    right: '-2px',
    top: '-2px',
    backgroundColor: 'red',
    width: '18px',
    height: '18px',
  }),
};

export default AudioMiniPlayer;
