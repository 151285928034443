import { useMemo } from 'react';
import { css } from '@emotion/react';
import ImageIcon from '@mui/icons-material/Image';
import Text from '~/components/text.tsx';
import strings from '~/constants/strings.ts';
import colors from '~/styles/colors.ts';
import { Attachment } from '~/types/attachment.ts';
import { Capsule } from '~/types/capsule.ts';
import { getDateFormatted } from '~/utils/date.ts';
import { pluralize } from '~/utils/strings.ts';

interface CapsuleItemProps {
  capsule: Capsule;
}

const CapsuleItem = ({ capsule }: CapsuleItemProps) => {
  const firstAttachment: Attachment | undefined = useMemo(() => {
    let _firstAttachment: Attachment | undefined;
    capsule.memories?.some((memory) =>
      memory.content.find((content) => {
        if (content?.attachments && content.attachments.length > 0) {
          _firstAttachment = content.attachments?.find((a) => !!a.thumbnailUrl);
        }

        if (_firstAttachment) {
          return true;
        }
      }),
    );

    return _firstAttachment;
  }, [capsule.memories]);
  const fontColor = colors.WHITE;

  return (
    <a key={capsule.id} css={styles.container} href={`/capsule/${capsule.id}`}>
      <div css={styles.content}>
        <Text title lineHeight={1.4} color={fontColor}>
          {capsule.title || 'Untitled Capsule...'}
        </Text>
        {capsule.sendDate && (
          <div css={styles.sendDescription}>
            <Text size={12} color={fontColor}>
              {`${strings.SCHEDULED_FOR} `}
              {getDateFormatted(
                capsule.sendDate,
                'Today | Monday, Jan 1 2024?',
              )}
            </Text>
          </div>
        )}
        {(capsule.videoCount > 0 || capsule.photoCount > 0) && (
          <div css={styles.mediaDescription}>
            <ImageIcon
              sx={{
                color: fontColor,
                fontSize: 12,
              }}
            />
            <Text color={fontColor} size={12}>
              {capsule.videoCount > 0
                ? `${pluralize(capsule.videoCount, strings.VIDEO)}`
                : ''}
              {capsule.videoCount > 0 && capsule.photoCount > 0 ? ', ' : ''}
              {capsule.photoCount > 0
                ? `${pluralize(capsule.photoCount, strings.PHOTO)}`
                : ''}
            </Text>
          </div>
        )}
      </div>

      <div css={styles.gradient} />
      {firstAttachment && (
        <div css={styles.imageContainer}>
          <img
            css={styles.image}
            src={firstAttachment.thumbnailUrl}
            alt="thumbnail"
          />
        </div>
      )}

      <div css={styles.statusBadge}>
        <Text color={fontColor} bold size={12}>
          {capsule.sendStatus}
        </Text>
      </div>
    </a>
  );
};

const styles = {
  container: css({
    position: 'relative',
    overflow: 'hidden',
    minHeight: 206,
    borderRadius: '16px',
    backgroundColor: colors.BACKGROUND_1,
  }),
  imageContainer: css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    zIndex: 1,
  }),
  gradient: css({
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 77%, rgba(0, 0, 0, .65) 100%)',
    zIndex: 2,
  }),
  image: css({
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  }),
  content: css({
    position: 'absolute',
    padding: '16px',
    zIndex: 3,
    bottom: 0,
  }),
  sendDescription: css({
    marginBottom: 3,
  }),
  mediaDescription: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  }),
  statusBadge: css({
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 3,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 16,
    backgroundColor: colors.STATUS_BADGE,
    textTransform: 'capitalize',
  }),
};

export default CapsuleItem;
