type DateFormat =
  | 'Today | Mon, Jan 1 2024? at 7pm'
  | 'Today | Monday, January 1 2024?'
  | 'Today | Monday, Jan 1 2024?'
  | 'Jan 1'
  | 'January 1'
  | 'January 1, 8PM';

export const DAY_IN_MS = 1000 * 60 * 60 * 24;
export const YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365;
export const HOUR_IN_MS = 1000 * 60 * 60;
export const HOURS_IN_SEC = 60 * 60;
export const MINUTE_IN_MS = 1000 * 60;
export const SECOND_IN_MS = 1000;

const getRelativeDateFormatted = (
  _date: number | string,
  options: Intl.DateTimeFormatOptions,
  yearOptional?: boolean,
): string => {
  const date = new Date(_date);
  const formattedDate = date.toLocaleDateString('en-US', options);

  const today = new Date();
  const yesterday = new Date(Date.now() - DAY_IN_MS);
  const tomorrow = new Date(Date.now() + DAY_IN_MS);

  if (isSameDay(date, today)) {
    if (formattedDate.includes('at')) {
      return 'Today at ' + formattedDate.split('at')[1].trim();
    }
    return 'Today';
  }

  if (isSameDay(date, yesterday)) {
    if (formattedDate.includes('at')) {
      return 'Yesterday at ' + formattedDate.split('at')[1].trim();
    }
    return 'Yesterday';
  }

  if (isSameDay(date, tomorrow)) {
    if (formattedDate.includes('at')) {
      return 'Tomorrow at ' + formattedDate.split('at')[1].trim();
    }
    return 'Tomorrow';
  }

  if (yearOptional && isSameYear(date, today)) {
    const currentYear = today.getFullYear();
    return formattedDate.replace(`, ${currentYear}`, '');
  }

  return formattedDate;
};

export const getDateFormatted = (
  date: number | string,
  format: DateFormat,
): string => {
  let options: Intl.DateTimeFormatOptions;

  switch (format) {
    case 'Jan 1':
      // Return Short Month Day. example: Jan 1
      options = {
        month: 'short',
        day: 'numeric',
      };
      break;
    case 'January 1':
      // Return Full Month Day. example: January 1
      options = {
        month: 'long',
        day: 'numeric',
      };
      break;
    case 'January 1, 8PM': {
      // Return Full Month Day, Hour. example: January 1, 8PM
      options = {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        hour12: true,
      };
      const formattedDate = new Date(date).toLocaleDateString('en-US', options);
      return formattedDate.replace(' at ', ', ');
    }
    case 'Today | Mon, Jan 1 2024? at 7pm': {
      // Return Day of the week, Short Month Day or Today, Yesterday
      // Year is optional if it's the current year
      options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        hour12: true,
      };

      return getRelativeDateFormatted(date, options, true)
        .replace('AM', 'am')
        .replace('PM', 'pm');
    }
    case 'Today | Monday, January 1 2024?': {
      // Return Day of the week, Full Month Day or Today, Yesterday
      // Year is optional if it's the current year
      options = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };

      return getRelativeDateFormatted(date, options, true);
    }
    case 'Today | Monday, Jan 1 2024?': {
      // Return Day of the week, Short Month Day or Today, Yesterday
      // Year is optional if it's the current year
      options = {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      };

      return getRelativeDateFormatted(date, options, true);
    }
  }

  return new Date(date).toLocaleDateString('en-US', options);
};

export const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const isSameYear = (date1: Date, date2: Date): boolean => {
  return date1.getFullYear() === date2.getFullYear();
};
