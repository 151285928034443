import { css } from '@emotion/react';
import { ArrowBack } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

export const PageHeader = ({
  title,
  onBack,
}: {
  title: string;
  onBack: () => void;
}) => (
  <div css={styles.header}>
    <div css={styles.headerContent}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="back"
        onClick={onBack}
        css={styles.backButton}
      >
        <ArrowBack />
      </IconButton>
      <Typography variant="h5" color="black" css={styles.headerTitle}>
        {title}
      </Typography>
    </div>
  </div>
);

const styles = {
  header: css({
    padding: '16px',
  }),
  button: css({
    marginLeft: '10px',
  }),
  backButton: css({
    marginLeft: '10px',
  }),
  headerTitle: css({
    fontWeight: '700',
  }),
  headerContent: css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  }),
};
