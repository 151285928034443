import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Chip,
} from '@mui/material';

import { DraftContributor } from '~/types/user.ts';

export const ContributorContactList = ({
  contributorList,
}: {
  contributorList: DraftContributor[];
}) => (
  <Box display="flex" flexDirection="column" alignItems="center" width="100%">
    {contributorList.length === 0 ? (
      <Typography variant="body1" color="grey" mt={5}>
        No contacts available
      </Typography>
    ) : (
      <List sx={{ width: '100%' }}>
        {contributorList.map((contact, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar>{contact?.user?.firstName?.[0]}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${contact?.user?.firstName} ${contact?.user?.lastName || ''}`}
              secondary={contact?.user?.email}
            />
            {contact?.type === 'existing' && contact?.invite && (
              <Chip
                label={contact?.invite?.accepted ? 'Accepted' : 'Pending'}
                color={contact?.invite?.accepted ? 'success' : 'primary'}
                size="medium"
              />
            )}
          </ListItem>
        ))}
      </List>
    )}
  </Box>
);
