/**
 * Helper functions for the profile page
 */

import { getExtension } from '~/utils/file.ts';

const folderDelimiter = '/';
const extensionDelimiter = '.';

/**
 * Gets the path to save the new avatar to
 * @param authId auth ID of the user
 * @param mimeType type of image, used to pull extension
 * @param oldAvatarPath the path to the user's current avatar, if one exists
 * @returns path to save the avatar file, see constructAvatarPath for the format
 */
export function getNewAvatarPath(
  authId: string,
  mimeType: string,
  oldAvatarPath?: string | null,
): string {
  const avatarNumber = getAvatarNumber(oldAvatarPath);
  const extension = getExtension(mimeType);
  return constructAvatarPath(authId, avatarNumber, extension);
}

/**
 * Creates the avatar path for this avatar upload, using the authId as the folder,
 * the number of avatar uploads as the file name, and the extension of the image.
 *
 * It's done this way to ensure each upload has a unique path. If we instead updated the same path with a new image,
 * the browser would still pull from the image in cache, since the path hasn't changed.
 * This ensures the image shown will always be the current profile picture.
 *
 * @param authId the auth ID of this user
 * @param avatarNumber the number of total avatar uploads for this user
 * @param extension the extension of the image
 * @returns path of the avatar file, in the format <authId>/<avatarNumber>.<extension>
 */
function constructAvatarPath(
  authId: string,
  avatarNumber: number,
  extension: string,
): string {
  return (
    authId + folderDelimiter + avatarNumber + extensionDelimiter + extension
  );
}

/**
 * Get the number avatar this is. Avatar number starts at 1 and is incremented each time the user uploads a new avatar.
 * @param avatarPath the old path to the user's avatar, if one exists
 * @returns the new number to use for the path, or 1 if this is the first avatar for this user
 */
function getAvatarNumber(avatarPath?: string | null): number {
  if (avatarPath) {
    const oldNumber = +avatarPath
      .split(folderDelimiter)[1]
      .split(extensionDelimiter)[0];
    return oldNumber + 1;
  }

  return 1;
}
