import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { DraftPrompt } from '~/types/theme.ts';
const styles = {
  modalBox: css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    backgroundColor: '#FFFFFF',
    boxShadow: '24px',
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
};

interface MemoryPopupProps {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  callback: (memory: string) => void;
}

interface MemoryEditPopupProps {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  callback: (memory: any) => void;
  memoryToEdit: DraftPrompt | null;
}

const NewMemoryPopup: React.FC<MemoryPopupProps> = ({
  isOpen,
  onClose,
  callback,
}) => {
  const [memory, setMemory] = useState('');
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="new-memory-modal"
      aria-describedby="new-memory-modal-description"
    >
      <Box css={[styles.modalBox]}>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          New Memory
        </Typography>

        <TextField
          multiline
          rows={2}
          fullWidth
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setMemory(e.target.value)
          }
          placeholder="Enter your memory here..."
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          sx={{ width: '100%' }}
          onClick={() => callback(memory)}
        >
          Save New memory
        </Button>
      </Box>
    </Modal>
  );
};

export const EditMemoryPopup: React.FC<MemoryEditPopupProps> = ({
  isOpen,
  onClose,
  callback,
  memoryToEdit,
}) => {
  const [memory, setMemory] = useState<DraftPrompt>({
    type: 'new',
    prompt: { id: '', name: '' },
  });

  const handleClose = () => {
    onClose(false);
  };

  const handleSave = (
    e: React.MouseEvent<HTMLButtonElement>,
    memory: DraftPrompt,
  ) => {
    e.preventDefault();
    callback(memory);
  };

  const handleNameChange = (name: string) => {
    setMemory({ type: 'new', prompt: { ...memory.prompt, name: name } });
  };

  useEffect(() => {
    if (memoryToEdit) {
      setMemory(memoryToEdit);
    }
  }, [memoryToEdit]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="edit-memory-modal"
      aria-describedby="edit-memory-modal-description"
    >
      <Box css={[styles.modalBox]}>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          Edit Memory
        </Typography>

        <TextField
          multiline
          rows={2}
          fullWidth
          variant="outlined"
          value={memory.type === 'new' ? memory.prompt.name : ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleNameChange(e.target.value)
          }
          placeholder="Enter your memory here..."
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          sx={{ width: '100%' }}
          onClick={(e) => {
            handleSave(e, memory);
          }}
        >
          Save Edits
        </Button>
      </Box>
    </Modal>
  );
};

export default NewMemoryPopup;
