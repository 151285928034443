import { Toaster } from 'react-hot-toast';
import { Session } from '@supabase/supabase-js';

import { toastDefaultPosition, toastOptions } from '~/constants/toaster.ts';
import Root from '~/routes/root';

import AppProviders from './AppProviders';

interface AppProps {
  session: Session | null;
}

function App({ session }: AppProps) {
  return (
    <AppProviders session={session}>
      <Toaster toastOptions={toastOptions} position={toastDefaultPosition} />
      <Root />
    </AppProviders>
  );
}

export default App;
