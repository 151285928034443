import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { AccountCircle, ExitToApp, Home } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { SwipeableDrawer } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiToolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useAuth } from '~/api/hooks/useAuth.tsx';
import Toolbar from '~/components/toolbar.tsx';
import colors from '~/styles/colors.ts';

export const drawerWidth = 240;

const styles = {
  flex: css({
    display: 'flex',
  }),
  appContainer: css({
    marginBottom: '30px',
    overflow: 'auto',
  }),
  appBar: css({
    backgroundColor: colors.DARK_BLUE,
  }),
  logo: css({
    width: '38px',
    height: '38px',
    marginRight: '16px',
  }),
  menuItemActive: css({
    fontWeight: 'bold',
  }),
  menuIconButton: css({
    marginRight: '16px',
  }),
};

interface MenuItemProps {
  text: string;
  icon: React.ReactNode;
  to?: string;
  onPress?: () => void;
}

const MenuItem = ({ text, icon, to, onPress }: MenuItemProps) => {
  const location = useLocation();
  const active = location.pathname === to;

  return (
    <ListItem disablePadding>
      {/* NOTE: unnecessary ts involved to play nicely with NavLink, considering to is optional */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ListItemButton component={NavLink} to={to} onClick={onPress}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography css={active ? styles.menuItemActive : undefined}>
              {text}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default function DrawerContainer({
  children,
  noLeftDrawer,
}: {
  children: React.ReactNode;
  noLeftDrawer?: boolean;
}) {
  const { logout } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawerMenuItems = (
    <div>
      <MuiToolbar />
      <List>
        <MenuItem text="Home" icon={<Home />} to="/" />
        <MenuItem text="Account" icon={<AccountCircle />} to="/profile" />
      </List>
      <Divider />
      <List>
        <MenuItem text="Logout" icon={<ExitToApp />} onPress={logout} />
      </List>
    </div>
  );

  return (
    <Box css={[styles.flex, styles.appContainer]}>
      <CssBaseline />
      <Toolbar
        left={
          noLeftDrawer ? null : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
            >
              <MenuIcon />
            </IconButton>
          )
        }
        setToolbarSpacing={false}
      />
      {!noLeftDrawer && (
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <SwipeableDrawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onOpen={handleDrawerToggle}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            {drawerMenuItems}
          </SwipeableDrawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            {drawerMenuItems}
          </Drawer>
        </Box>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginBottom: '64px',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <MuiToolbar />
        {children}
      </Box>
    </Box>
  );
}
