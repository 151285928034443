import CapsuleItem from '~/components/capsule_item/index.tsx';
import HomeGridView from '~/components/home/grid_view.tsx';
import Text from '~/components/text.tsx';
import { Capsule } from '~/types/capsule.ts';

interface ReceivedCapsulesTabProps {
  capsules: Capsule[];
}

const ReceivedCapsulesTab = ({ capsules }: ReceivedCapsulesTabProps) => {
  return (
    <>
      <Text title>Received Capsules</Text>

      {capsules.length === 0 ? (
        <Text>No capsules have been received yet....</Text>
      ) : (
        <HomeGridView>
          {capsules.map((capsule) => (
            <CapsuleItem key={capsule.id} capsule={capsule} />
          ))}
        </HomeGridView>
      )}
    </>
  );
};

export default ReceivedCapsulesTab;
