// eslint-disable-next-line
// @ts-nocheck

import React from 'react';
import { styled } from '@mui/material';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import Countdown from '~/components/video_recorder/countdown';
import RecordButton from '~/components/video_recorder/record-button';
import StopButton from '~/components/video_recorder/stop-button';
import Timer from '~/components/video_recorder/timer';

const ActionsWrapper = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: '5%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const VideoRecorderActions = ({
  t,
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  useVideoInput,

  onTurnOnCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onStopReplaying,
  onConfirm,
}) => {
  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <div style={{ display: 'flex', gap: 8 }}>
          <Button
            id="use-other-video"
            variant="contained"
            color="primary"
            onClick={onStopReplaying}
            data-qa="start-replaying"
          >
            {t('Use another video')}
          </Button>
          <Button
            id="continue"
            variant="contained"
            color="primary"
            onClick={onConfirm}
            data-qa="continue"
          >
            {t('Continue')}
          </Button>
        </div>
      );
    }

    if (isRecording) {
      return (
        <StopButton
          type="button"
          onClick={onStopRecording}
          data-qa="stop-recording"
        />
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <RecordButton
          t={t}
          type="button"
          onClick={onStartRecording}
          data-qa="start-recording"
        />
      );
    }

    if (useVideoInput) {
      return (
        <Button type="button" onClick={onOpenVideoInput} data-qa="open-input">
          {t('Upload A Video')}
        </Button>
      );
    }

    return shouldUseVideoInput ? (
      <Button
        id="use-other-video"
        variant="contained"
        color="primary"
        onClick={onOpenVideoInput}
        data-qa="open-input"
      >
        {t('Record A Video')}
      </Button>
    ) : (
      <Button
        id="use-other-video"
        variant="contained"
        color="primary"
        onClick={onTurnOnCamera}
        data-qa="turn-on-camera"
      >
        {t('Turn On Camera')}
      </Button>
    );
  };

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </div>
  );
};

VideoRecorderActions.propTypes = {
  t: PropTypes.func,
  isVideoInputSupported: PropTypes.bool,
  isInlineRecordingSupported: PropTypes.bool,
  thereWasAnError: PropTypes.bool,
  isRecording: PropTypes.bool,
  isCameraOn: PropTypes.bool,
  streamIsReady: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isRunningCountdown: PropTypes.bool,
  countdownTime: PropTypes.number,
  timeLimit: PropTypes.number,
  showReplayControls: PropTypes.bool,
  replayVideoAutoplayAndLoopOff: PropTypes.bool,
  isReplayingVideo: PropTypes.bool,
  useVideoInput: PropTypes.bool,

  onTurnOnCamera: PropTypes.func,
  onTurnOffCamera: PropTypes.func,
  onOpenVideoInput: PropTypes.func,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
  onPauseRecording: PropTypes.func,
  onResumeRecording: PropTypes.func,
  onStopReplaying: PropTypes.func,
  onConfirm: PropTypes.func,
  prompt: PropTypes.object,
};

export default VideoRecorderActions;
