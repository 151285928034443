import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Packages } from '~/types/billing';
import { PackageOverview } from '~/types/package';

interface PackageState {
  packages: Record<string, PackageOverview>;
  activeMembershipPackageId?: string;
  selectedPackage?: Packages | null;
}

interface Actions {
  setPackage: (packages: PackageOverview) => void;
  setActiveMembershipPackageId: (id?: string) => void;
  setSelectedPackage: (pkg?: Packages) => void;
}

export const usePackageStore = create<PackageState & Actions>()(
  persist(
    (set) => ({
      packages: {},
      selectedPackage: null,
      setPackage: (packages) =>
        set((state) => ({
          ...state,
          packages: {
            ...state.packages,
            [packages.package?.membershipPackageId || '']: packages,
          },
        })),
      setActiveMembershipPackageId: (id) =>
        set((state) => ({
          ...state,
          activeMembershipPackageId: id,
        })),
      setSelectedPackage: (pkg) =>
        set((state) => ({
          ...state,
          selectedPackage: pkg,
        })),
    }),
    {
      name: 'packages',
    },
  ),
);
