import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Params, useNavigate } from 'react-router-dom';
import { useLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { CheckCircle } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { api } from '~/api/index.ts';
import ErrorView from '~/components/error_view.tsx';
import strings from '~/constants/strings.ts';
import { capsuleLoader } from '~/routes/capsule/index.tsx';
import { Capsule } from '~/types/capsule.ts';

import PurchaseFlowLoadingScreen from './loading.tsx';

export const orderCompleteLoader = async ({
  params,
}: {
  params: Params<'capsuleId'>;
}) => {
  return await capsuleLoader({ params });
};

const OrderComplete = ({ capsule }: { capsule: Capsule }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [statusSuccess, setStatusSuccess] = useState<boolean | null>(null);
  const [statusLoading, setStatusLoading] = useState<boolean>(false);

  const updateCapsuleStatus = useCallback(async () => {
    setStatusLoading(true);

    try {
      const { error: capsuleError } = await api.capsules.schedule(capsule.id);
      if (capsuleError) {
        setStatusLoading(false);
        toast.error(strings.payments.CAPSULE_UPDATE_ERROR);
        return { error: capsuleError };
      }

      setStatusLoading(false);
      setStatusSuccess(true);
      toast.success(strings.payments.CAPSULE_UPDATE_SUCCESS);

      return { error: null };
    } catch (error) {
      console.error(strings.payments.CAPSULE_UPDATE_ERROR, error);
      setStatusLoading(false);
      return { error };
    }
  }, [capsule]);

  useEffect(() => {
    //Add fake delay to simulate loading.
    const delay = async () => {
      await new Promise((resolve) => setTimeout(resolve, 4000));
      setIsLoading(false);
      updateCapsuleStatus();
    };

    delay();
  }, []);

  if (isLoading) {
    return <PurchaseFlowLoadingScreen />;
  }

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <img
          src={`${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/public_assets/vtc-logo-icon.png`}
          alt="logo"
          css={styles.image}
        />
        <Typography variant="h5" css={styles.headerTitle} fontWeight="700">
          {strings.payments.PAYMENTS_SUCCESS_TITLE}
        </Typography>
        <Typography
          variant="subtitle1"
          css={styles.headerTitle}
          textAlign="center"
        >
          {strings.payments.PAYMENTS_SUCCESS_DESC}
        </Typography>

        <Player
          autoplay
          loop
          src="https://lottie.host/415e7242-2fae-4264-9347-b3c4ab78b713/GioSDR9FIR.json"
          style={{ height: '300px', width: 'auto', marginBottom: '20px' }}
        >
          <Controls
            visible={false}
            buttons={['play', 'repeat', 'frame', 'debug']}
          />
        </Player>

        {statusSuccess && (
          <Box
            css={css({
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              backgroundColor: '#e8f5e9',
              padding: '12px 24px',
              borderRadius: '8px',
              color: '#2e7d32',
            })}
          >
            <CheckCircle /> {strings.payments.CAPSULE_IS_LIVE}
          </Box>
        )}

        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate('/')}
          disabled={statusLoading}
        >
          {strings.payments.HOMEFEED_BTN}
        </Button>
      </div>
    </div>
  );
};

const OrderCompleteIndex = () => {
  const data = useLoaderData<typeof orderCompleteLoader>();
  if (!data.capsule || data.error) {
    return <ErrorView error={data.error || strings.CAPSULE_NOT_FOUND} />;
  }

  return <OrderComplete capsule={data.capsule} />;
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    color: 'white',
    backgroundColor: '#112D59',
    padding: '16px',
  }),
  header: css({
    padding: '16px',
  }),
  button: css({
    marginLeft: '10px',
  }),
  backButton: css({
    marginLeft: '10px',
  }),
  headerTitle: css({
    marginTop: '10px',
  }),
  headerContent: css({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column' as const,
    gap: '16px',
  }),
  image: css({
    width: '60px',
  }),
  content: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
  }),
  inputContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  }),
};

export default OrderCompleteIndex;
