import React from 'react';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import AttachmentService from '~/api/attachments.ts';
import AuthService from '~/api/auth.ts';
import CapsulesService from '~/api/capsules.ts';
import ContactsService from '~/api/contacts.ts';
import MemoryService from '~/api/memory.ts';
import MemoryContentService from '~/api/memory_content.ts';
import { SupabaseService } from '~/api/service.ts';
import StorageService from '~/api/storage.ts';
import ThemesService from '~/api/themes.ts';
import BillingService from '~/api/billing.ts';
import ContributionsService from '~/api/contributions.ts';
import { Database } from '~/types/database.ts';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const supabase = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'implicit',
  },
});

class Api extends SupabaseService {
  storage: StorageService;
  auth: AuthService;
  themes: ThemesService;
  capsules: CapsulesService;
  contacts: ContactsService;
  memories: MemoryService;
  contributions: ContributionsService;
  memoryContent: MemoryContentService;
  attachments: AttachmentService;
  billing: BillingService;

  constructor(_supabase: SupabaseClient) {
    super(_supabase);

    this.storage = new StorageService(_supabase);
    this.auth = new AuthService(_supabase, this.storage);
    this.billing = new BillingService(_supabase);
    this.themes = new ThemesService(_supabase);
    this.contacts = new ContactsService(_supabase);
    this.contributions = new ContributionsService(_supabase);
    this.capsules = new CapsulesService(_supabase, this.storage, this.contacts);
    this.memories = new MemoryService(_supabase);
    this.memoryContent = new MemoryContentService(_supabase);
    this.attachments = new AttachmentService(_supabase);
  }

  getClient() {
    return this.supabase;
  }
}

export const api = new Api(supabase);

export const ApiContext = React.createContext({ api });

export const useApi = () => {
  const { api } = React.useContext(ApiContext);
  return api;
};

supabase.auth.onAuthStateChange(async (event) => {
  if (event === 'INITIAL_SESSION') {
    // This sets the membershipId on the user's metadata on Supabase
    // membership is required for RLS policies
    await api.auth.initializeUserWithMembership();
  }
});
