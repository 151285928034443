import { styled } from '@mui/material/styles';

import logo from '~/assets/images/vtc-logo.png';

const classes = {
  logo: 'VideoRecorderDisconnectedView-logo',
};

const Root = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [`& .${classes.logo}`]: {
    height: '50%',
  },
}));

const VideoRecorderDisconnectedView = () => {
  return (
    <Root>
      <img
        className={classes.logo}
        alt="Virtual Time Capsule Logo"
        src={logo}
      />
    </Root>
  );
};

export default VideoRecorderDisconnectedView;
