import { useCallback, useMemo } from 'react';
import { useRevalidator } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import useFileUpload from '~/api/hooks/useFileUpload.ts';
import { useApi } from '~/api/index.ts';
import draft, { useDraftStore } from '~/state/draft.ts';
import { DraftMemoryContent, Memory } from '~/types/memory.ts';
import { PackageOverview } from '~/types/package.ts';
import {
  getDraftAttachmentsFromUpload,
  getDraftAudioFromUpload,
  getDraftThumbnailFromUpload,
  isAudioUpload,
  Upload,
} from '~/types/upload.ts';

export interface MemoryContentInputState {
  content: DraftMemoryContent | undefined;
  uploads: Upload[];
  audioRecording: Upload | undefined;
  upload: (files: (File | Blob)[]) => void;
  removeUpload: (id: string) => void;
  setText: (text: string) => void;
  submit: () => void;
  canSubmit: boolean;
}

const useMemoryContentInput = (
  memory: Memory,
  packageOverview: PackageOverview,
): MemoryContentInputState => {
  const { revalidate } = useRevalidator();
  const api = useApi();
  const {
    upload: fileUpload,
    uploads: fileUploads,
    removeUpload: removeFileUpload,
  } = useFileUpload('capsule_assets');

  const allUploads = useMemo(
    () => fileUploads.filter((upload) => upload.parentId === memory.capsuleId),
    [fileUploads, memory],
  );

  const contentUploads = useMemo(
    () =>
      fileUploads
        .filter((upload) => upload.parentId === memory.capsuleId)
        .filter((upload) => !isAudioUpload(upload)),
    [fileUploads, memory],
  );

  const audioRecordingUpload = useMemo(
    () =>
      fileUploads
        .filter((upload) => upload.parentId === memory.capsuleId)
        .find((upload) => isAudioUpload(upload)),
    [fileUploads, memory],
  );

  const activeDraftContentId = useDraftStore(
    (state) => state.memoryDraftContentIds[memory.id],
  );

  const contentDraft = useDraftStore((state) =>
    state.content.find((content) => content.id === activeDraftContentId),
  );

  const canSubmit = useMemo(() => {
    if (allUploads.length > 0) {
      const allUploadsSucceeded = allUploads.every(
        (u) => u.status === 'success',
      );
      if (!allUploadsSucceeded) {
        return false;
      }

      if (contentDraft?.content.trim().length === 0) {
        return true;
      }
    }

    return Boolean(
      contentDraft?.content && contentDraft.content.trim().length > 0,
    );
  }, [contentDraft, allUploads]);

  const setText = useCallback(
    (text: string) => {
      contentDraft &&
        draft.content.update({ id: contentDraft.id, content: text });
    },
    [contentDraft],
  );

  const upload = useCallback(
    (files: (File | Blob)[]) => {
      fileUpload(
        files.map((file) => ({
          id: uuidv4(),
          parentId: memory.capsuleId,
          asset: file,
        })),
      );
    },
    [memory, fileUpload],
  );

  const removeUpload = useCallback(
    (id: string) => {
      removeFileUpload(id);
    },
    [removeFileUpload],
  );

  const submit = useCallback(async () => {
    try {
      // TODO(miguel): Add validation, error handling and loading state
      if (!contentDraft) {
        return;
      }

      const { error } = await api.memoryContent.upsert(contentDraft);
      if (error) {
        // handle error
        console.error(error);
        return;
      }

      const draftAttachments = contentUploads.map((upload, index) =>
        getDraftAttachmentsFromUpload({
          capsuleId: memory.capsuleId,
          contentId: contentDraft.id,
          upload,
          order: index,
        }),
      );
      if (audioRecordingUpload) {
        draftAttachments.push(
          getDraftAudioFromUpload({
            capsuleId: memory.capsuleId,
            contentId: contentDraft.id,
            upload: audioRecordingUpload,
          }),
        );
      }
      const draftThumbnails = contentUploads.map((upload) =>
        getDraftThumbnailFromUpload({
          capsuleId: memory.capsuleId,
          contentId: contentDraft.id,
          upload,
        }),
      );

      const { error: createAttachmentsError } =
        await api.attachments.createMany([
          ...draftThumbnails,
          ...draftAttachments,
        ]);
      if (createAttachmentsError) {
        // handle error
        console.error(createAttachmentsError);
        return;
      }

      draft.resetActiveDraftContent(memory.id);
      contentUploads.forEach((upload) => removeUpload(upload.id));
      audioRecordingUpload && removeUpload(audioRecordingUpload.id);
      revalidate();

      if (
        packageOverview.package?.membershipPackageId &&
        contentUploads.length > 0
      ) {
        // On new file uploads submitted, reload package overview
        // to update the remaining storage statistics
        api.billing.reloadPackageOverview(
          packageOverview.package.membershipPackageId,
        );
      }
    } catch (error) {
      // handle error
      console.error('input.submit.error: ', error);
    }
  }, [
    api,
    memory,
    contentDraft,
    contentUploads,
    packageOverview,
    audioRecordingUpload,
    removeUpload,
    revalidate,
  ]);

  return {
    content: contentDraft,
    uploads: contentUploads,
    audioRecording: audioRecordingUpload,
    removeUpload,
    setText,
    upload,
    submit,
    canSubmit,
  };
};

export default useMemoryContentInput;
