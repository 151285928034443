import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRouteLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import { getDateFormatted } from '~/utils/date.ts';
import { CapsuleRecievedPreviewTemplate } from '~/components/capsules/capsule-recieved-preview.tsx';
import ErrorView from '~/components/error_view.tsx';
import strings from '~/constants/strings.ts';
import { CapsuleLoaderData } from '~/routes/capsule/index.tsx';
import { Capsule } from '~/types/capsule.ts';
import { PackageOverview } from '~/types/package.ts';
import { useGetCurrentUser } from '~/state/users';

const CapsulePreview = ({
  capsule,
  packageOverview,
}: {
  capsule: Capsule;
  packageOverview: PackageOverview;
}) => {
  const navigate = useNavigate();
  const currentUser = useGetCurrentUser();

  const canUseExistingPackage = useMemo(
    () =>
      packageOverview.package &&
      packageOverview.statistics.remainingCapsules > 0,
    [packageOverview],
  );

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <Typography variant="h5" css={styles.headerTitle}>
          {
            'Watch your capsule all the way through and let us know if you have edits:'
          }
        </Typography>

        <CapsuleRecievedPreviewTemplate
          recipientName={`${capsule && capsule.recipients.length > 0 ? `${capsule?.recipients[0].firstName}` : `Amy Lee`}`}
          senderName={`${capsule.sender.firstName || currentUser.firstName} ${capsule.sender.lastName || currentUser.lastName}`}
          senderAvatar={capsule.sender.avatarPath}
          sendDate={capsule.sendDate ? getDateFormatted(capsule.sendDate, 'Today | Monday, Jan 1 2024?') : ''}
          recipientEmail={''}
          message={`${capsule.message}`}
          thumbnailUrl={''}
          appUrl={''}
        />

        <Button
          variant="outlined"
          color="primary"
          css={styles.button}
          onClick={() => navigate(`/capsule/${capsule.id}`)}
        >
          Edit
        </Button>
      </div>
      <div css={styles.inputContainer}>
        <Button
          variant="contained"
          color="primary"
          css={styles.button}
          onClick={() => navigate(-1)}
        >
          {strings.scheduleReview.BACK_BTN}
        </Button>
        {canUseExistingPackage && (
          <Button
            variant="contained"
            color="primary"
            css={styles.button}
            onClick={() =>
              navigate(
                `/capsule/${capsule.id}/review/order-complete?success=true`,
              )
            }
          >
            Send capsule
          </Button>
        )}
        {!canUseExistingPackage && (
          <Button
            variant="contained"
            color="primary"
            css={styles.button}
            onClick={() =>
              navigate(`/capsule/${capsule.id}/review/confirm-plan`)
            }
          >
            Proceed to payment
          </Button>
        )}
      </div>
    </div>
  );
};

const CapsulePreviewIndex = () => {
  const data = useRouteLoaderData<CapsuleLoaderData>('capsuleRoot');

  if (!data.capsule || data.error) {
    return <ErrorView error={data.error} />;
  }

  return (
    <CapsulePreview
      capsule={data.capsule}
      packageOverview={data.packageOverview}
    />
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100vh',
  }),
  header: css({
    padding: '16px',
  }),
  button: css({
    marginLeft: '10px',
  }),
  backButton: css({
    marginLeft: '10px',
  }),
  headerTitle: css({
    fontWeight: '700',
    fontSize: '24px',
  }),
  headerContent: css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  }),
  content: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '16px',
    padding: '16px',
    overflowY: 'auto' as const,
  }),
  inputContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  }),
};

export default CapsulePreviewIndex;
