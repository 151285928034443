import { css } from '@emotion/react';

import Logo from '~/assets/images/vtc-logo.png';
import colors from '~/styles/colors.ts';

const ToolbarLogo = () => (
  <div css={styles.container}>
    <img src={Logo} alt="Virtual Time Capsule Logo" css={styles.logo} />
  </div>
);

const styles = {
  container: css({
    backgroundColor: colors.DARK_BLUE,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '20px',
    padding: '4px',
  }),
  logo: css({
    width: '24px',
    height: '24px',
  }),
};

export default ToolbarLogo;
