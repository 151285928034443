import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { ArrowBack, StopCircle } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';

import MediaCarousel from '~/components/media_carousel/media_carousel.tsx';
import MemoryItem from '~/components/memory/memory_item.tsx';
import { Memory } from '~/types/memory.ts';

const AudioRecordModal = ({
  memory,
  attachments,
  thumbnailUrls,
  open,
  handleClose,
  onComplete,
}: {
  memory: Memory;
  attachments: (Blob | File)[];
  thumbnailUrls: string[];
  open: boolean;
  handleClose: () => void;
  onComplete: (audioBlob: Blob) => void;
}) => {
  const [recording, setRecording] = useState(false);
  const closing = useRef(false);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const wavesurfer = useRef<WaveSurfer | null>();
  const recordPlugin = useRef<RecordPlugin | null>();

  const onClose = useCallback(() => {
    closing.current = true;
    if (wavesurfer.current) {
      wavesurfer.current.destroy();
      recordPlugin.current?.destroy();
      wavesurfer.current = null;
      recordPlugin.current = null;
    }

    handleClose();
  }, [handleClose]);

  const createWaveSurfer = useCallback(() => {
    if (!container) {
      return;
    }

    if (wavesurfer.current) {
      return;
    }

    // Create an instance of WaveSurfer
    wavesurfer.current = WaveSurfer.create({
      container: container,
      waveColor: '#f76565',
      height: 28,
    });

    // Initialize the Record plugin
    recordPlugin.current = wavesurfer.current.registerPlugin(
      RecordPlugin.create({
        scrollingWaveform: true,
        renderRecordedAudio: false,
        scrollingWaveformWindow: 30,
      }),
    );

    recordPlugin.current?.on('record-end', (blob) => {
      if (closing.current) {
        return;
      }
      onClose();
      onComplete(blob);
      setRecording(false);
    });
  }, [container, onComplete, onClose]);

  const startRecording = useCallback(() => {
    if (!wavesurfer.current) {
      createWaveSurfer();
    }

    setRecording(true);
    recordPlugin.current?.startRecording();
  }, [wavesurfer, recordPlugin, createWaveSurfer]);

  const stopRecording = useCallback(() => {
    if (recordPlugin.current && recordPlugin.current.isRecording()) {
      recordPlugin.current.stopRecording();
    }
  }, [recordPlugin]);

  useEffect(() => {
    if (!recording && open && container) {
      closing.current = false;
      startRecording();
    }
  }, [open, recording, container, startRecording]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      disableEscapeKeyDown={true}
      aria-labelledby="form-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'black',
        },
      }}
    >
      <DialogTitle id="form-dialog-title" component={'div' as const}>
        <IconButton onClick={onClose}>
          <ArrowBack htmlColor={'white'} />
        </IconButton>
      </DialogTitle>

      <MediaCarousel
        muted={true}
        header={<MemoryItem memory={memory} />}
        footer={
          <div>
            <div css={styles.input}>
              <div
                id="waveform"
                ref={(container) => {
                  setContainer(container);
                }}
                css={styles.audioVisualizer}
              />
              <IconButton onClick={stopRecording}>
                <StopCircle />
              </IconButton>
            </div>
          </div>
        }
        attachments={attachments}
        thumbnailUrls={thumbnailUrls}
      />
    </Dialog>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'auto',
    height: '100%',
    padding: '20px',
  }),
  input: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: '20px',
    borderRadius: '20px',
    backgroundColor: '#E6E6E6',
    alignSelf: 'flex-end',
    maxHeight: '40px',
    paddingLeft: '16px',
  }),
  audioVisualizer: css({
    width: '100%',
    height: '100%',
    marginTop: '6px',
  }),
};

export default AudioRecordModal;
