import { css } from '@emotion/react';
import { Typography, CircularProgress, Box } from '@mui/material';
import strings from '~/constants/strings.ts';

const PurchaseFlowLoadingScreen = () => {
  return (
    <Box css={styles.container}>
      <Box css={styles.content}>
        <Typography variant="h4" css={styles.headerTitle}>
          {strings.payments.PAYMENTS_LOADING}
        </Typography>
        <Box css={styles.progressContainer}>
          <CircularProgress
            size={120}
            thickness={2}
            css={css`
              position: absolute;
              z-index: 1;
            `}
          />
          <img
            src={`${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/public_assets/icons/vtc-hourglass.png`}
            alt="hourglass"
            css={css`
              width: 80px;
              height: 80px;
              position: relative;
              z-index: 2;
            `}
          />
        </Box>
      </Box>
    </Box>
  );
};
const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100vh',
  }),
  header: css({
    padding: '16px',
  }),
  button: css({
    marginLeft: '10px',
  }),
  backButton: css({
    marginLeft: '10px',
  }),
  headerTitle: css({
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: '20px',
  }),
  headerContent: css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  }),
  content: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '16px',
    padding: '16px',
    overflowY: 'auto' as const,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  }),
  inputContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  }),
  progressContainer: css({
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    alignItems: 'center',
    alignContent: 'center',
  }),
};

export default PurchaseFlowLoadingScreen;
