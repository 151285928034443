import { SupabaseService } from '~/api/service.ts';
import { DraftAttachment, DraftAttachmentBlob } from '~/types/attachment.ts';

export default class AttachmentService extends SupabaseService {
  create = async (draft: DraftAttachment) => {
    const { data, error } = await this.supabase
      .from('attachments')
      .insert({ ...draft });

    return { data, error };
  };

  createMany = async (drafts: DraftAttachment[]) => {
    const { data, error } = await this.supabase
      .from('attachments')
      .insert(drafts);

    return { data, error };
  };

  createBlob = async (draft: DraftAttachmentBlob) => {
    const { data, error } = await this.supabase
      .from('attachment_blobs')
      .insert({ ...draft });

    return { data, error };
  };
}
