import { css } from '@emotion/react';
import { Box } from '@mui/material';

import { HasChildren } from '~/types/types';

const styles = css({
  display: 'flex',
  padding: '0.5rem 0',
});

export const FormFieldWrapper: React.FC<HasChildren> = (props) => {
  const { children } = props;
  return <Box css={styles}>{children}</Box>;
};

export default FormFieldWrapper;
