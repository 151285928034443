import { SupabaseService } from '~/api/service.ts';
import { AppResponse } from '~/types/request.ts';
export default class ThemesService extends SupabaseService {
  //TODO: Need to reconfigure return type for nested personas_theme_categories and prompts - typescript throws an error when returning PersonaWithThemeCategories[]
  getPersonasAndThemeCategories = async (): AppResponse<any[]> => {
    const { data, error } = await this.supabase
      .from('personas')
      .select(
        '*, personasThemeCategories:personas_theme_categories(*, themeCategory:theme_categories!inner(*, prompts(*)))',
      );

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  };
}
