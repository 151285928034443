const PresentIcon = ({ color }: { color: string }) => (
  <div>
    <svg
      width="31"
      height="28"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5826 0.574273C9.06261 0.56094 6.60927 2.98761 7.72927 6.00094H3.5026C2.79536 6.00094 2.11708 6.28189 1.61699 6.78199C1.11689 7.28208 0.835938 7.96036 0.835938 8.66761V11.3343C0.835938 11.6879 0.976413 12.027 1.22646 12.2771C1.47651 12.5271 1.81565 12.6676 2.16927 12.6676H14.1693V8.66761H16.8359V12.6676H28.8359C29.1896 12.6676 29.5287 12.5271 29.7788 12.2771C30.0288 12.027 30.1693 11.6879 30.1693 11.3343V8.66761C30.1693 7.96036 29.8883 7.28208 29.3882 6.78199C28.8881 6.28189 28.2099 6.00094 27.5026 6.00094H23.2759C24.8359 1.64094 18.9693 -1.43906 16.2626 2.32094L15.5026 3.33427L14.7426 2.29427C13.9026 1.10761 12.7426 0.587607 11.5826 0.574273ZM11.5026 3.33427C12.6893 3.33427 13.2893 4.77427 12.4493 5.61427C11.6093 6.45427 10.1693 5.85427 10.1693 4.66761C10.1693 4.31398 10.3097 3.97485 10.5598 3.7248C10.8098 3.47475 11.149 3.33427 11.5026 3.33427ZM19.5026 3.33427C20.6893 3.33427 21.2893 4.77427 20.4493 5.61427C19.6093 6.45427 18.1693 5.85427 18.1693 4.66761C18.1693 4.31398 18.3097 3.97485 18.5598 3.7248C18.8098 3.47475 19.149 3.33427 19.5026 3.33427ZM2.16927 14.0009V24.6676C2.16927 25.3748 2.45022 26.0531 2.95032 26.5532C3.45042 27.0533 4.12869 27.3343 4.83594 27.3343H26.1693C26.8765 27.3343 27.5548 27.0533 28.0549 26.5532C28.555 26.0531 28.8359 25.3748 28.8359 24.6676V14.0009H16.8359V24.6676H14.1693V14.0009H2.16927Z"
        fill={color}
      />
    </svg>
  </div>
);

export default PresentIcon;
