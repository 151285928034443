const ContributorIcon = ({ color }: { color: string }) => (
  <div>
    <svg
      width="33"
      height="24"
      viewBox="0 0 33 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.98 13.515C9.42 13.545 10.755 14.265 11.655 15.525C12.75 17.07 14.565 18 16.5 18C18.435 18 20.25 17.07 21.345 15.51C22.245 14.25 23.58 13.53 25.02 13.5C23.94 11.67 19.62 10.5 16.5 10.5C13.395 10.5 9.06 11.67 7.98 13.515Z"
        fill={color}
      />
      <path
        d="M4.5 13.5C6.99 13.5 9 11.49 9 9C9 6.51 6.99 4.5 4.5 4.5C2.01 4.5 0 6.51 0 9C0 11.49 2.01 13.5 4.5 13.5Z"
        fill={color}
      />
      <path
        d="M28.5 13.5C30.99 13.5 33 11.49 33 9C33 6.51 30.99 4.5 28.5 4.5C26.01 4.5 24 6.51 24 9C24 11.49 26.01 13.5 28.5 13.5Z"
        fill={color}
      />
      <path
        d="M16.5 9C18.99 9 21 6.99 21 4.5C21 2.01 18.99 0 16.5 0C14.01 0 12 2.01 12 4.5C12 6.99 14.01 9 16.5 9Z"
        fill={color}
      />
      <path
        d="M30 15H25.095C23.94 15 23.07 15.675 22.575 16.38C22.515 16.47 20.535 19.5 16.5 19.5C14.355 19.5 11.955 18.54 10.425 16.38C9.84 15.555 8.925 15 7.905 15H3C1.35 15 0 16.35 0 18V24H10.5V20.61C12.225 21.81 14.31 22.5 16.5 22.5C18.69 22.5 20.775 21.81 22.5 20.61V24H33V18C33 16.35 31.65 15 30 15Z"
        fill={color}
      />
    </svg>
  </div>
);

export default ContributorIcon;
