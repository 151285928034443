import { SupabaseService } from '~/api/service.ts';
import { DraftMemoryContent } from '~/types/memory.ts';

export default class MemoryContentService extends SupabaseService {
  upsert = async (draft: DraftMemoryContent) => {
    const { data, error } = await this.supabase
      .from('memory_content')
      .upsert({ ...draft });

    return { data, error };
  };
}
