import React from 'react';
import ReactDOM from 'react-dom/client';

import '~/utils/init_sentry.ts';
import { api } from '~/api/index.ts';

import App from './App.tsx';

import './index.css';

const enableStrictMode = import.meta.env.VITE_ENABLE_STRICT_MODE === 'true';
const init = async () => {
  const { data } = await api.getClient().auth.getSession();

  ReactDOM.createRoot(document.getElementById('root')!).render(
    enableStrictMode ? (
      <React.StrictMode>
        <App session={data.session} />
      </React.StrictMode>
    ) : (
      <App session={data.session} />
    ),
  );
};

init();
