import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { Upload } from '~/types/upload.ts';

interface FileDraftState {
  uploads: Upload[];
}

interface Actions {
  addUploads: (uploads: Upload[]) => void;
  removeUpload: (uploadId: string) => void;
  updateUpload: (upload: Partial<Upload>) => void;
  reset: () => void;
}

export const useFileDraftStore = create<FileDraftState & Actions>()(
  persist(
    (set) => ({
      uploads: [],
      addUploads: (uploads) => {
        set((state) => ({
          uploads: state.uploads.concat(uploads),
        }));
      },
      removeUpload: (uploadId) => {
        set((state) => ({
          uploads: state.uploads.filter((u) => u.id !== uploadId),
        }));
      },
      updateUpload: (upload: Partial<Upload>) => {
        set((state) => ({
          uploads: state.uploads.map((u) =>
            u.id === upload.id ? { ...u, ...upload } : u,
          ),
        }));
      },
      reset: () => {
        set({ uploads: [] });
      },
    }),
    {
      name: 'file_draft',
    },
  ),
);
