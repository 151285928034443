export default {
  ADD_YOUR_CAPSULE_DESCRIPTION: "Add your capsule's description",
  ADD_YOUR_CAPSULE_TITLE: "Add your capsule's title",
  VIRTUAL_TIME_CAPSULE: 'Virtual Time Capsule',
  // Auth
  SIGN_UP_SUCCESSFUL:
    'Sign up successful! Please check your email to verify your account.',
  SIGN_UP_FAILED: 'Sign up failed. Please try again.',
  LOGIN_FAILED: 'Login failed. Please try again.',
  FORGOT_PASSWORD_SUCCESSFUL: 'Please check your email to reset your password.',
  FORGOT_PASSWORD_FAILED: 'Something went wrong. Please try again.',
  RESET_PASSWORD_SUCCESSFUL: 'Password reset successful!',
  RESET_PASSWORD_FAILED: 'Something went wrong. Please try again.',
  // Capsules List
  SENT_CAPSULES_LIST_TITLE: 'Sent Time Capsules',
  RECEIVED_CAPSULES_LIST_TITLE: 'Received Time Capsules',
  EMPTY_SENT_CAPSULES_LIST_MESSAGE: "You haven't sent any capsules.",
  EMPTY_RECEIVED_CAPSULES_LIST_MESSAGE: "You haven't received any capsules.",

  // Form Validation
  PHONE_INVALID: 'Invalid phone number',
  PASSWORD_MIN_LENGTH: 'Password must contain at least 8 character(s)',
  PASSWORD_MATCH: 'Passwords do not match',
  DATE_INVALID: 'Invalid date',
  DATE_FUTURE: 'Date must be in the future',

  // Shared
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email Address',
  PHONE: 'Phone Number',
  RESET_PASSWORD: 'Reset Password',
  CANCEL: 'Cancel',
  SEND_DATE: 'Send Date',
  BACK: 'Back',
  SAVE_CHANGES: 'Save Changes',

  // Profile Page
  profile: {
    CHANGE_PASSWORD: 'Want to change your Password?',
    SAVE_PROFILE: 'Save Profile',
    EDIT_PROFILE: 'Edit Profile',
    EDIT_PROFILE_FAILED: 'Profile update failed. Please try again.',
    UPLOAD_PICTURE_DIALOG_TITLE: 'Upload a profile picture',
    CHANGE_IMAGE: 'Change Image',
    UPDATE_PICTURE: 'Update Profile Picture',
    UPLOAD_SUCCESSFUL: 'Upload successful!',
    UPLOAD_FAILED: 'Upload failed. Please try again.',
    SAVE_SUCCESSFUL: 'Profile saved successfully!',
    SAVE_FAILED: 'Profile save failed. Please try again.',
    MANAGE_BILLING_TITLE: 'Manage Billing',
    MANAGE_BILLING_DESC:
      'Manage your billing, payment methods, and orders by clicking the link to your billing portal',
    GO_TO_BILLING: 'Go To Billing',
    TITLE: 'Profile',
  },

  // Login Page
  login: {
    ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    DONT_HAVE_ACCOUNT: "Don't have an account?",
    FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  },

  forgotPasswordAndReset: {
    FORGOT_YOUR_PASSWORD_TITLE: 'Forgot Your Password?',
    FORGOT_YOUR_PASSWORD_SUBTITLE:
      'Happens to the best of us! Simply add your email below and we`ll send you a link to reset your password.',
    FORGOT_YOUR_PASSWORD_BTN: 'Send Reset Link',
    FORGOT_YOUR_PASSWORD_SUCCESS_MSG_TITLE: 'Email Sent Successfully!',
    FORGOT_YOUR_PASSWORD_SUCCESS_MSG_SUBTITLE:
      'Please check your email for instructions to reset your password.',
    FORGOT_YOUR_PASSWORD_SUCCESS_BTN: 'Back to Login',
    RESET_PASSWORD_TITLE: 'Reset Your Password',
    RESET_PASSWORD_SUBTITLE:
      'Please enter your new password. Your password must be at least 8 characters long.',
    RESET_PASSWORD_BTN: 'Reset Password',
    RESET_PASSWORD_SUCCESS_MSG_TITLE:
      'Your password has been changed successfully. Please wait while we redirect you to the login page.',
    RESET_PASSWORD_ERROR_MSG_TITLE:
      'Passwords do not match. Please re-enter your password.',
  },

  // Create a capsule
  CREATE_CAPSULE: 'Create a Capsule',

  // Capsule item
  VIDEO: 'video',
  PHOTO: 'photo',
  SCHEDULED_FOR: 'Scheduled for',

  // Error messages
  CAPSULES_NOT_FOUND: 'Capsules not found',
  CAPSULE_NOT_FOUND: 'Capsule not found',

  //Personas
  PERSONAS_NOT_FOUND: 'Loading personas failed',
  USER_PERSONAS_UPDATE_FAILED: 'Could not update personas',
  // Select Template Page
  selectTemplate: {
    CAPSULE_CREATE_SUCCESS: 'New capsule created successfully!',
    CAPSULE_CREATE_ERROR: 'Capsule failed to create. Please try again.',
    START_TEMPLATE_CAPSULE_LABEL: 'Start with a Template',
    START_TEMPLATE_CAPSULE_DESCRIPTION: 'Guide me through telling my story.',
    START_BLANK_CAPSULE_LABEL: 'Start a Blank Capsule',
    START_BLANK_CAPSULE_DESCRIPTION: 'I want to start building on my own.',
    PAGE_ACTION:
      'Use a template to be suggested memory prompts or build from a blank capsule.',
    TITLE: 'Create a Virtual Time Capsule',
  },
  // Select Prompts Page
  selectPrompts: {
    BACK_BTN: 'Back',
    SELECT_PROMPTS: 'Select Prompts',
    SELECT_YOUR_PROMPTS: 'Select your prompts',
    ADD_PROMPTS: 'Add Prompts',
    SEARCH_PROMPTS: 'Search Memory Prompts',
    NO_PROMPTS_FOUND: 'No prompts found...',
    SELECTED_PERSONA_ERROR: 'Selected persona not found',
    SELECTED_TEMPLATE_ERROR: 'Error selecting template:',
    CREATE_YOUR_OWN_BTN: 'Create Your Own',
    CREATE_YOUR_OWN_DESCRIPTION:
      'Alternatively, create your own memory prompt below...',
    CAPSULE_CREATE_SUCCESS:
      'Done! Your capsule has been updated with new prompts',
    CAPSULE_CREATE_ERROR: 'Capsule failed to create. Please try again.',
    ZERO_PROMPTS_WARNING: 'Please select at least one prompt before continuing',
    PROMPT_TEXT_WARNING: 'Memory prompt cannot be empty',
    BTN_STATUS_PROCESSING: 'Processing...',
    BTN_STATUS_CONTINUE: 'Continue',
    PAGE_ACTION_A: 'Select up to',
    PAGE_ACTION_B:
      'prompts, in the following screen you can record or update your content.',
    TITLE: 'Create a Virtual Time Capsule',
  },
  // recipientsReview Page
  recipientsReview: {
    CONTACT_SAVE_ERROR: 'Contact failed to create. Please try again.',
    NO_CONTACTS_ERROR: 'Please add at least one recipient',
    ADD_NEW_CONTACTS: 'Add New Contacts',
    SELECT_FROM_CONTACTS: 'Select from Contacts',
    SAVE_SUCCESSFUL: 'Profile saved successfully!',
    PAGE_ACTION:
      'Choose up to 4 recipients for us to send this capsule to on its scheduled date.',
    TITLE: 'Who should we send this capsule to?',
  },
  //scheduleReview Page
  scheduleReview: {
    UPDATE_CAPSULE_ERROR: 'Error updating capsule:',
    BACK_BTN: 'Back',
    SUBMIT_BTN: 'Save & Review',
    RECIPIENTS_LABEL: 'Recipients',
    SCHEDULE_DATE: 'Schedule Date',
    SELECT_DATE: 'Select Date',
    SELECT_DATE_ERROR: 'Please select a date to send this capsule.',
    SAVE_SUCCESSFUL: 'Profile saved successfully!',
    PAGE_ACTION:
      'You can edit this anytime before the scheduled date, we&apos;ll notify you one week before the capsule gets sent:',
    TITLE: 'When would you like your recipients to receive this capsule?',
  },

  selectCapsulePlan: {
    TITLE: 'Choose your capsule plan',
    PAGE_ACTION: '*You won’t be charged until the end of capsule creation.',
    ONE_TIME_PAYMENTS: 'One-Time Payments',
    SUBSCRIPTION_PLAN: 'Subscription Plan',
    SUBMIT_BTN: 'Continue to Capsule',
    SUBMIT_BTN_DISABLED: 'Select A Plan',
    SAVE_PACKAGE_ERROR:
      'We were unable to save your package selection. Please try again or contact us.',
  },

  payments: {
    CAPSULE_IS_LIVE: 'Capsule Is Live!',
    CAPSULE_UPDATE_ERROR:
      'We were unable to update your capsule status. Please try again or contact us.',
    CAPSULE_UPDATE_SUCCESS:
      'Your capsule is live & scheduled to send! Your capsule will be sent to your recipients on your chosen date.',
    UNEXPECTED_ERROR:
      'An unexpected error occurred. Please try again or contact our support team.',
    BILLING_ACCOUNT_ERROR:
      'We were unable to find your billing account. Please try again or contact our support team.',
    PAYMENTS_SUCCESS_BTN: 'Home Feed',
    PAYMENTS_SUCCESS_TITLE: 'Your Capsule Is Ready for Launch!',
    PAYMENTS_SUCCESS_DESC:
      'You’ll be able to edit this capsule until the scheduled send date.',
    PAYMENTS_FAILED_BTN: 'Try Again',
    PAYMENTS_FAILED_TITLE: 'Uh oh!',
    PAYMENTS_FAILED_DESC: 'We were unable to process your payment',
    PAYMENTS_LOADING:
      'We’re preparing your capsule for its journey to the future!',
    GET_PAYMENT_SESSION_ERROR: 'Error - could not find payment session.',
    SELECT_PACKAGE_ERROR: 'Select a package first before continuing.',
    CREATE_PAYMENTS_SESSION_ERROR: 'Error - could not create payment session.',
    BACK_BTN: 'Back',
    CHANGESELECTION_BTN: 'Change Selection',
    SELECT_PKG_BTN: 'Select Package',
    LOADING_TEXT: 'Please Wait...',
    HOMEFEED_BTN: 'Home Feed',
    SUBMIT_BTN: 'Confirm & Pay',
    SUBMIT_BTN_DISABLED: 'Select A Package',
    PAGE_ACTION: '',
    TITLE: 'Let’s review your package selection:',
  },
  contributions: {
    PAGE_ACTION: 'Choose up to 4 contributors.',
    TITLE: 'Who do you want to contribute?',
  },
};
