import axios from 'axios';

import { SupabaseService } from '~/api/service.ts';
import { AppResponse } from '~/types/request.ts';
import { getNewAvatarPath } from '~/utils/profile.ts';

const MAX_PART_SIZE = 5 * 1024 * 1024; // 5 MB

interface UploadParams {
  name: string;
  path?: string;
  asset: File | Blob;
  contentType: string;

  startedCallback?: (url: string) => void;
  progressCallback?: (progress: { loaded: number; total: number }) => void;
}

export default class StorageService extends SupabaseService {
  // High-level method to handle the entire multi-part upload process
  multipartUpload = async (params: UploadParams) => {
    try {
      const totalParts = Math.ceil(params.asset.size / MAX_PART_SIZE);
      const initResponse = await this.initializeMultipartUpload(
        params.name,
        totalParts,
      );

      if (initResponse.error) {
        return { data: null, error: initResponse.error };
      }

      const { uploadId, urls } = initResponse.data;
      params.startedCallback?.(urls[0].url);

      const parts = await this.uploadFileParts(
        params.asset,
        urls,
        params.progressCallback,
      );

      const completeResponse = await this.completeMultipartUpload(
        params.name,
        uploadId,
        parts,
      );

      if (completeResponse.error) {
        return { data: null, error: completeResponse.error };
      }

      return { data: { success: true }, error: null };
    } catch (error) {
      return { data: null, error: error as Error };
    }
  };

  // upload: Supports file uploads for capsule videos and profile pictures
  upload = async (params: UploadParams) => {
    try {
      const presignedUrl = await this.getUpdateSignedUrl(params.name);

      if (presignedUrl.error) {
        return { data: null, error: presignedUrl.error };
      }

      params.startedCallback?.(presignedUrl.data.signedUrl);
      const response = await axios.put(
        presignedUrl.data.signedUrl,
        params.asset,
        {
          headers: { 'Content-Type': params.contentType },
          onUploadProgress: (progress) => {
            if (progress.total) {
              params.progressCallback?.({
                loaded: progress.loaded,
                total: progress.total,
              });
            }
          },
        },
      );

      if (response.status !== 200) {
        return { data: null, error: new Error('Failed to upload') };
      }

      return { data: { success: true }, error: null };
    } catch (error) {
      return { error, data: null };
    }
  };

  // Multi-part upload: Initializes a multi-part upload and generates presigned URLs
  initializeMultipartUpload = async (
    key: string,
    totalParts: number,
  ): AppResponse<{
    uploadId: string;
    urls: { url: string; partNumber: number }[];
  }> => {
    try {
      const { data, error } = await this.supabase.functions.invoke(
        'get-s3-presigned-url',
        {
          body: { key, command: 'initiate', totalParts },
        },
      );

      if (error) {
        return { error, data: null };
      }

      return { data, error: null };
    } catch (error) {
      return { error: error as Error, data: null };
    }
  };

  // Multi-part upload: Completes the upload after all parts are uploaded
  completeMultipartUpload = async (
    key: string,
    uploadId: string,
    parts: { ETag: string; PartNumber: number }[],
  ): AppResponse<{ success: boolean }> => {
    try {
      const { data, error } = await this.supabase.functions.invoke(
        'get-s3-presigned-url',
        {
          body: { key, command: 'complete', uploadId, parts },
        },
      );

      if (error) {
        return { error, data: null };
      }

      return { data, error: null };
    } catch (error) {
      return { error: error as Error, data: null };
    }
  };

  // Multi-part upload: Uploads file parts to S3 using presigned URLs
  uploadFileParts = async (
    asset: File | Blob,
    presignedUrls: { url: string; partNumber: number }[],
    progressCallback?: (progress: { loaded: number; total: number }) => void,
  ) => {
    const parts = [];

    let curr = 0,
      partLength = 0;
    let remaining = asset.size;
    for (let i = 0; i < presignedUrls.length; i++) {
      const { url, partNumber } = presignedUrls[i];

      if (remaining < MAX_PART_SIZE) {
        partLength = remaining;
      } else {
        partLength = MAX_PART_SIZE;
      }

      const chunk = asset.slice(curr, curr + partLength);
      const response = await axios.put(url, chunk, {
        withCredentials: false,
        headers: {
          'Content-Type': asset.type,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
        onUploadProgress: (progress) => {
          if (progress.total) {
            progressCallback?.({
              loaded: progress.loaded,
              total: progress.total,
            });
          }
        },
      });

      remaining -= partLength;
      curr += partLength;
      if (response.status === 200) {
        parts.push({
          ETag: response.headers.etag,
          PartNumber: partNumber,
        });
      } else {
        throw new Error('Failed to upload part');
      }
    }

    return parts;
  };

  getUpdateSignedUrl = async (
    key: string,
  ): AppResponse<{ signedUrl: string }> => {
    try {
      const { data, error } = await this.supabase.functions.invoke(
        'get-s3-presigned-url',
        {
          body: { key, command: 'put' },
        },
      );

      if (error) {
        return { error, data: null };
      }

      return { data, error: null };
    } catch (error) {
      return { error: error as Error, data: null };
    }
  };

  getReadSignedUrl = async (
    key: string,
    contentType?: string,
  ): AppResponse<{ signedUrl: string }> => {
    try {
      let cleanContentType = contentType;
      if (contentType?.includes(';')) {
        // For content types that include additional info, like 'image/jpeg;charset=utf-8' or 'audio/webm;codecs=opus'
        cleanContentType = contentType.split(';')[0];
        if (cleanContentType === 'audio/webm') {
          cleanContentType = 'audio/mp4';
        }
      }
      const { data, error } = await this.supabase.functions.invoke(
        'get-s3-presigned-url',
        {
          body: { key, contentType: cleanContentType, command: 'get' },
        },
      );

      if (error) {
        return { error, data: null };
      }

      return { data, error: null };
    } catch (error) {
      return { error: error as Error, data: null };
    }
  };

  thumbnailUpload = async (params: UploadParams) => {
    if (!params.path) {
      return { data: null, error: new Error('Path is required') };
    } else if (!params.asset) {
      return { data: null, error: new Error('Blob is required') };
    }

    return this.supabase.storage
      .from('thumbnails')
      .upload(params.path, params.asset, { contentType: params.contentType });
  };

  getThumbnailSignedUrl = async (
    key: string,
  ): AppResponse<{ signedUrl: string }> => {
    const { data, error } = await this.supabase.storage
      .from('thumbnails')
      .createSignedUrl(key, 3600);
    if (error) {
      return { error, data: null };
    }
    return { data, error: null };
  };

  /**
   * Updates the logged in user's avatar by deleting the current avatar and uploading the new one
   * @param avatar The image to use as the user's avatar
   * @param authId The auth ID of the user, will be looked up if not passed in
   * @param oldAvatarPath The path to the user's current avatar, if one exists
   * @returns The new avatar path if updated successfully, null if there was an error
   */
  updateAvatar = async (
    avatar: File,
    authId?: string,
    oldAvatarPath?: string | null,
  ): Promise<string | null> => {
    try {
      if (!authId) {
        const { data, error } = await this.supabase.auth.getUser();
        if (error) {
          throw new Error(error.message);
        }
        authId = data.user.id;
      }

      if (oldAvatarPath) {
        await this.deleteAvatar(oldAvatarPath);
      }

      const newAvatarPath = getNewAvatarPath(
        authId,
        avatar.type,
        oldAvatarPath,
      );

      await this.uploadAvatar(avatar, newAvatarPath);
      await this.updateAvatarPath(authId, newAvatarPath);

      return newAvatarPath;
    } catch (e) {
      return null;
    }
  };

  /**
   * Deletes the user's current avatar in supabase
   * @param avatarPath The path to the user's current avatar
   */
  private deleteAvatar = async (avatarPath: string) => {
    const { error } = await this.supabase.storage
      .from('avatars')
      .remove([avatarPath]);

    if (error) {
      throw new Error(error.message);
    }
  };

  /**
   * Uploads the user's new avatar to supabase
   * @param avatar The image to use as the user's avatar
   * @param avatarPath The path to upload the image to
   */
  private uploadAvatar = async (avatar: File, avatarPath: string) => {
    const { error } = await this.supabase.storage
      .from('avatars')
      .upload(avatarPath, avatar);

    if (error) {
      throw new Error(error.message);
    }
  };

  /**
   * Gets the URL for the avatar of the user from the database
   * @param avatarPath The path to the user's avatar, from the public.users table
   * @returns A string with the public URL of the avatar
   */
  getAvatarUrl = async (avatarPath: string) => {
    const { data } = this.supabase.storage
      .from('avatars')
      .getPublicUrl(avatarPath);

    return data.publicUrl;
  };

  /**
   * Deletes one or more thumbnail images/gifs from supabase storage
   * @param thumbnailPaths List of thumbnails to be deleted
   * @returns An error if one occurred
   */
  deleteThumbnails = async (
    thumbnailPaths: string[],
  ): Promise<Error | null> => {
    const { error } = await this.supabase.storage
      .from('thumbnails')
      .remove(thumbnailPaths);

    return error;
  };

  /**
   * Deletes one or more attachment files from s3 storage
   * @param keys List of attachment keys to be deleted, in the format amazon expects
   * @returns An error if one occurred
   */
  deleteAttachments = async (
    keys: { Key: string }[],
  ): Promise<Error | null> => {
    const { error } = await this.supabase.functions.invoke(
      'delete-s3-objects',
      {
        body: { keys },
      },
    );

    return error;
  };
}
