import { createNanoEvents } from 'nanoevents';

import { Memory, MemoryContent } from '~/types/memory.ts';

import type { Emitter } from 'nanoevents';

interface Events {
  'memory.mediaCarousel.open': (
    memory: Memory,
    memoryContent: MemoryContent,
    selectedIndex: number,
  ) => void;
}

class AppEvents {
  emitter: Emitter;

  constructor() {
    this.emitter = createNanoEvents<Events>();
  }

  on<E extends keyof Events>(event: E, callback: Events[E]) {
    return this.emitter.on(event, callback);
  }
}

export default new AppEvents();
