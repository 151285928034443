import { api } from '~/api';

enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

class ApiClient {
  private async request<T>(
    method: HttpMethod,
    path: string,
    body?: unknown,
  ): Promise<T> {
    const token = await this.getToken();
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(
      `${import.meta.env.VITE_API_BASE_URL}${path}`,
      {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      },
    );

    if (response.status === 204) {
      // @ts-ignore - We don't need to return anything for 204 responses
      return null;
    } else if (!response.ok) {
      throw new Error(
        `ApiClient.response#error [${response.status}] ${method} ${path} - ${response.statusText}`,
      );
    }

    return await response.json();
  }

  protected async getToken(): Promise<string | null> {
    return null;
  }

  public get<T>(path: string) {
    return this.request<T>(HttpMethod.GET, path);
  }

  public put<T>(path: string, body?: unknown) {
    return this.request<T>(HttpMethod.PUT, path, body);
  }

  public post<T>(path: string, body?: unknown) {
    return this.request<T>(HttpMethod.POST, path, body);
  }

  public delete<T>(path: string) {
    return this.request<T>(HttpMethod.DELETE, path);
  }
}

class AuthenticatedApiClient extends ApiClient {
  protected async getToken() {
    const { token, error: tokenError } = await api.auth.getToken();

    if (!token || tokenError) {
      throw new Error('Failed to get auth token');
    }

    return token;
  }
}

export const apiClient = new AuthenticatedApiClient();
export const apiClientNoAuth = new ApiClient();
