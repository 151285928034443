import { Box } from '@mui/material';

import ContributorRequestItem from '~/components/contributor_request_item/index.tsx';
import Text from '~/components/text.tsx';
import { useGetCurrentUser } from '~/state/users';
import { MemoryContributorInvite } from '~/types/contributions.ts';

interface ContributorsTabProps {
  invites: MemoryContributorInvite[] | null;
}
const ContributorsTab = ({ invites }: ContributorsTabProps) => {
  const currentUser = useGetCurrentUser();

  return (
    <>
      <Text title>Contributions</Text>
      <Box
        display="flex"
        justifyContent="flex-start"
        flex={1}
        flexDirection={'column'}
        alignItems={'flex-start'}
        mt={2}
      >
        {invites && invites?.length > 0 ? (
          invites.map((invite) => (
            <ContributorRequestItem
              key={invite.id}
              invite={invite}
              userId={currentUser.id}
            />
          ))
        ) : (
          <Text>You currently do not have any contribution requests.</Text>
        )}
      </Box>
    </>
  );
};

export default ContributorsTab;
