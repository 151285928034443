export const toastDefaultPosition = 'top-right';
export const toastOptions = {
  className: '',
  style: {
    fontSize: '14px',
    fontWeight: 'semi-bold',
  },
  success: {
    duration: 5000,
  },
};
