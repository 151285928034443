import { useCallback, useState } from 'react';
import VideoRecorder from 'react-video-recorder';
import { css } from '@emotion/react';
import { ArrowBack } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton } from '@mui/material';

import MemoryItem from '~/components/memory/memory_item.tsx';
import VideoRecorderActions from '~/components/video_recorder/video_recorder_actions.tsx';
import VideoRecorderDisconnectedView from '~/components/video_recorder/video_recorder_disconnected_view.tsx';
import { Memory } from '~/types/memory.ts';

const VIDEO_RECORDER_LIMIT = 120000; // 2 minutes

const VideoRecordMemoryModal = ({
  memory,
  open,
  handleClose,
  onComplete,
}: {
  memory: Memory;
  open: boolean;
  handleClose: () => void;
  onComplete: (videoBlob: Blob) => void;
}) => {
  const [activeBlob, setActiveBlob] = useState<Blob | null>(null);

  const onRecordingComplete = useCallback((videoBlob: Blob) => {
    setActiveBlob(videoBlob);
  }, []);

  const onConfirm = useCallback(() => {
    if (activeBlob) {
      onComplete(activeBlob);
      handleClose();
    }
  }, [activeBlob, onComplete, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={true}
      disableEscapeKeyDown={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" component={'div' as const}>
        <IconButton onClick={handleClose}>
          <ArrowBack />
        </IconButton>
      </DialogTitle>

      <div css={styles.headerContainer}>
        <MemoryItem memory={memory} />
      </div>

      <VideoRecorder
        onRecordingComplete={onRecordingComplete}
        timeLimit={VIDEO_RECORDER_LIMIT}
        isOnInitially={true}
        renderDisconnectedView={() => <VideoRecorderDisconnectedView />}
        renderActions={({ ...props }) => {
          return <VideoRecorderActions {...props} onConfirm={onConfirm} />;
        }}
      />
    </Dialog>
  );
};

const styles = {
  headerContainer: css({
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '24px',
  }),
};

export default VideoRecordMemoryModal;
