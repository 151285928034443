import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useLoaderData,
} from 'react-router-dom';
import { redirect } from 'react-router-typesafe';

import { useAuth } from '~/api/hooks/useAuth.tsx';
import { useHydrateFileUploads } from '~/api/hooks/useFileUpload.ts';
import CheckEmail from '~/routes/auth/checkEmail';
import ForgotPassword from '~/routes/auth/forgot-password';
import Login from '~/routes/auth/login';
import ResetPassword from '~/routes/auth/reset-password';
import SignUp from '~/routes/auth/sign-up';
import {
  CapsuleIndexRoute,
  capsuleLoader,
  CapsulePublicRoute,
  draftCapsuleLoader,
} from '~/routes/capsule';
import MemoryContributorsRoute, {
  contributorsLoader,
} from '~/routes/capsule/memory/contributions/index.tsx';
import MemoryIndexRoute, {
  memoryLoader,
} from '~/routes/capsule/memory/index.tsx';
import ConfirmCapsulePlan, {
  packagesLoader,
} from '~/routes/capsule/payments/confirm-capsule-plan.tsx';
import OrderComplete, {
  orderCompleteLoader,
} from '~/routes/capsule/payments/order-complete.tsx';
import SelectCapsulePlan, {
  capsulePlanLoader,
} from '~/routes/capsule/payments/select-capsule-plan.tsx';
import CapsulePreview from '~/routes/capsule/review-and-send/capsule-preview.tsx';
import CapsuleReviewRecipients from '~/routes/capsule/review-and-send/index.tsx';
import CapsuleReviewScheduleSend from '~/routes/capsule/review-and-send/schedule-send.tsx';
import { promptsLoader } from '~/routes/capsule/select-prompts';
import NewCapsuleSelectPrompts from '~/routes/capsule/select-prompts.tsx';
import NewCapsuleSelectTemplate from '~/routes/capsule/select-template.tsx';
import { createCapsuleLoader } from '~/routes/create-capsule.tsx';
import Home, { homeLoader } from '~/routes/home';
import Onboarding, { onboardingLoader } from '~/routes/onboarding';
import Playground from '~/routes/playground.tsx';
import Profile from '~/routes/profile.tsx';
import { fetchCurrentUser } from '~/state/users';

const AuthenticatedRoutes = () => {
  const { session } = useAuth();
  const currentUser = useLoaderData();
  useHydrateFileUploads();
  return session && currentUser ? <Outlet /> : <Navigate to="/login" />;
};

const authenticatedLoader = async () => {
  const currentUser = await fetchCurrentUser();
  if (!currentUser) {
    throw redirect('/login');
  }

  return currentUser;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        path="/public/:capsuleId"
        id={'publicCapsuleView'}
        loader={capsuleLoader}
        element={<CapsulePublicRoute />}
      />
      <Route loader={authenticatedLoader} element={<AuthenticatedRoutes />}>
        <Route index loader={homeLoader} element={<Home />} />
        <Route
          path="/onboarding"
          loader={onboardingLoader}
          element={<Onboarding />}
        />
        <Route path="/playground" element={<Playground />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/capsule">
          <Route path="new" loader={createCapsuleLoader} />
          <Route
            path=":capsuleId"
            id={'capsuleRoot'}
            loader={capsuleLoader}
            shouldRevalidate={({ currentUrl }) => {
              // NOTE(miguel): let's shy away from this solution, we'll keep for the sake of mvp
              // revalidate if originates from any sub-routes of the capsule id
              return /\/capsule\/[^\/]+\/*/.test(currentUrl.pathname);
            }}
          >
            <Route index element={<CapsuleIndexRoute />} />
            <Route
              path="memory/:memoryId"
              id={'memoryRoot'}
              loader={memoryLoader}
            >
              <Route index element={<MemoryIndexRoute />} />
              <Route
                path="contributors"
                loader={contributorsLoader}
                element={<MemoryContributorsRoute />}
              />
            </Route>

            <Route
              path="select-plan"
              loader={capsulePlanLoader}
              element={<SelectCapsulePlan />}
            />
            <Route
              path="select-prompts"
              loader={promptsLoader}
              element={<NewCapsuleSelectPrompts />}
            />
            <Route
              path="select-template"
              id={'capsuleSelectTemplate'}
              loader={draftCapsuleLoader}
              element={<NewCapsuleSelectTemplate />}
            />
            <Route path="review">
              <Route
                path="recipients"
                loader={capsuleLoader}
                element={<CapsuleReviewRecipients />}
              />
              <Route
                path="schedule"
                loader={capsuleLoader}
                element={<CapsuleReviewScheduleSend />}
              />
              <Route
                path="preview"
                loader={capsuleLoader}
                element={<CapsulePreview />}
              />
              <Route
                path="confirm-plan"
                loader={packagesLoader}
                element={<ConfirmCapsulePlan />}
              />

              <Route
                path="order-complete"
                loader={orderCompleteLoader}
                element={<OrderComplete />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/login/verify-email" element={<CheckEmail />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/sign-up" element={<SignUp />} />
    </Route>,
  ),
);

const Root = () => {
  return <RouterProvider router={router} />;
};

export default Root;
