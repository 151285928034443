import { useCallback, useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Params, useNavigate } from 'react-router-dom';
import { useLoaderData } from 'react-router-typesafe';
import { CheckCircle, Close, Search } from '@mui/icons-material';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PostgrestError } from '@supabase/supabase-js';
import { v4 as uuid } from 'uuid';

import { api } from '~/api/index.ts';
import { DraftPrompt, DraftThemeCategoryWithPrompts } from '~/types/theme.ts';
import SelectTemplates from '~/assets/images/select-templates.png';
import { SimpleHeaderContainer } from '~/components/containers/header_container.tsx';
import ErrorView from '~/components/error_view.tsx';
import NewMemoryPopup from '~/components/memory/new_memory_popup.tsx';
import { EditMemoryPopup } from '~/components/memory/new_memory_popup.tsx';
import strings from '~/constants/strings.ts';
import draft from '~/state/draft.ts';
import { useGetCurrentUser, fetchCurrentUser } from '~/state/users';
import { DraftCapsule } from '~/types/capsule.ts';
import { MemoryStatus } from '~/types/memory.ts';
import {
  Persona,
  UserPersona,
  PersonasThemeCategories,
} from '~/types/persona.ts';

export interface PromptsDataProps {
  data: Persona[] | null;
  error: Error | PostgrestError | null;
}

const MAX_PROMPTS = 10;
const Banner = styled('img')({
  width: '100%',
  maxWidth: '200px',
  height: 'auto',
  objectFit: 'cover',
});

const CategoryIcon = styled('img')({
  width: '60px',
  height: '60px',
  marginRight: '20px',
});

const TemplateCard = styled(Paper)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    minWidth: 120,
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    border: selected ? '3px solid' : 'none',
    borderColor: selected ? theme.palette.primary.main : 'transparent',
  }),
);
const PromptCard = styled(Paper)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    border: selected ? '2px solid green' : 'none',
  }),
);

export const promptsLoader = async ({
  params,
}: {
  params: Params<'capsuleId'>;
}) => {
  if (!params.capsuleId) {
    return { error: strings.CAPSULE_NOT_FOUND };
  }

  let draftCapsule = draft.capsules.get(params.capsuleId);

  if (!draftCapsule) {
    try {
      const { data: capsuleData } = await api.capsules.getCapsuleById(
        params.capsuleId,
      );
      if (!capsuleData) {
        return { error: strings.CAPSULE_NOT_FOUND };
      }
      draftCapsule = capsuleData;
    } catch (error) {
      return { error: 'Error fetching capsule' };
    }
  }

  const { data, error } = await api.themes.getPersonasAndThemeCategories();

  const currentUser = await fetchCurrentUser();
  if (!currentUser) {
    return { error: 'User not found' };
  }

  const { data: userPersonaData } = await api.auth.getUserPersonas(
    currentUser.authId,
  );

  return {
    capsule: draftCapsule,
    promptsData: { data, error },
    userPersonas: { data: userPersonaData },
    state: 'draft',
  };
};

const getOrderedIdentities = (
  data: any[],
  userPersonasData: UserPersona[] | null,
) => {
  return [...(data || [])].sort((a, b) => {
    const aIsUserPersona = userPersonasData?.some((p) => p.personaId === a.id);
    const bIsUserPersona = userPersonasData?.some((p) => p.personaId === b.id);
    if (aIsUserPersona && !bIsUserPersona) return -1;
    if (!aIsUserPersona && bIsUserPersona) return 1;
    return 0;
  });
};

const themeCategoriesToDraftPromptState = (
  nestedPromptData: PromptsDataProps | null,
  identityId: string,
): { themeCategories: any[]; prompts: DraftPrompt[] } => {
  if (!nestedPromptData?.data) {
    return { themeCategories: [], prompts: [] };
  }

  const selectedPersona = nestedPromptData?.data.find(
    (persona) => persona.id === identityId,
  );
  if (!selectedPersona) {
    return { themeCategories: [], prompts: [] };
  }

  const themeCategories =
    selectedPersona?.personasThemeCategories
      ?.sort(
        (a: PersonasThemeCategories, b: PersonasThemeCategories) =>
          a.order - b.order,
      )
      .map((category) => ({
        id: category.themeCategory.id,
        name: category.themeCategory.name,
        image: category.themeCategory.image,
        createdAt: category.themeCategory.createdAt,
        updatedAt: category.themeCategory.updatedAt,
      })) || [];

  const allPrompts: DraftPrompt[] =
    selectedPersona?.personasThemeCategories?.flatMap((category) =>
      category.themeCategory?.prompts?.map((prompt) => ({
        type: 'existing',
        prompt,
      })),
    ) || [];

  return { themeCategories, prompts: allPrompts };
};

const usePromptManagement = (
  promptsData: PromptsDataProps,
  userPersonas: { data: UserPersona[] | null },
) => {
  const [prompts, setPrompts] = useState<any[]>([]);
  const [selectedPrompts, setSelectedPrompts] = useState<any[]>([]); // all the data that's selected/unselected by the user
  const [themeCategories, setThemeCategories] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [identities, setIdentities] = useState<any[]>([]);

  useEffect(() => {
    if (promptsData.data) {
      try {
        let matchingPersona = promptsData.data.find((persona: Persona) =>
          userPersonas.data?.some(
            (userPersona: UserPersona) => userPersona.personaId === persona.id,
          ),
        );

        if (!matchingPersona) {
          matchingPersona = promptsData.data[0];
        }

        const allPrompts = themeCategoriesToDraftPromptState(
          promptsData,
          matchingPersona.id,
        );
        const selectedData = matchingPersona || promptsData.data[0];
        const orderedIdentities = getOrderedIdentities(
          promptsData.data,
          userPersonas.data,
        );

        setSelectedTemplate(selectedData?.id || '');
        setPrompts(allPrompts.prompts);
        setIdentities(orderedIdentities);
        setThemeCategories(allPrompts.themeCategories);
      } catch (error) {
        console.error('Error processing prompt data:', error);
        toast.error('Error loading prompts');
      }
    }
  }, [promptsData, userPersonas]);

  return {
    prompts,
    setPrompts,
    selectedPrompts,
    setSelectedPrompts,
    selectedTemplate,
    setSelectedTemplate,
    identities,
    themeCategories,
    setThemeCategories,
  };
};

const NewCapsuleSelectPromptsView = ({
  capsule,
  promptsData,
  userPersonas,
  state,
}: {
  capsule: DraftCapsule;
  userPersonas: { data: UserPersona[] | null };
  promptsData: PromptsDataProps;
  state: string;
}) => {
  const navigate = useNavigate();
  const currentUser = useGetCurrentUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [promptSearchQuery, setPromptSearchQuery] = useState<string>('');
  const [isNewMemoryPopupOpen, setIsNewMemoryPopupOpen] =
    useState<boolean>(false);
  const [isEditMemoryPopupOpen, setIsEditMemoryPopupOpen] =
    useState<boolean>(false);
  const [memoryToEdit, setMemoryToEdit] = useState<DraftPrompt | null>(null);
  const {
    prompts,
    setPrompts,
    selectedPrompts,
    setSelectedPrompts,
    selectedTemplate,
    setSelectedTemplate,
    identities,
    themeCategories,
    setThemeCategories,
  } = usePromptManagement(promptsData, userPersonas);

  const handleSelectTemplate = useCallback(
    (id: string) => {
      try {
        const selectedIdentity = identities.find(
          (identity: any) => identity.id === id,
        );
        if (!selectedIdentity) {
          toast.error(`${strings.selectPrompts.SELECTED_PERSONA_ERROR}`);
          return;
        }

        const allPrompts = themeCategoriesToDraftPromptState(
          promptsData,
          selectedIdentity.id,
        );
        setSelectedTemplate(selectedIdentity?.id || '');
        setPrompts(allPrompts.prompts);
        setThemeCategories(allPrompts.themeCategories);
      } catch (error) {
        console.error(
          `${strings.selectPrompts.SELECTED_TEMPLATE_ERROR}`,
          error,
        );
        toast.error(`${strings.selectPrompts.SELECTED_TEMPLATE_ERROR}`);
      }
    },
    [identities, selectedPrompts, setPrompts, setSelectedTemplate],
  );

  const handlePromptSelection = useCallback(
    (prompt: DraftPrompt) => {
      setSelectedPrompts((prev: DraftPrompt[]) => {
        if (prev.length >= MAX_PROMPTS && !prev.includes(prompt)) {
          toast.error(`Maximum ${MAX_PROMPTS} prompts allowed`);
          return prev;
        }
        return prev.includes(prompt)
          ? prev.filter((p: DraftPrompt) => p !== prompt)
          : [prompt, ...prev.filter((p: DraftPrompt) => p !== prompt)];
      });
    },
    [setSelectedPrompts],
  );
  const handleStartCapsuleWithSelectedPrompts = useCallback(async () => {
    if (selectedPrompts.length === 0) {
      toast.error(`${strings.selectPrompts.ZERO_PROMPTS_WARNING}`);
      return;
    }

    setIsLoading(true);
    try {
      const { data: existingCapsule } = await api.capsules.getCapsuleById(
        capsule.id,
      );
      const newCapsule =
        existingCapsule || (await api.capsules.create(capsule)).data;

      if (!newCapsule) {
        throw new Error(`${strings.selectPrompts.CAPSULE_CREATE_ERROR}`);
        return;
      }

      await api.memories.createMany(
        selectedPrompts.map((memory) => ({
          id: uuid(),
          capsuleId: newCapsule.id,
          userId: currentUser.id,
          title: memory.prompt.name,
          status: MemoryStatus.Draft,
        })),
      );

      toast.success(`${strings.selectPrompts.CAPSULE_CREATE_SUCCESS}`);
      navigate(`/capsule/${newCapsule.id}`);
    } catch (error) {
      console.error(`${strings.selectPrompts.CAPSULE_CREATE_ERROR}`, error);
      toast.error(`${strings.selectPrompts.CAPSULE_CREATE_ERROR}`);
      return;
    } finally {
      setIsLoading(false);
    }
  }, [selectedPrompts, capsule, navigate, currentUser]);

  const handleCreateMemory = useCallback(
    (memoryName: string) => {
      if (!memoryName.trim()) {
        toast.error(`${strings.selectPrompts.PROMPT_TEXT_WARNING}`);
        return;
      }

      const promptDraft = {
        type: 'new',
        prompt: {
          id: uuid(),
          name: memoryName.trim(),
        },
      };

      setSelectedPrompts((prev) => [promptDraft, ...prev]);
      setPrompts((prev) => [promptDraft, ...prev]);
      setIsNewMemoryPopupOpen(false);
    },
    [setSelectedPrompts, setPrompts],
  );

  const handleEditMemory = useCallback(
    (memory: DraftPrompt) => {
      const { id: memoryId, name: memoryName } = memory.prompt;

      if (!memoryName || !memoryName.trim()) {
        toast.error(`${strings.selectPrompts.PROMPT_TEXT_WARNING}`);
        return;
      }

      const updatedPrompt = {
        type: 'new',
        prompt: {
          id: memoryId,
          name: memoryName.trim(),
        },
      };

      const memoryToEdit = prompts.find((p) => p.prompt.id === memoryId);

      if (memoryToEdit) {
        const promptIndex = prompts.indexOf(memoryToEdit);
        const selectedPromptIndex = selectedPrompts.indexOf(memoryToEdit);

        if (promptIndex !== -1) {
          setPrompts((prev) => {
            const updated = [...prev];
            updated[promptIndex] = updatedPrompt;
            return updated;
          });
        }

        if (selectedPromptIndex !== -1) {
          setSelectedPrompts((prev) => {
            const updated = [...prev];
            updated[selectedPromptIndex] = updatedPrompt;
            return updated;
          });
        }
      }
      setIsEditMemoryPopupOpen(false);
    },
    [prompts, selectedPrompts, setSelectedPrompts],
  );
  const handlePromptSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value.toLowerCase();
      setPromptSearchQuery(searchValue);
    },
    [prompts, themeCategories, setPromptSearchQuery, promptSearchQuery],
  );
  const handleClearSearch = useCallback(() => {
    setPromptSearchQuery('');
  }, [prompts, promptsData.data]);

  const handleToggleEditMemory = useCallback(
    (memory: DraftPrompt) => {
      setMemoryToEdit(memory);
      setTimeout(() => setIsEditMemoryPopupOpen(true), 500);
    },
    [memoryToEdit, isEditMemoryPopupOpen, setMemoryToEdit],
  );

  const handleDeleteMemory = useCallback(
    (memory: DraftPrompt) => {
      const updatedPrompts = prompts.filter(
        (draft: DraftPrompt) => draft.prompt.name !== memory.prompt.name,
      );
      const updatedSelectedPrompts = selectedPrompts.filter(
        (draft: DraftPrompt) => draft.prompt.name !== memory.prompt.name,
      );

      setPrompts(updatedPrompts);
      setSelectedPrompts(updatedSelectedPrompts);
    },
    [prompts, selectedPrompts, setPrompts, setSelectedPrompts],
  );

  const customPrompts = selectedPrompts.filter(
    (draft: DraftPrompt) => draft.type === 'new',
  );
  const hasCustomPrompts = customPrompts.length > 0;

  const searchedThemeCategories = useMemo(() => {
    //If no search query, return all theme categories
    if (!promptSearchQuery || promptSearchQuery === '') return prompts;
    // do search logic and return new search array
    const newSearch = themeCategories.filter((category) => {
      const hasMatchingPrompts = prompts.some(
        (prompt) =>
          new RegExp(promptSearchQuery.toLowerCase()).test(
            prompt.prompt.name.toLowerCase(),
          ) && prompt.prompt.categoryId === category.id,
      );
      return hasMatchingPrompts;
    });
    const allSearchedPrompts = prompts.filter(
      (prompt: DraftPrompt) =>
        prompt.type === 'existing' &&
        newSearch.some((category) => category.id === prompt.prompt.categoryId),
    );
    return allSearchedPrompts;
  }, [themeCategories, prompts, promptSearchQuery, selectedTemplate]);

  return (
    <SimpleHeaderContainer
      noPadding={true}
      headerTitle={
        <Typography variant="h6" noWrap component="div" color="textPrimary">
          {state === 'draft'
            ? `${strings.selectPrompts.SELECT_YOUR_PROMPTS}`
            : `${strings.selectPrompts.ADD_PROMPTS}`}
        </Typography>
      }
      headerRight={
        <IconButton aria-label="navigate back" onClick={() => navigate(-1)}>
          <Close />
        </IconButton>
      }
    >
      <NewMemoryPopup
        isOpen={isNewMemoryPopupOpen}
        onClose={setIsNewMemoryPopupOpen}
        callback={handleCreateMemory}
      />
      <EditMemoryPopup
        isOpen={isEditMemoryPopupOpen}
        onClose={setIsEditMemoryPopupOpen}
        callback={handleEditMemory}
        memoryToEdit={memoryToEdit}
      />

      <Box
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent={'center'}
      >
        <Banner src={SelectTemplates} alt="Capsule Creation Banner" />
      </Box>
      <Box sx={{ mt: 2, p: 3 }}>
        <Typography variant="subtitle1" color="text.secondary">
          {strings.selectPrompts.PAGE_ACTION_A} {MAX_PROMPTS}{' '}
          {strings.selectPrompts.PAGE_ACTION_B}
        </Typography>
        <hr />
        <Typography variant="subtitle1" color="text.secondary">
          {strings.selectPrompts.CREATE_YOUR_OWN_DESCRIPTION}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            mt: 1,
          }}
        >
          <Button
            variant="contained"
            onClick={() => setIsNewMemoryPopupOpen(true)}
            disabled={isNewMemoryPopupOpen}
          >
            {strings.selectPrompts.CREATE_YOUR_OWN_BTN}
          </Button>
        </Box>
      </Box>
      <Box sx={{ p: 3 }}>
        <TextField
          value={promptSearchQuery}
          onChange={handlePromptSearch}
          placeholder={strings.selectPrompts.SEARCH_PROMPTS}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: promptSearchQuery && (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Box>

      <Box
        sx={{
          overflowX: 'scroll',
          display: 'flex',
          pb: 2,
          mt: 2,
          ml:3,
          maxWidth: '90vw',
        }}
      >
        {identities.map((identity: Persona) => (
          <TemplateCard
            key={identity.id}
            selected={selectedTemplate === identity.id}
            onClick={() => handleSelectTemplate(identity.id)}
          >
            <img
              src={identity.image}
              alt={identity.name}
              style={{ width: '64px', height: '64px' }}
            />
            <Typography variant="subtitle2" align="center" sx={{ mt: 1 }}>
              {identity.name}
            </Typography>
          </TemplateCard>
        ))}
      </Box>

      <Box sx={{ mt: 4, p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {strings.selectPrompts.SELECT_PROMPTS} ({selectedPrompts.length}/
          {MAX_PROMPTS})
        </Typography>
        <Box sx={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}>
          {hasCustomPrompts && (
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PhotoFilterIcon sx={{ mr: 1 }} />
                <Typography variant="h6">Custom Memories</Typography>
              </Box>
              {customPrompts.map((prompt: DraftPrompt, index: number) => (
                <PromptCard
                  key={`custom-${index}`}
                  selected={selectedPrompts.includes(prompt)}
                >
                  <Box sx={{ marginRight: '10px' }}>
                    <CheckCircle
                      onClick={() => handlePromptSelection(prompt)}
                      sx={{
                        color: selectedPrompts.includes(prompt)
                          ? 'green'
                          : 'grey',
                      }}
                    />
                  </Box>
                  <Typography sx={{ wordBreak: 'break-word', flexGrow: 1 }}>
                    {prompt?.prompt?.name}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      size="small"
                      onClick={() => handleToggleEditMemory(prompt)}
                    >
                      <EditIcon sx={{ color: 'grey.500' }} />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteMemory(prompt)}
                    >
                      <DeleteIcon sx={{ color: 'grey.500' }} />
                    </IconButton>
                  </Box>
                </PromptCard>
              ))}
            </Box>
          )}
          {searchedThemeCategories.length === 0 ? (
            <Typography variant="body1" sx={{ textAlign: 'center', py: 2 }}>
              {strings.selectPrompts.NO_PROMPTS_FOUND}
            </Typography>
          ) : (
            <>
              {themeCategories.map(
                (category: DraftThemeCategoryWithPrompts) => (
                  <Box key={category.id} sx={{ mb: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <CategoryIcon src={category.image || `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/public_assets/icons/subcategories/Default-Icon.svg`} sx={{ mr: 1 }} />
                      <Typography variant="h6">{category.name}</Typography>
                    </Box>
                    {searchedThemeCategories
                      .filter(
                        (prompt: DraftPrompt) =>
                          prompt.type !== 'new' &&
                          prompt.prompt.categoryId === category.id,
                      )
                      .map((prompt: DraftPrompt, index: number) => {
                        return (
                          <PromptCard
                            key={`${prompt?.prompt?.id}-${index}`}
                            selected={selectedPrompts.includes(prompt)}
                            onClick={() => handlePromptSelection(prompt)}
                          >
                            <Box sx={{ marginRight: '10px' }}>
                              <CheckCircle
                                sx={{
                                  color: selectedPrompts.includes(prompt)
                                    ? 'green'
                                    : 'grey',
                                }}
                              />
                            </Box>
                            <Typography sx={{ wordBreak: 'break-word' }}>
                              {prompt?.prompt?.name}
                            </Typography>
                          </PromptCard>
                        );
                      })}
                  </Box>
                ),
              )}
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 3 }}>
        <Button
          variant="outlined"
          onClick={() => navigate(-1)}
          sx={{ mr: 2 }}
          disabled={isLoading}
        >
          {strings.selectPrompts.BACK_BTN}
        </Button>
        <Button
          variant="contained"
          onClick={handleStartCapsuleWithSelectedPrompts}
          disabled={isLoading || selectedPrompts.length === 0}
        >
          {isLoading
            ? `${strings.selectPrompts.BTN_STATUS_PROCESSING}`
            : `${strings.selectPrompts.BTN_STATUS_CONTINUE}`}
        </Button>
      </Box>
    </SimpleHeaderContainer>
  );
};

const NewCapsuleSelectPrompts = () => {
  const data = useLoaderData<typeof promptsLoader>();

  if (data.error || !data.capsule || !data.promptsData || !data.userPersonas) {
    return <ErrorView error={data.error} />;
  }

  return (
    <NewCapsuleSelectPromptsView
      capsule={data.capsule}
      promptsData={data.promptsData}
      userPersonas={data.userPersonas}
      state={data.state}
    />
  );
};

export default NewCapsuleSelectPrompts;
