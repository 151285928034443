import { Box, Typography } from '@mui/material';
import { Persona } from '~/types/persona.ts';

const PersonaWelcome: React.FC<{ primaryPersona: Persona | null }> = ({
  primaryPersona,
}) => {
  return (
    <>
      <Typography variant="h5" textAlign={'center'} gutterBottom>
        <b>Welcome!</b>
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        width={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={2}
      >
        <Typography variant="h5" textAlign={'center'} gutterBottom>
          {primaryPersona?.welcomeMessage}
        </Typography>
        {primaryPersona && (
          <img
            src={primaryPersona.image}
            alt="Welcome"
            style={{ width: '300px', height: 'auto' }}
          />
        )}
      </Box>
    </>
  );
};

export default PersonaWelcome;
