import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Params, useParams } from 'react-router-dom';
import { useRouteLoaderData } from 'react-router-typesafe';
import { css } from '@emotion/react';
import { Button } from '@mui/material';

import useMemoryContentInput from '~/api/hooks/useMemoryContentInput.ts';
import BackButton from '~/components/back_button.tsx';
import ErrorView from '~/components/error_view.tsx';
import MemoryContentInput from '~/components/memory/memory_content_input.tsx';
import MemoryItem from '~/components/memory/memory_item.tsx';
import MemoryContentItem from '~/components/memory_content_item/index.tsx';
import Toolbar from '~/components/toolbar.tsx';
import strings from '~/constants/strings.ts';
import { CapsuleLoaderData } from '~/routes/capsule/index.tsx';
import MemoryMediaCarousel from '~/routes/capsule/memory/memory_media_carousel.tsx';
import draft from '~/state/draft.ts';
import { useGetCurrentUser } from '~/state/users';
import { Capsule } from '~/types/capsule.ts';
import { Memory, MemoryContent } from '~/types/memory.ts';
import { PackageOverview } from '~/types/package.ts';

export const memoryLoader = async ({
  params,
}: {
  params: Params<'capsuleId' | 'memoryId'>;
}) => {
  if (!params.memoryId) {
    return { error: strings.CAPSULE_NOT_FOUND };
  }

  const activeDraftContentId = draft.getActiveDraftContentId(params.memoryId);
  if (!activeDraftContentId) {
    draft.resetActiveDraftContent(params.memoryId);
  }

  return null;
};

const MemoryLoaded = ({
  capsule,
  packageOverview,
}: {
  capsule: Capsule;
  packageOverview: PackageOverview;
}) => {
  const currentUser = useGetCurrentUser();
  const navigate = useNavigate();
  const { memoryId } = useParams();
  const memory = useMemo(
    () => capsule.memories.find((m: Memory) => m.id === memoryId) as Memory,
    [capsule, memoryId],
  );
  const memoryFields = useMemo(() => {
    const { user: _user, content: _content, ...rest } = memory;
    return rest;
  }, [memory]);
  const input = useMemoryContentInput(memory, packageOverview);

  return (
    <div css={styles.container}>
      <Toolbar left={<BackButton />} />
      <MemoryMediaCarousel />
      <div css={styles.header}>
        <MemoryItem memory={memory} />
        {capsule.senderId === currentUser.id && (
          <Button
            variant="outlined"
            onClick={() => navigate(`./contributors`)}
            sx={{ mt: 2, float: 'right' }}
          >
            Invite Contributors
          </Button>
        )}
      </div>
      <div css={styles.content}>
        {memory.content.map((content: MemoryContent) => (
          <MemoryContentItem
            key={content.id}
            memory={memoryFields}
            content={content}
          />
        ))}
      </div>
      <div css={styles.inputContainer}>
        <MemoryContentInput memory={memory} input={input} />
      </div>
    </div>
  );
};

const MemoryIndexRoute = () => {
  const data = useRouteLoaderData<CapsuleLoaderData>('capsuleRoot');
  if (!data.capsule || data.error) {
    return <ErrorView error={data.error} />;
  }

  return (
    <MemoryLoaded
      capsule={data.capsule}
      packageOverview={data.packageOverview}
    />
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100vh',
    '@supports (-webkit-touch-callout: none)': {
      height: '90vh',
    },
    overflow: 'hidden' as const,
  }),
  header: css({
    padding: '16px',
  }),
  content: css({
    flex: 1,
    display: 'flex',
    gap: '16px',
    padding: '16px',
    overflowY: 'auto' as const,
    flexDirection: 'column-reverse',
  }),
  inputContainer: css({
    height: '72px',
  }),
};

export default MemoryIndexRoute;
