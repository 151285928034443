import { css } from '@emotion/react';
import { Close } from '@mui/icons-material';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import { DraftContributor } from '~/types/user.ts';
import AddMemoryContributorForm from './add_contributor_form.tsx';

interface AddNewMemoryContributorModalProps {
  memoryId: string;
  capsuleId: string;
  userId: string;
  contributors: DraftContributor[];
  isOpen: boolean;
  onClose: () => void;
  callback: (draftContributorData: DraftContributor[]) => void;
}

const AddNewMemoryContributorModal: React.FC<
  AddNewMemoryContributorModalProps
> = ({
  memoryId,
  capsuleId,
  userId,
  contributors,
  isOpen,
  onClose,
  callback,
}) => {
  const handleClose = (draftContributorData: DraftContributor[]) => {
    callback(draftContributorData);
    onClose();
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="new-contributor-modal"
      aria-describedby="new-contributor-modal-description"
      disableEscapeKeyDown={true}
    >
      <Paper css={[styles.paper]}>
        <Box css={[styles.contactBox]}>
          <Typography variant="h6" ml={2}>
            Add New Contributor
          </Typography>
          <Button onClick={onClose}>
            <Close />
          </Button>
        </Box>
        <AddMemoryContributorForm
          memoryId={memoryId}
          capsuleId={capsuleId}
          userId={userId}
          contributors={contributors}
          onSave={handleClose}
          onClose={onClose}
        />
      </Paper>
    </Modal>
  );
};

const styles = {
  paper: css({
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    p: 1,
    mb: 4,
    height: '100%',
  }),
  contactBox: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 3,
  }),
};

export default AddNewMemoryContributorModal;
