import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { Session } from '@supabase/supabase-js';

import { AuthProvider } from '~/api/hooks/useAuth.tsx';

interface AppProvidersProps {
  session: Session | null;
  children: React.ReactNode;
}

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const AppProviders = ({ session, children }: AppProvidersProps) => (
  <ThemeProvider theme={theme}>
    <AuthProvider initialSession={session}>{children}</AuthProvider>
  </ThemeProvider>
);

export default AppProviders;
