import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Close, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, List, ListItem } from '@mui/material';
import { PostgrestError } from '@supabase/supabase-js';

import { api } from '~/api';
import strings from '~/constants/strings';
import { DraftRecipient, User } from '~/types/user';
import { isEmail } from '~/utils/email';
import { RecipientsSchema } from '~/utils/validation';

import ButtonWrapper from '../shared/button_wrapper';
import { ContactEmailField as EmailField } from '../shared/form/email_field';
import NameFields from '../shared/form/name_fields';

const styles = css({
  width: '100%',
});

interface AddContactFormProps {
  capsuleId: string;
  userId: string;
  recipients: User[] | null;
  onSave: () => void;
}

const defaultDraftRecipientEntry: DraftRecipient = {
  type: 'new',
  user: {
    firstName: '',
    lastName: '',
    email: '',
  },
};

const AddContactForm: React.FC<AddContactFormProps> = (props) => {
  const { capsuleId, userId, recipients, onSave } = props;
  const [recipientIdsToRemove, setRecipientIdsToRemove] = useState<string[]>(
    [],
  );

  // load existing users into form
  const defaultFormValues = getDraftRecipientsFromUsers(recipients);

  const {
    register,
    formState: { isValid, errors, dirtyFields },
    getValues,
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: zodResolver(RecipientsSchema),
    defaultValues: { recipients: defaultFormValues },
    mode: 'onBlur',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipients',
  });

  const onSubmit = async () => {
    if (!dirtyFields.recipients) {
      return;
    }

    const values = getValues();
    const { recipients } = values;

    // Validate all email addresses
    const invalidEmails = recipients.filter(
      (recipient) =>
        recipient.user.email && !isEmail.test(recipient.user.email),
    );
    if (invalidEmails.length > 0) {
      toast.error('Please enter valid email addresses');
      return;
    }

    const recipientsToEdit: DraftRecipient[] = [];
    const recipientsToAdd: DraftRecipient[] = [];

    // Go through dirty fields to determine whether we need to add, remove, or edit recipients
    dirtyFields.recipients.forEach((recipientDirty, index) => {
      if (index < recipients.length) {
        const recipient = recipients[index];
        if (recipientDirty?.user?.email) {
          if (recipient.type === 'existing') {
            // existing recipient moved places or email changed
            recipientIdsToRemove.push(recipient.user.id);
          }
          // add recipient no matter what
          recipientsToAdd.push(recipient);
        } else if (
          recipientDirty?.user?.firstName ||
          recipientDirty?.user?.lastName
        ) {
          // edited name only
          recipientsToEdit.push(recipient);
        }
      }
    });

    const deleteErrors = await deleteCapsuleRecipients(
      recipientIdsToRemove,
      capsuleId,
    );
    if (deleteErrors.length > 0) {
      // TODO: add error logging
    } else {
      setRecipientIdsToRemove([]);
    }

    const editErrors = await editRecipients(recipientsToEdit, userId);

    const { contactErrors, recipientsError } = await addRecipients(
      recipientsToAdd,
      userId,
      capsuleId,
    );

    if (recipientsError || editErrors.length > 0 || contactErrors.length > 0) {
      // TODO: add error logging
      return;
    }
    toast.success('Changes saved successfully!');
    reset(values);
    onSave();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <List>
        {fields.map((item, index) => {
          const recipientErrors = errors.recipients
            ? errors.recipients[index]
            : undefined;
          return (
            <ListItem key={item.id}>
              <Box css={styles}>
                <NameFields
                  register={register}
                  errors={recipientErrors}
                  registerPrepend={`recipients.${index}.`}
                />
                <EmailField
                  register={register}
                  registerPrepend={`recipients.${index}.`}
                  emailError={recipientErrors?.user?.email}
                  pattern={{
                    value: isEmail,
                    message: 'Please enter a valid email address',
                  }}
                ></EmailField>
              </Box>
              {!item.id ? (
                <IconButton
                  aria-label="remove recipient draft"
                  onClick={() => {
                    const values = getValues();
                    const recipient = values.recipients[index];
                    const recipientId =
                      recipient.type === 'existing' ? recipient.user.id : '';
                    if (recipientId) {
                      setRecipientIdsToRemove([
                        ...recipientIdsToRemove,
                        recipientId,
                      ]);
                    }
                    remove(index);
                  }}
                >
                  <Close />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="delete recipient"
                  onClick={() => {
                    const values = getValues();
                    const recipient = values.recipients[index];
                    const recipientId =
                      recipient.type === 'existing' ? recipient.user.id : '';
                    const idsToRemove = [...recipientIdsToRemove, recipientId];
                    if (recipientId) {
                      setRecipientIdsToRemove(idsToRemove);
                    }
                    deleteCapsuleRecipients(idsToRemove, capsuleId);
                    remove(index);
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </ListItem>
          );
        })}
      </List>
      <Box display="flex" justifyContent="center" mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            append(defaultDraftRecipientEntry);
          }}
        >
          Add More
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" mb={3}>
        <ButtonWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onSave();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || !dirtyFields.recipients}
          >
            {strings.SAVE_CHANGES}
          </Button>
        </ButtonWrapper>
      </Box>
    </form>
  );
};

export default AddContactForm;

const deleteCapsuleRecipients = async (
  recipientIds: string[],
  capsuleId: string,
): Promise<PostgrestError[]> => {
  const deleteErrors: PostgrestError[] = [];
  if (recipientIds.length < 1) {
    return deleteErrors;
  }

  for (const recipientId of recipientIds) {
    const deleteError = await api.capsules.deleteCapsuleRecipient(
      recipientId,
      capsuleId,
    );
    if (deleteError) {
      deleteErrors.push(deleteError);
    }
  }

  return deleteErrors;
};

const editRecipients = async (
  recipientsToEdit: DraftRecipient[],
  userId: string,
): Promise<PostgrestError[]> => {
  const errors: PostgrestError[] = [];

  for (const recipient of recipientsToEdit) {
    if (recipient.type === 'existing') {
      const error = await api.contacts.updateContact(
        recipient.user.id,
        userId,
        recipient.user.firstName,
        recipient.user.lastName,
      );

      if (error) {
        errors.push(error);
      }
    }
  }

  return errors;
};

const addRecipients = async (
  recipientsToAdd: DraftRecipient[],
  userId: string,
  capsuleId: string,
): Promise<{
  contactErrors: PostgrestError[];
  recipientsError: PostgrestError | null;
}> => {
  const recipientIdsToAdd: string[] = [];
  const contactErrors: PostgrestError[] = [];
  for (const recipient of recipientsToAdd) {
    if (recipient.type === 'new') {
      const { data: recipientId, error } = await api.contacts.createContact(
        userId,
        recipient.user,
      );

      if (error) {
        contactErrors.push(error);
        return { contactErrors, recipientsError: null };
      }
      if (!error) recipientIdsToAdd.push(recipientId);
    } else {
      recipientIdsToAdd.push(recipient.user.id);
    }
  }

  const recipientsError = await api.capsules.createCapsuleRecipients(
    recipientIdsToAdd,
    capsuleId,
  );
  return { contactErrors, recipientsError };
};

const getDraftRecipientsFromUsers = (
  recipients: User[] | null,
): DraftRecipient[] => {
  if (!recipients?.length) {
    return [defaultDraftRecipientEntry];
  }

  return recipients.map((recipient) => {
    return {
      type: 'existing',
      user: recipient,
    };
  });
};
