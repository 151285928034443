import { PostgrestError, SupabaseClient } from '@supabase/supabase-js';
import { nanoid } from 'nanoid';

import { apiClient } from '~/api/api_client.ts';
import { SupabaseService } from '~/api/service.ts';
import StorageService from '~/api/storage.ts';
import { usePackageStore } from '~/state/packages.ts';
import {
  Attachment,
  isAttachment,
  isAudioRecording,
} from '~/types/attachment.ts';
import { Capsule, CapsuleStatus, DraftCapsule } from '~/types/capsule.ts';
import { Memory } from '~/types/memory.ts';
import { AttachmentType } from '~/types/types';

import ContactsService from './contacts';

// This can grab capsules regardless of the number of recipients
const selectCapsuleQuery = `*,
        sender:capsules_senderId_fkey(*),
        recipients:capsule_shares(...users(*)),
        memories:memories(*,
          content:memory_content(*,
            attachments:attachments(*,
              blob:attachment_blobs(*)
            ),
            user: userId(*)
          ),
          user:userId(*)
        )`;

// This can only grab capsules that have recipients, but inner join is needed for matching the recipient ID
const selectReceivedCapsuleQuery = `*,
        sender:capsules_senderId_fkey(*),
        recipients:capsule_shares!inner(...users(*)),
        memories:memories(*,
          content:memory_content(*,
            attachments:attachments(*,
              blob:attachment_blobs(*)
            ),
            user: userId(*)
          ),
          user:userId(*)
        )`;

export default class CapsulesService extends SupabaseService {
  storage: StorageService;
  contacts: ContactsService;

  constructor(
    supabase: SupabaseClient,
    storage: StorageService,
    contacts: ContactsService,
  ) {
    super(supabase);
    this.storage = storage;
    this.contacts = contacts;
  }

  create = async (draft: DraftCapsule) => {
    const { user, error: getUserError } = await this.getUser();
    if (getUserError) {
      return { error: getUserError };
    }
    const { id: userId, membershipId } = user;

    // auto-associate membershipPackageId if an existing active package exists
    // this ensures we can properly track the storage usage
    const packageState = usePackageStore.getState();
    let membershipPackageId: string | undefined;
    if (
      packageState.activeMembershipPackageId &&
      packageState.packages[packageState.activeMembershipPackageId].statistics
        .remainingCapsules > 0
    ) {
      membershipPackageId = packageState.activeMembershipPackageId;
    }

    const { data, error } = await this.supabase
      .from('capsules')
      .insert({
        id: draft.id,
        membershipId: membershipId,
        membershipPackageId,
        senderId: userId,
        sendStatus: CapsuleStatus.DRAFT,
        title: '',
        message: '',
      })
      .select()
      .single();

    if (membershipPackageId) {
      const { error: joinInsertError } = await this.supabase
        .from('capsule_to_membership_packages')
        .insert({
          capsuleId: draft.id,
          membershipPackageId: membershipPackageId,
        });

      if (joinInsertError) {
        return { error: joinInsertError };
      }
    }

    return {
      data,
      error,
    };
  };

  schedule = async (capsuleId: string) => {
    try {
      await apiClient.post(`/v1/capsule/${capsuleId}/schedule`);
    } catch (error) {
      return { error };
    }

    return { error: null };
  };

  getSentCapsules = async () => {
    const { user, error: getUserError } = await this.getUser();
    if (getUserError) {
      return { error: getUserError, data: null };
    }

    const { data, error } = await this.supabase
      .from('capsules')
      .select(selectCapsuleQuery)
      .eq('senderId', user.id)
      .returns<Capsule[]>();

    if (error) {
      return { data: null, error };
    }

    const wrappedCapsules = await Promise.all(
      (data as Capsule[]).map(this.wrapSignedAttachments),
    );

    return {
      data: wrappedCapsules,
      error,
    };
  };

  getReceivedCapsules = async () => {
    const { user, error: getUserError } = await this.getUser();
    if (getUserError) {
      return { error: getUserError, data: null };
    }
    const { data, error } = await this.supabase
      .from('capsules')
      .select(selectReceivedCapsuleQuery)
      .eq('capsule_shares.recipientId', user.id)
      .eq('sendStatus', 'sent')
      .returns<Capsule[]>();

    if (error) {
      return { data: null, error };
    }

    const wrappedCapsules = await Promise.all(
      (data as Capsule[]).map(this.wrapSignedAttachments),
    );

    return {
      data: wrappedCapsules,
      error,
    };
  };

  getCapsuleById = async (capsuleId: string) => {
    const { data, error } = await this.supabase
      .from('capsules')
      .select(selectCapsuleQuery)
      .eq('id', capsuleId)
      .order('"createdAt"', {
        referencedTable: 'memories.memory_content',
        ascending: false,
      })
      .single();

    if (error) {
      return { error, data: null };
    }

    const capsule = await this.wrapSignedAttachments(data as Capsule);
    return { data: capsule, error: null };
  };

  /**
   * Deletes a capsule record in supabase
   * @param capsuleId ID of the capsule
   * @returns An error if one occurred
   */
  private deleteById = async (capsuleId: string) => {
    const { error } = await this.supabase
      .from('capsules')
      .delete()
      .eq('id', capsuleId);

    return error;
  };

  /**
   * Deletes a capsule and its related attachments
   * @param capsule The capsule to be deleted
   * @returns An array of errors that occurred, null if no errors
   */
  cascadeDelete = async (
    capsule: Capsule,
  ): Promise<(Error | PostgrestError)[] | null> => {
    const { id } = capsule;
    const thumbnailsToDelete: string[] = [];
    const errors: (Error | PostgrestError)[] = [];

    // Use format s3 expects for attachments
    const attachmentsToDelete: { Key: string }[] = [];

    if (capsule.memories) {
      const capsuleAttachments: Attachment[] = [];
      capsule.memories.forEach((memory) => {
        memory.content.forEach((content) => {
          content.attachments &&
            capsuleAttachments.push(...content.attachments);
        });
      });

      capsuleAttachments.forEach((attachment) => {
        const { type, blob } = attachment;
        if (type === AttachmentType.ATTACHMENT) {
          attachmentsToDelete.push({ Key: blob.key });
        } else {
          thumbnailsToDelete.push(blob.key);
        }
      });
    }

    // Delete thumbnails in supabase
    if (thumbnailsToDelete.length > 0) {
      const error = await this.storage.deleteThumbnails(thumbnailsToDelete);
      if (error) {
        errors.push(error);
      }
    }

    // Delete attachments in s3
    if (attachmentsToDelete.length > 0) {
      const error = await this.storage.deleteAttachments(attachmentsToDelete);
      if (error) {
        errors.push(error);
      }
    }

    // Delete capsule
    const error = await this.deleteById(id);
    if (error) {
      errors.push(error);
    }

    if (errors.length > 0) {
      return errors;
    }

    return null;
  };

  /**
   * Update a capsule in supabase
   * @param capsuleId ID of the capsule to be updated
   * @param capsule capsule to be updated
   * @param message updated message
   * @param sendDateISO updated send date
   * @param recipientId updated recipient ID
   * @param status status of the capsule
   * @returns an error if one occurred
   */
  update = async (
    capsuleId: string,
    capsule: Partial<Capsule>,
  ): Promise<PostgrestError | null> => {
    const params: Partial<Capsule> = {};
    capsule.title && (params['title'] = capsule.title);
    capsule.message && (params['message'] = capsule.message);
    capsule.sendDate && (params['sendDate'] = capsule.sendDate);
    capsule.sendStatus && (params['sendStatus'] = capsule.sendStatus);

    const { error } = await this.supabase
      .from('capsules')
      .update({
        ...params,
        updatedAt: new Date().toISOString(),
      })
      .eq('id', capsuleId);

    return error;
  };

  /**
   * Adds the recipients for a capsule and generate a shareToken
   * @param recipientIds user IDs of recipients
   * @param capsuleId the capsule ID
   * @returns An error if one occurred
   */
  createCapsuleRecipients = async (
    recipientIds: string[],
    capsuleId: string,
  ): Promise<PostgrestError | null> => {
    if (recipientIds.length < 1) {
      return null;
    }

    const { error: recipientsError } = await this.supabase
      .from('capsule_shares')
      .insert(
        recipientIds.map((recipientId: string) => ({
          capsuleId: capsuleId,
          recipientId: recipientId,
          shareToken: nanoid(),
        })),
      );

    return recipientsError;
  };

  /**
   * Deletes the given recipient from this capsule
   * @param recipientId user ID of recipient to remove
   * @param capsuleId capsule ID
   * @returns An error if one occurred
   */
  deleteCapsuleRecipient = async (
    recipientId: string,
    capsuleId: string,
  ): Promise<PostgrestError | null> => {
    const { error } = await this.supabase
      .from('capsule_shares')
      .delete()
      .eq('capsuleId', capsuleId)
      .eq('recipientId', recipientId);

    return error;
  };

  private wrapSignedAttachments = async (
    capsule: Capsule,
  ): Promise<Capsule> => {
    const presignedUrlRequests: Promise<{
      attachmentId: string;
      blobId: string;
      url: string | undefined;
    }>[] = [];

    const attachmentBlobKeys = getAttachmentBlobValues(
      capsule.memories,
      AttachmentType.ATTACHMENT,
    );

    const thumbnailBlobKeys = getAttachmentBlobValues(
      capsule.memories,
      AttachmentType.THUMBNAIL,
    );

    const audioRecordingBlobKeys = getAttachmentBlobValues(
      capsule.memories,
      AttachmentType.AUDIO_RECORDING,
    );

    Object.values({ ...audioRecordingBlobKeys, ...attachmentBlobKeys }).forEach(
      ({ attachmentId, blobId, blobKey, contentType }) => {
        // get presigned urls from amazon s3
        presignedUrlRequests.push(
          this.storage
            .getReadSignedUrl(blobKey, contentType)
            .then(({ data }) => {
              return {
                attachmentId: attachmentId,
                blobId: blobId,
                url: data?.signedUrl,
              };
            }),
        );
      },
    );

    Object.values(thumbnailBlobKeys).forEach(
      ({ attachmentId, blobId, blobKey }) => {
        // get presigned urls from supabase thumbnail storage
        presignedUrlRequests.push(
          this.storage.getThumbnailSignedUrl(blobKey).then(({ data }) => {
            return {
              attachmentId: attachmentId,
              blobId: blobId,
              url: data?.signedUrl,
            };
          }),
        );
      },
    );

    const presignedUrls = await Promise.all(presignedUrlRequests);

    return {
      ...capsule,
      memories: capsule.memories.map((memory) => ({
        ...memory,
        content: memory.content.map((content) => ({
          ...content,
          audioRecording: content.attachments
            ?.filter((attachment) => isAudioRecording(attachment))
            ?.map((attachment) => ({
              ...attachment,
              blob: { ...attachment.blob },
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              url: presignedUrls.find(
                (url) => url.attachmentId === attachment.id,
              )?.url!,
            }))?.[0],
          attachments: content.attachments
            ?.map((attachment) => ({
              ...attachment,
              blob: { ...attachment.blob },
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              url: presignedUrls.find(
                (url) => url.attachmentId === attachment.id,
              )?.url!,
              // add thumbnailUrl to attachments
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              thumbnailUrl: presignedUrls.find(
                (url) => url.attachmentId === attachment.thumbnailId,
              )?.url!,
            }))
            // abstract out the thumbnails, now that attachments have thumbnailUrls
            .filter((attachment) => isAttachment(attachment)),
        })),
      })),
    };
  };
}

const getAttachmentBlobValues = (memories: Memory[], type: AttachmentType) => {
  return memories.reduce<{
    [id: string]: {
      attachmentId: string;
      blobId: string;
      blobKey: string;
      contentType: string;
    };
  }>((acc, memory) => {
    memory.content.forEach((content) => {
      content.attachments
        ?.filter((attachment) => attachment.type === type)
        .forEach((attachment) => {
          acc[attachment.blob.id] = {
            attachmentId: attachment.id,
            blobId: attachment.blob.id,
            blobKey: attachment.blob.key,
            contentType: attachment.blob.contentType,
          };
        });
    });
    return acc;
  }, {});
};
