import dayjs, { Dayjs } from 'dayjs';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

import strings from '~/constants/strings';

const emailValidation = z.string().email();
const firstNameValidation = z.string();
const lastNameValidation = z.string();
const dateValidation = z.custom<Dayjs>(
  (val) => val instanceof dayjs,
  strings.DATE_INVALID,
);

const phoneNumberValidation = z.string().refine((phoneNumber) => {
  return isValidPhoneNumber(phoneNumber);
}, strings.PHONE_INVALID);
const passwordValidation = z.string().min(8, strings.PASSWORD_MIN_LENGTH);
const confirmPasswordMessage = {
  path: ['password2'],
  message: strings.PASSWORD_MATCH,
};

const EmailSchema = emailValidation;

const LoginSchema = z.object({
  email: emailValidation,
  password: passwordValidation,
});

const SignUpSchema = z
  .object({
    email: emailValidation,
    password: passwordValidation,
    password2: passwordValidation,
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    phoneNumber: phoneNumberValidation,
    options: z
      .object({
        emailRedirectTo: z.string(),
      })
      .optional(),
  })
  .refine((data) => {
    return data.password === data.password2;
  }, confirmPasswordMessage);

const ForgotPasswordSchema = z.object({
  email: emailValidation,
});

const ResetPasswordSchema = z
  .object({
    password: passwordValidation,
    password2: passwordValidation,
  })
  .refine((data) => {
    return data.password === data.password2;
  }, confirmPasswordMessage);

const CapsuleFormSchema = z.object({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  email: emailValidation,
  message: z.string(),
  sendDate: dateValidation.refine((sendDate) => {
    return sendDate.isAfter(dayjs());
  }, strings.DATE_FUTURE),
});

const UserSchema = z.object({
  id: z.string().optional(),
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  email: emailValidation,
  phoneNumber: phoneNumberValidation.optional(),
});

const DraftUserSchema = z.object({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  email: emailValidation,
});

const RecipientSchema = z.object({
  user: UserSchema.or(DraftUserSchema),
});

const ContributorSchema = z.object({
  user: UserSchema.or(DraftUserSchema),
});

const AttachmentBlobSchema = z.object({
  key: z.string(),
  filename: z.string(),
  contentType: z.string(),
  metadata: z
    .object({
      width: z.number(),
      height: z.number(),
    })
    .optional(),
  service: z.string(),
  byteSize: z.number(),
  order: z.number().optional(),
  type: z.string(),
});

const CreateCapsuleSchema = z
  .object({
    capsule: z.object({
      id: z.string(),
      title: z.string(),
      attachments: z.array(AttachmentBlobSchema),
      imageThumbnail: AttachmentBlobSchema,
      gifPreview: AttachmentBlobSchema,
      message: z.string(),
      sendDate: z.date(),
    }),
    recipient: RecipientSchema.optional().nullable(),
    recipientUserId: z.string().optional().nullable(),
  })
  .refine(
    (data) => {
      // If both recipient and recipientUserId are provided, return false
      if (data.recipient && data.recipientUserId) return false;
      // If neither recipient nor recipientUserId are provided, return false
      if (!data.recipient && !data.recipientUserId) return false;
      return true;
    },
    {
      message: "Either provide 'recipient' or 'recipientUserId', but not both.",
      path: [], // root-level error
    },
  );

const RecipientsSchema = z.object({ recipients: z.array(RecipientSchema) });
const ContributorsSchema = z.object({
  contributors: z.array(ContributorSchema),
});

export {
  CapsuleFormSchema,
  CreateCapsuleSchema,
  EmailSchema,
  ForgotPasswordSchema,
  LoginSchema,
  RecipientSchema,
  RecipientsSchema,
  ResetPasswordSchema,
  SignUpSchema,
  UserSchema,
  ContributorSchema,
  ContributorsSchema,
};

export type SignUpParams = z.infer<typeof SignUpSchema>;
export type LoginParams = z.infer<typeof LoginSchema>;
export type CreateCapsuleParams = z.infer<typeof CreateCapsuleSchema>;
export type CreateCapsuleAttachmentBlob = z.infer<typeof AttachmentBlobSchema>;
