import { SupabaseService } from '~/api/service.ts';
import { DraftMemory } from '~/types/memory.ts';

export default class MemoryService extends SupabaseService {
  create = async (draftMemory: DraftMemory) => {
    const { data, error } = await this.supabase
      .from('memories')
      .insert(draftMemory);

    return { data, error };
  };

  createMany = async (draftMemories: DraftMemory[]) => {
    const { data, error } = await this.supabase
      .from('memories')
      .insert(draftMemories);

    return { data, error };
  };
}
