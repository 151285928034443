import { useCallback, useEffect, useRef } from 'react';
import * as tus from 'tus-js-client';
import { v4 as uuid } from 'uuid';

import { useApi } from '~/api';
import { useFileDraftStore } from '~/state/file_draft.ts';
import {
  Dimensions,
  isAudioUpload,
  isFile,
  isImageUpload,
  isVideoUpload,
  Upload,
} from '~/types/upload.ts';
import { normalizePostgrestError } from '~/utils/error.ts';
import { getDefaultFileName } from '~/utils/file.ts';
import { captureException, Events, logEvent } from '~/utils/logger.ts';
import { createVideoThumbnail, resizeImage } from '~/utils/thumbnail.ts';

import fileStorage from '~/storage/file_storage.ts';

type UploadPath = 'capsule_assets' | 'profile_avatars' | 'hydration';

export interface UploadParam {
  id?: string;
  parentId: string;
  asset: File | Blob;
}

const getVideoDimensions = async (file: Blob): Promise<Dimensions> => {
  const url = URL.createObjectURL(file);
  const videoElement = document.createElement('video');
  videoElement.src = url;
  return new Promise((resolve) => {
    videoElement.addEventListener('loadedmetadata', function () {
      resolve({
        width: this.videoWidth,
        height: this.videoHeight,
      });
    });
  });
};

const getImageDimensions = async (blob: Blob): Promise<Dimensions> => {
  const image = new Image();
  image.src = URL.createObjectURL(blob);
  return new Promise((resolve) => {
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
  });
};

const getAssetDimensions = async (blob: Blob) => {
  if (blob.type.startsWith('video')) {
    return await getVideoDimensions(blob);
  } else if (blob.type.startsWith('image')) {
    return await getImageDimensions(blob);
  }

  return undefined;
};

export default function useFileUpload(path: UploadPath) {
  const uploads = useFileDraftStore((state) => state.uploads);
  const _addUploads = useFileDraftStore((state) => state.addUploads);
  const _removeUpload = useFileDraftStore((state) => state.removeUpload);
  const _updateUpload = useFileDraftStore((state) => state.updateUpload);
  const _resetUploads = useFileDraftStore((state) => state.reset);
  const api = useApi();

  const handleInsertRecord = useCallback(
    async (upload: Upload) => {
      try {
        logEvent(Events.FILE_UPLOAD_INSERT_RECORD_STARTED, {
          upload_id: upload.id,
        });
        const asset = upload.metadata.asset;
        const { error: insertBlobError } = await api.attachments.createBlob({
          id: upload.id,
          key: upload.objectKey,
          filename: isFile(asset)
            ? asset.name
            : getDefaultFileName(asset!.type),
          contentType: upload.metadata.asset!.type,
          metadata: upload.metadata?.dimensions
            ? { ...upload.metadata.dimensions }
            : undefined,
          service: 'amazon',
          byteSize: upload.metadata.asset?.size || 0,
        });
        if (insertBlobError) {
          throw insertBlobError;
        }

        _updateUpload({
          id: upload.id,
          status: 'success',
          insertRecordCompleted: true,
        });
        logEvent(Events.FILE_UPLOAD_INSERT_RECORD_COMPLETED, {
          upload_id: upload.id,
        });
      } catch (error) {
        _updateUpload({
          id: upload.id,
          status: 'error',
          error: error as Error,
          errorLocation: 'handleInsertRecord.catch',
        });
        logEvent(Events.FILE_UPLOAD_INSERT_RECORD_FAILED, {
          upload_id: upload.id,
          error,
        });
        captureException(error, 'handleInsertRecord.catch');
      }
    },
    [api.attachments, _updateUpload],
  );

  const handleTusUpload = useCallback(
    async (upload: Upload) => {
      // get JWT token from Supabase
      const { token, error: tokenError } = await api.auth.getToken();
      if (tokenError) {
        _updateUpload({
          id: upload.id,
          status: 'error',
          error: tokenError as Error,
          errorLocation: 'api.auth.getToken',
        });
        captureException(tokenError, 'api.auth.getToken');
        return;
      }
      const file = upload.metadata.asset as File;

      const tusUpload = new tus.Upload(file, {
        endpoint: `${import.meta.env.VITE_API_BASE_URL}/uploads/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: (error: Error) => {
          _updateUpload({
            id: upload.id,
            status: 'error',
            error: error as Error,
            errorLocation: 'handleTusUpload.onError',
          });
          captureException(error, 'handleTusUpload.onError');
        },
        onSuccess: async (payload: any) => {
          const uploadPath = payload.lastResponse.getHeader('X-Upload-Path')!;
          const updatedUpload: Upload = {
            ...upload,
            objectKey: uploadPath,
            tusUploadCompleted: true,
            status: 'success',
          };
          _updateUpload(updatedUpload);

          logEvent(Events.FILE_UPLOAD_COMPLETED, { upload_id: upload.id });
          handleInsertRecord(updatedUpload);
        },
        onProgress: (bytesSent: any, bytesTotal: any) => {
          _updateUpload({
            id: upload.id,
            progress: (bytesSent / bytesTotal) * 100,
          });
        },
      });
      tusUpload.start();
    },
    [api.auth, _updateUpload, handleInsertRecord],
  );

  const handleGenerateThumbnail = useCallback(
    async (upload: Upload) => {
      try {
        let imageThumbnail!: Blob;
        if (isVideoUpload(upload)) {
          logEvent(Events.FILE_GENERATE_THUMBNAIL_STARTED, {
            video: true,
            upload_id: upload.id,
          });
          const videoFrame = await createVideoThumbnail({
            file: upload.metadata.asset!,
            debug: true,
          });
          imageThumbnail = await resizeImage({
            file: videoFrame,
          });
          logEvent(Events.FILE_GENERATE_THUMBNAIL_COMPLETED, {
            video: true,
            upload_id: upload.id,
          });
        } else if (isImageUpload(upload)) {
          logEvent(Events.FILE_GENERATE_THUMBNAIL_STARTED, {
            image: true,
            upload_id: upload.id,
          });
          imageThumbnail = await resizeImage({
            file: upload.metadata.asset!,
          });
          logEvent(Events.FILE_GENERATE_THUMBNAIL_COMPLETED, {
            image: true,
            upload_id: upload.id,
          });
        }

        const thumbnailDimensions = await getImageDimensions(imageThumbnail);
        const thumbnailId = uuid();
        const thumbnailPath = `${upload.parentId}/${thumbnailId}`;

        // Upload thumbnail to storage
        const { error: thumbnailUploadError } =
          await api.storage.thumbnailUpload({
            name: thumbnailPath,
            path: thumbnailPath,
            asset: imageThumbnail,
            contentType: imageThumbnail.type,
          });

        if (thumbnailUploadError) {
          _updateUpload({
            id: upload.id,
            status: 'error',
            error: thumbnailUploadError as Error,
            errorLocation: 'handleGenerateThumbnail.thumbnailUploadError',
          });
          captureException(
            thumbnailUploadError,
            'handleGenerateThumbnail.thumbnailUploadError',
          );
          return;
        }

        const asset = upload.metadata.asset;
        const { error: createBlobError } = await api.attachments.createBlob({
          id: thumbnailId,
          key: thumbnailPath,
          filename: isFile(asset)
            ? asset.name
            : getDefaultFileName(asset!.type),
          contentType: imageThumbnail.type,
          metadata: thumbnailDimensions
            ? { ...thumbnailDimensions }
            : undefined,
          service: 'supabase',
          byteSize: imageThumbnail.size || 0,
        });
        if (createBlobError) {
          _updateUpload({
            id: upload.id,
            status: 'error',
            error: normalizePostgrestError(createBlobError),
            errorLocation: 'handleGenerateThumbnail.createBlobError',
          });
          captureException(
            createBlobError,
            'handleGenerateThumbnail.createBlobError',
          );
          return;
        }

        _updateUpload({
          id: upload.id,
          thumbnailId,
          imageThumbnail,
          generateThumbnailCompleted: true,
        });

        handleTusUpload(upload);
      } catch (uploadError) {
        _updateUpload({
          id: upload.id,
          status: 'error',
          error: uploadError as Error,
          errorLocation: 'handleGenerateThumbnail.catch',
        });
        logEvent(Events.FILE_GENERATE_THUMBNAIL_FAILED, {
          upload_id: upload.id,
          error: uploadError,
        });
        captureException(uploadError, 'handleGenerateThumbnail.catch');
      }
    },
    [api, _updateUpload, handleTusUpload],
  );

  const upload = useCallback(
    async (assets: UploadParam[]) => {
      const newUploads: Upload[] = [];

      for (const asset of assets) {
        const assetId = asset.id || uuid();

        const dimensions = await getAssetDimensions(asset.asset as Blob);
        const uploadItem: Upload = {
          id: assetId,
          objectKey: `${path}/${assetId}`,
          parentId: asset.parentId,
          status: 'uploading',
          metadata: {
            asset: asset.asset,
            dimensions,
          },
          progress: 0,
          createdAt: new Date().toISOString(),
        };
        newUploads.push(uploadItem);
        logEvent(Events.FILE_UPLOAD_SELECTED, { ...uploadItem });
      }

      _addUploads(newUploads);
      newUploads.map(async (upload) => {
        try {
          if (!upload.metadata.asset) {
            throw new Error('No file or blob provided');
          }

          logEvent(Events.FILE_UPLOAD_CACHE_BLOB, { upload_id: upload.id });

          // NOTE(miguel): during beta testing, let's assess if we should only apply saving for recorded videos and audio
          // cache the file locally to support draft uploads
          await fileStorage.saveFile(upload.id, upload.metadata.asset as File);
          _updateUpload({ id: upload.id, fileLocalStorageCompleted: true });
        } catch (error) {
          _updateUpload({
            id: upload.id,
            status: 'error',
            error: error as Error,
            errorLocation: 'fileStorage.saveFile',
          });
          logEvent(Events.FILE_UPLOAD_CACHE_BLOB_FAILED, {
            upload_id: upload.id,
            error: error,
          });
          captureException(error, 'fileStorage.saveFile');
          return;
        }

        if (isAudioUpload(upload)) {
          // Audio uploads do not require thumbnails
          _updateUpload({ id: upload.id, generateThumbnailCompleted: true });
          handleTusUpload(upload);
          return;
        }

        handleGenerateThumbnail(upload);
      });
    },
    [
      path,
      _addUploads,
      _updateUpload,
      handleTusUpload,
      handleGenerateThumbnail,
    ],
  );

  const removeUpload = useCallback(
    (id: string) => {
      _removeUpload(id);
    },
    [_removeUpload],
  );

  const resetUploads = useCallback(() => {
    _resetUploads();
  }, [_resetUploads]);

  const hydrateUpload = useCallback(
    async (upload: Upload) => {
      if (upload.error || !!upload.errorLocation) {
        return;
      }

      logEvent(Events.FILE_UPLOAD_HYDRATING, { upload_id: upload.id });

      try {
        if (!upload.fileLocalStorageCompleted) {
          throw new Error(
            'File failed to be cached before hydration indexedDB.file_storage_db',
          );
        }

        // Retrieve the file from indexedDB
        const file = await fileStorage.getFile(upload.id);
        _updateUpload({ id: upload.id, metadata: { asset: file } });

        if (!upload.generateThumbnailCompleted) {
          handleGenerateThumbnail(upload);
          return;
        } else {
          const imageThumbnailUrl = await api.storage.getThumbnailSignedUrl(
            `${upload.parentId}/${upload.thumbnailId}`,
          );
          _updateUpload({
            id: upload.id,
            imageThumbnailUrl: imageThumbnailUrl.data?.signedUrl,
          });
        }

        if (!upload.tusUploadCompleted) {
          handleTusUpload(upload);
          return;
        }
      } catch (e) {
        logEvent(Events.FILE_UPLOAD_HYDRATING_FAILED, {
          upload_id: upload.id,
          error: e,
        });
        // TODO(miguel): Add error reporting
        // Remove upload to avoid stale uploads
        removeUpload(upload.id);
      }
    },
    [
      api,
      _updateUpload,
      handleGenerateThumbnail,
      handleTusUpload,
      removeUpload,
    ],
  );

  return { uploads, upload, removeUpload, resetUploads, hydrateUpload };
}

// useHydrateFileUploads: This hook should only be called once
// to hydrate the file uploads from the redux store
export function useHydrateFileUploads() {
  const hydrationStarted = useRef(false);
  const { hydrateUpload } = useFileUpload('hydration');
  const uploads = useFileDraftStore((state) => state.uploads);

  useEffect(() => {
    if (hydrationStarted.current) {
      return;
    }
    hydrationStarted.current = true;

    // Thanks to zustand-persist, if we have any uploads cached
    // we can guarantee that uploads exist on first render
    uploads.forEach(hydrateUpload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
