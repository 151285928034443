import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { api } from '~/api';
import { User } from '~/types/user.ts';

export type UserStore = UserState & Actions;

interface UserState {
  /**
   * The logged in user
   */
  currentUser?: User;
}

interface Actions {
  /**
   * Fetches the logged in user from supabase
   * @returns the logged in user
   */
  fetch: () => Promise<User | null>;
}

/**
 * Creates the user store, used to hold the logged in user's current data and track any changes made in the profile page
 */
export const useUserStore = create<UserState & Actions>()(
  persist(
    immer((set) => ({
      currentUser: undefined,
      fetch: async () => {
        const { user } = await api.getUser();
        if (user) {
          set({ currentUser: user });
        }
        return user;
      },
    })),
    {
      name: 'user',
    },
  ),
);

/**
 * Fetches the user from the store if it exists, or fetches it from supabase and loads it into the store
 * @returns the logged in user from the store
 */
export const fetchCurrentUser = async (): Promise<User | null> => {
  const userStoreState = useUserStore.getState();
  // Load user from store if it exists
  const { currentUser } = userStoreState;
  const fetchedUser = await userStoreState.fetch();

  if (currentUser && fetchedUser) {
    if (JSON.stringify(currentUser) !== JSON.stringify(fetchedUser)) {
      return fetchedUser;
    }
    return currentUser;
  }

  return fetchedUser;
};

/**
 * Refetches the user from supabase and updates the store with the new data
 * @returns the updated user data
 */
export const refetchUser = async (): Promise<User | null> => {
  const userStoreState = useUserStore.getState();
  return await userStoreState.fetch();
};

/**
 * Selector to grab current user in authenticated routes
 * @returns the current user from the store
 */
export const useGetCurrentUser = () => {
  const currentUser = useUserStore((state) => state.currentUser);
  if (!currentUser) {
    throw new Error('No current user');
  }
  return currentUser;
};

export const getCurrentUser = () => {
  const currentUser = useUserStore.getState().currentUser;
  if (!currentUser) {
    throw new Error('No current user');
  }
  return currentUser;
};
